import React, { Component } from 'react';
import InputCancer from './input-cancer';
import { connect } from "react-redux";
import InputAndOr from './input-andor';
import { fromJS, remove, updateIn } from 'immutable'
import lodash, { cloneDeep, filter } from 'lodash'
import { createUUID } from '../react-flow-pedigree/utils';
import AdvancedFilterInput from './advanced-filter-input';
import family_api from '../../api/family-api'
import Select from 'react-select'

import { RadioGroup, Radio } from "react-ui-icheck";

import { buildQueryPayload } from './query-utils'

let filterItem = { fieldType: '', andOr: 'and', cancerType: '', relationalOperator: 'any_age', age: '' }

let filterSet = { andOr: 'and', items: [filterItem] }

let geneFilterItem = { fieldType: '', andOr: 'and', gene: '', result: 'any' , Variant: '' }

let referralFilterItem = { fieldType: '', andOr: 'and', referral: '' }

let surveyFilterItem = { fieldType: '', andOr: 'and', surveyName: '', questionUUID: '', questionType: '', answer: '', answer_2: '', answer_3: '', date_from: '', date_to: '' }

const operator_options = [
  {value: 'any', label: 'Any'},
  {value: '<=', label: '≤'},
  {value: '<', label: '<'},
  {value: '=', label: '='},
  {value: '>', label: '>'},
  {value: '>=', label: '≥'},
];

const MAX_PATIENTS = 75
class AdvancedFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSets: [],
      includeUMLS: false,
      checkBoxDisabled: false,
      activeAdvancedFilter: 'probands',
      familyFilters: this.props.familyFilters
    }
    this.handleOpenClick = this.handleOpenClick.bind(this)
    this.handleAddSet = this.handleAddSet.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this)
    this.checkUMLSCheckboxDisable = this.checkUMLSCheckboxDisable.bind(this);
    this.umlsChecked = this.umlsChecked.bind(this);
    this.setActiveAdvancedFilter = this.setActiveAdvancedFilter.bind(this);
    this.runQueryReport = this.runQueryReport.bind(this);
    this.handleFamilyFiltersChange = this.handleFamilyFiltersChange.bind(this);
  }

  loadReportSetFilters(filterSets) {
    if (filterSets.disease_filters.length === 0) {
      let clean = [filterSet]
      this.setState({ filterSets: clean })
    }
    else {
      let newFilterSets = filterSets.disease_filters;
      this.setState({ filterSets: newFilterSets })
    }
  }

  isActive() {
    let clean = [filterSet]
    let filterSets = this.state.filterSets
    return lodash.isEqual(clean, filterSets) === false;
  }

  componentDidMount() {
    if(this.props.abortController){
      this.props.abortController.abort();
    }
    if(this.props.active_advanced_filter){
      this.setState({ activeAdvancedFilter: this.props.active_advanced_filter })
    }

    if(this.props.advanced_filters.length > 0){
      this.setState({ filterSets: this.props.advanced_filters })
    }
    else{
      this.handleAddSet()
    }
  }

  handleOpenClick() {
    if (this.props.open) {
      this.props.onOpen('')
      this.props.handleIsActiveChange('disease', this.isActive());
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
      this.props.changeDiseaseFilters(lodash.cloneDeep(this.state.filterSets), this.state.includeUMLS, this.isActive())
      this.props.handleIncludeUMLS(this.state.includeUMLS)

    } else {
      this.props.onOpen('cancer')
      this.props.setCurrentFilterPanelFilterSet(lodash.cloneDeep(this.state.filterSets));
      this.props.handleIncludeUMLS(this.state.includeUMLS)
    }
  }

  handleAddSet() {
    let filterSets = this.state.filterSets
    let filterSet_copy = cloneDeep(filterSet)
    filterSet_copy.andOr = filterSets[1] ? filterSets[1].andOr : filterSets[0] ? filterSets[0].andOr : 'and'
    let newFilterSet = Object.assign({}, filterSet_copy)
    filterSets.push(newFilterSet)
    this.setState({ filterSets: filterSets }, () => this.props.setAdvancedFilters(filterSets))
  }

  handleAddItem(seletedSetIndex) {
    let filterSets = this.state.filterSets
    let filterItem_copy = cloneDeep(filterItem)
    filterItem_copy.andOr = filterSets[seletedSetIndex].items[1] ? filterSets[seletedSetIndex].items[1].andOr : filterSets[seletedSetIndex].items[0] ? filterSets[seletedSetIndex].items[0].andOr : 'and'
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [seletedSetIndex, 'items'], items => items.push(filterItem_copy))
    filterSets = filterSets.toJS()
    this.setState({ filterSets }, () => this.props.setAdvancedFilters(filterSets))
  }

  handleDeleteItem(setIndex, itemIndex) {
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)

    // Remove item from set
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => remove(items, itemIndex))

    // Remove empty set
    if (filterSets.get(setIndex).get('items').size == 0) filterSets = remove(filterSets, setIndex)

    filterSets = filterSets.toJS()
    this.setState({ filterSets }, () => this.props.setAdvancedFilters(filterSets))

    if (setIndex === 0 && itemIndex === 0 && filterSets.length === 0) {
      this.handleFilterChange(setIndex, itemIndex, filterItem)
    }
  }

  onChangeCheckBox(event) {
    const name = event.target.name
    const checked = event.target.checked
    this.setState({ [name]: checked }, () => { this.props.handleIncludeUMLS(checked) })
  }

  handleFilterChange(setIndex, itemIndex, item) {
    let item_clone = cloneDeep(item);
    if (item.fieldType == 'disease'){
      item_clone = cloneDeep(filterItem)
      item_clone.fieldType = item.fieldType;
      item_clone.andOr = item.andOr;
      item_clone.cancerType = item.cancerType;
      item_clone.relationalOperator = item.relationalOperator;
      item_clone.age = item.age;
    }
    if (item.fieldType == 'genetic_testing'){
      item_clone = cloneDeep(geneFilterItem)
      item_clone.fieldType = item.fieldType;
      item_clone.andOr = item.andOr;
      item_clone.gene = item.gene;
      item_clone.result = item.result;
      item_clone['Variant'] = item['Variant'];
    }
    if (item.fieldType == 'referral_criteria'){
      item_clone = cloneDeep(referralFilterItem)
      item_clone.fieldType = item.fieldType;
      item_clone.andOr = item.andOr;
      item_clone.referral = item.referral;
    }
    if (item.fieldType == 'survey'){
      item_clone = cloneDeep(surveyFilterItem)
      item_clone.fieldType = item.fieldType;
      item_clone.andOr = item.andOr;
      item_clone.surveyName = item.surveyName;
      item_clone.questionUUID = item.questionUUID;
      item_clone.surveyLabel = item.surveyLabel;
      item_clone.questionType = item.questionType;
      item_clone.answer = item.answer;
      item_clone.answer_2 = item.answer_2;
      item_clone.answer_3 = item.answer_3;
      item_clone.date_from = item.date_from;
      item_clone.date_to = item.date_to;
    }
    let filterSets = this.state.filterSets
    filterSets = fromJS(filterSets)
    filterSets = updateIn(filterSets, [setIndex, 'items'], items => updateIn(items, [itemIndex], _ => item_clone))
    filterSets = filterSets.toJS()

    for(let filter of filterSets[setIndex].items){
      filter.andOr = item_clone.andOr
    }
    this.setState({ filterSets: filterSets }, () => this.props.setAdvancedFilters(filterSets))
  }

  handleChangeSet(andOr) {
    let filterSets = this.state.filterSets
    for(let filterSet of filterSets){
      filterSet.andOr = andOr
    }
    this.setState({ filterSets: filterSets }, () => this.props.setAdvancedFilters(filterSets))
    // this.props.handleIncludeUMLS(this.state.includeUMLS)
  }

  isDiseaseFromUmls(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0, 1) === "C";
    return is_from_umls
  }

  checkUMLSCheckboxDisable() {
    let umls_disabled_options = ['any_other', 'no_other', 'no_UMLS', 'any_UMLS', 'any_disease', 'no_disease', 'any_other_conditions', 'no_other_conditions']
    let disable = false
    this.state.filterSets.forEach(item => {
      item.items.forEach(type => {
        if (this.isDiseaseFromUmls(type.cancerType) || umls_disabled_options.indexOf(type.cancerType) >= 0) {
          disable = true
        }
      })
    })
    return disable
  }
  umlsChecked() {
    let umls_checked = ['any_UMLS', 'no_UMLS']
    let checked = false
    this.state.filterSets.forEach(item => {
      item.items.forEach(type => {
        if (this.isDiseaseFromUmls(type.cancerType) || umls_checked.indexOf(type.cancerType) >= 0 || this.state.includeUMLS) {
          checked = true
        }
      })
    })
    return checked
  }

  setActiveAdvancedFilter(filter){
    this.setState({ activeAdvancedFilter: filter }, () => this.props.setActiveAdvancedFilter(filter))
  }

  async runQueryReport(){
    try{
      let umls_genes = this.props.static_list.genetic_testings_umls
      let umls_diseases = this.props.static_list.umls_diseases
      let diseases = this.props.static_list.diseases
      let disease_categories = this.props.static_list.disease_categories
      let surveyColumnsToAdd = this.state.filterSets.flatMap(filter => filter.items || []).filter(item => item.fieldType === 'survey').map(item => ({'value': item.questionUUID, 'label': item.surveyLabel, checked: true}))

      let payload = buildQueryPayload(this.state.activeAdvancedFilter, cloneDeep(this.state.filterSets), this.state.familyFilters, umls_genes, umls_diseases, diseases, disease_categories, true)
      let request = await family_api.query_search(payload)

      let result_payload = {
        query_result_ID: request.query_result_ID,
        max: MAX_PATIENTS
      }

      // let results = await family_api.get_query_results(result_payload)

      // console.log(results)

      // let results_payload = {
      //   query_result_ID: results.query_result_ID
      // }
      // let results_final = await family_api.get_query_results(results_payload)
      // console.log(results_final)

      this.props.setAdvancedQueryUIDs(request.query_ID, request.query_result_ID)
      this.props.setShowAdvancedFilters(false);
      this.props.setAdvancedFiltersResults(result_payload, 'advanced')
      // this.props.addSurveyColumns(surveyColumnsToAdd)
    }
    catch(e){
      console.log(e.stack)
    }

  }

  handleFamilyFiltersChange(value, field){
    let familyFilters = this.state.familyFilters
    if (field == 'operator'){
      familyFilters.number_of_people_to_meet_criteria.relationalOperator = value
    }
    else if(field == 'number_of_people'){
      familyFilters.number_of_people_to_meet_criteria.value = value
    }
    else if(field == 'relationship'){
      familyFilters.relationship = value
    }

    this.setState({ familyFilters }, () => this.props.setFamilyFilters(familyFilters))
  }


  render() {
    let btnClass = this.props.advanced_filters_results.status == 'Completed' ? 'btn-purple' : 'btn-light-outline';
    let disable_umls = this.checkUMLSCheckboxDisable()
    let umlsChecked = this.umlsChecked()
    let showDeleteButtonOnSetOne = false;

    if (this.state.filterSets.length === 1 && this.isActive()) {
      showDeleteButtonOnSetOne = true;
    }

    let colWidth = 'col-md-5';
    if ('colWidth' in this.props) colWidth = this.props.colWidth;

    let ageDisabled = this.state.familyFilters.number_of_people_to_meet_criteria.relationalOperator == 'any'

    let selected_family_filter_operator = operator_options.find(option => option.value == this.state.familyFilters.number_of_people_to_meet_criteria.relationalOperator)

    return (
      <React.Fragment >
        <p className='advanced-filters-header'>ADVANCED FILTERS</p>

        <div className={colWidth + " col-xs-12"} style={{ margin: '20px 0px' }}>
          <div className="btn-group btn-group2 btn-toggle" data-toggle="buttons">

            <label className={"btn btn-xs btn-light-advanced-filters btn-light-outline2 " + (this.state.activeAdvancedFilter == 'probands' ? 'active' : '')}>
              <input
                name="probands"
                onChange={(e) => this.setActiveAdvancedFilter('probands')}
                type="radio" checked={this.state.activeAdvancedFilter == 'probands'} />
              PROBANDS
            </label>
            <label className={"btn btn-xs btn-light-advanced-filters btn-light-outline2 " + (this.state.activeAdvancedFilter == 'everyone' ? 'active' : '')}>
              <input
                name="everyone"
                onChange={(e) => this.setActiveAdvancedFilter('everyone')}
                type="radio" checked={this.state.activeAdvancedFilter == 'everyone'} />
              EVERYONE
            </label>
            <label className={"btn btn-xs btn-light-advanced-filters btn-light-outline2 " + (this.state.activeAdvancedFilter == 'families' ? 'active' : '')}>
              <input
                name="families"
                onChange={(e) => this.setActiveAdvancedFilter('families')}
                type="radio" checked={this.state.activeAdvancedFilter == 'families'} />
              FAMILIES
            </label>

          </div>
        </div>

        <div className="popover-query-wrapper">
          <div id="advanced-filters"
            style={{ display: 'block' }}>

            <div className="popover-body popover-content">

              {this.state.activeAdvancedFilter == 'families' &&
                <div className="panel-form-3" style={{display: 'flex', flexDirection: 'column'}}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
                    <p>How many people in the family must meet the following criteria?</p>
                    <div className="col-md-2 col-xs-12">
                      <div className="custom-select">
                        <Select
                          className="custom-react-select-container"
                          classNamePrefix="custom-react-select"
                          onChange={(item) => this.handleFamilyFiltersChange(item.value, 'operator')}
                          value={selected_family_filter_operator}
                          options={operator_options}
                          autoFocus={false}
                          openMenuOnFocus
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-xs-12 no-padding-left">
                      <input
                        disabled={ageDisabled}
                        id={`age-input-cancer-${this.props.setIndex}-${this.props.itemIndex}`}
                        name="age"
                        value={this.state.familyFilters.number_of_people_to_meet_criteria.value}
                        onChange={(e) => this.handleFamilyFiltersChange(e.target.value, 'number_of_people')}
                        type="text" className="form-control" placeholder="" />
                    </div>
                  </div>

                    <div className="form-group" style={{display: 'flex', alignItems: 'center'}}>
                    <label className="control-label" style={{marginRight: 15}}>
                      Relationship
                    </label>

                      <div className="relationship-filter-wrapper">
                        <RadioGroup
                          name="relationship"
                          radioWrapClassName="form-check form-check-inline p-0"
                          radioWrapTag="div"
                          value={this.state.familyFilters.relationship}
                          onChange={(e, value) => {
                            this.handleFamilyFiltersChange(value, 'relationship');
                          }}
                        >
                          <Radio
                            value={'anyone'}
                            radioClass="iradio_flat-green"
                            increaseArea="20%"
                            label="&nbsp;Anyone"
                          />

                          <Radio
                            value={'blood_relatives'}
                            radioClass="iradio_flat-green"
                            increaseArea="20%"
                            label="&nbsp;Blood relatives"
                          />

                          <Radio
                            value={'same_side_of_the_family'}
                            radioClass="iradio_flat-green"
                            increaseArea="20%"
                            label="&nbsp;Same side of the family"
                          />

                          <Radio
                            value={'maternal_only'}
                            radioClass="iradio_flat-green"
                            increaseArea="20%"
                            label="&nbsp;Maternal only"
                          />

                          <Radio
                            value={'paternal_only'}
                            radioClass="iradio_flat-green"
                            increaseArea="20%"
                            label="&nbsp;Paternal only"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
              }

              <br></br>

              {this.state.filterSets.map((set, setIndex) => {
                return (<FilterSet
                  key={createUUID()}
                  set={set}
                  totalSets={this.state.filterSets.length}
                  setIndex={setIndex}
                  showDeleteButtonOnSetOne={showDeleteButtonOnSetOne}
                  onChangeSet={(andOr) => this.handleChangeSet(andOr, setIndex)}
                  onChangeItem={(item, itemIndex) => this.handleFilterChange(setIndex, itemIndex, item)}
                  onAddItemClick={() => this.handleAddItem(setIndex)}
                  onDeleteItemClick={(itemIndex) => this.handleDeleteItem(setIndex, itemIndex)}
                />)
              })}

              <div className="margin-two">
                <a onClick={this.handleAddSet} className="btn btn-teal btn-xs">Add Set</a>
              </div>

            </div>

          </div>
        </div>

        <div className="section-footer">
          <button
            onClick={() => this.runQueryReport(this.state.filterSets)}
            className={"btn btn-dark btn-sm"} ><i className="fa fa-arrow-right text-white"></i>Run Report</button>
          <button
            style={{marginRight: -5}}
            onClick={() => {
              this.props.setShowAdvancedFilters(false);
              if(this.props.advanced_filters_results.status == 'Running'){
                this.props.runAdvancedQuery();
              }
            }}
            className={"btn btn-light btn-sm"} ><i className="fa fa-arrow-left text-white"></i>Cancel</button>
        </div>
      </React.Fragment>
    )
  }
}

/*
Functional Components
*/

const FilterSet = (props) => {
  return (
    <React.Fragment>

      {props.setIndex > 0 &&
        <div className="row margin-two">
          <InputAndOr
            value={props.set.andOr}
            onChange={(e) => props.onChangeSet(e.target.value)}
          />
        </div>
      }

      <div className="panel-form-3">

        {props.set.items.map((item, itemIndex) => {
          const showDeleteButton = () => {
            if (itemIndex === 0 && props.setIndex === 0 && props.totalSets === 1) {
              return props.showDeleteButtonOnSetOne;
            }
            else {
              return props.set.items.length > 1 || props.totalSets > 1
            }
          }

          return (
            <div key={createUUID()} className={"row margin-one " + (itemIndex == 0 ? 'padding-left--15' : '')}>

              <AdvancedFilterInput
                key={createUUID()}
                item={item}
                itemIndex={itemIndex}
                setIndex={props.setIndex}
                onAddItemClick={() => props.onAddItemClick()}
                onDeleteItemClick={() => props.onDeleteItemClick(itemIndex)}
                onChange={(data) => props.onChangeItem(data, itemIndex)}
                showDeleteButton={showDeleteButton()}
                showAddButton={itemIndex == props.set.items.length - 1}
              />

            </div>
          )

        })}

      </div>

    </React.Fragment>
  )
}

/* Functional Components */

const redux_state = state => ({
  static_list: state.static_list,
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(AdvancedFilters);
