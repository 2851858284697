import React, { Component, MouseEventHandler, useState } from 'react';
import ReactDOM from 'react-dom';
import SuperAccountDetails from '../../components/super-accounts/super-account-details';
import StaffTable from '../../components/sub-accounts/staff-table';
import SubAccountsTable from '../../components/sub-accounts/sub-accounts-table';
import SubAccountDetails from '../../components/sub-accounts/sub-account-details';
import { createUUID } from '../react-flow-pedigree/utils';
import ModalStaff from '../sub-accounts/modal-staff';
import account_api from '../../api/user-account-api';
import authentication_api from '../../api/authentication';
import LicensingTab from '../../components/sub-accounts/licensing-tab';
import PatientDocuments from '../../components/patient-profile/patient-data-tabs/patient-documents';

interface Props extends React.PropsWithChildren<{}> {
    toggleEditSuperOrgModal: () => void;
    toggleEditSubOrgModal: () => void;
    viewSubOrg: (org_id: string) => void;
    newSubOrg: () => void;
    openStaffModal: () => void;
    closeStaffModal: () => void;
    onSaveStaffMember: (payload: { staffKey: any; staffMember: any; }) => void;
    refreshStaff: () => void;
    showStaffModal: boolean;
    addingNewStaff: boolean;
    headerBtnCssClass: string;
    showEdit: boolean;
    survey_display: {};
    org_id: string;
    org: {
        id: string;
        org_type: string;
    };
    login_type: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    setOrgLicenseState: any;
}

type StaffUpdateFunction = () => void;

type State = {
    activeTab: string;
    staff_member: {};
    staff_member_key: string;
    showStaffModal: boolean;
    addingNewStaff: boolean;
    loginTypeOptions: [];
    staff: {};
    staffUpdateReference: StaffUpdateFunction;
};

class AccountTabDisplay extends Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        activeTab: "account",
        staff_member: {},
        staff_member_key: '',
        showStaffModal: false,
        addingNewStaff: false,
        loginTypeOptions: [],
        staff: {},
        staffUpdateReference: () => {}
      }
      this.openStaffModal    = this.openStaffModal.bind(this);
      this.closeStaffModal   = this.closeStaffModal.bind(this);
      this.onSaveStaffMember = this.onSaveStaffMember.bind(this);
      this.setCallBackTrigger = this.setCallBackTrigger.bind(this);
    }
    async componentDidMount() {
      const loginTypeOptions = await account_api.get_org_sso();
      this.setState({loginTypeOptions: loginTypeOptions});
    }

    setCallBackTrigger(callbackTrigger: StaffUpdateFunction) {
      this.setState({staffUpdateReference: callbackTrigger});
    }

    async onSaveStaffMember(payload: { staffKey: any; staffMember: any; }) {
      // doing a deep clone here because there are a few nested objects/arrays in the state
      // {staffKey: {...}, ...}
      let { staffKey, staffMember } = payload;
      const json = {
          username: staffMember.username,
          first_name: staffMember.first_name,
          last_name: staffMember.last_name,
          login_type: staffMember.login_type,
          email: staffMember.email,
          is_lock: staffMember.is_lock,
          access_type: (staffMember.admin) ? 'admin' : 'normal',
          organization: this.props.org.id
      };

      try {
        if (this.state.addingNewStaff) {
          const new_staff_response = await account_api.post_new_staff(json);

          await authentication_api.send_password_reset_email(staffMember.username, true);

          // add expiration date to staff if org is trial
          // use username to find account -> find staff based on account id
          await account_api.update_trial_staff(new_staff_response.staff.id, staffMember.trial_expiration);
        }
      } catch (error) {
        console.log(error);
      }

      if (this.state.staffUpdateReference) {
        await this.state.staffUpdateReference();
      }
      this.setState({showStaffModal: false, staff_member: {}, staff_member_key: ''});
    }

    openStaffModal = () => {
      let staff_member_key = createUUID();
      let staff_member = {
        staff_id: null,
        user_id: null,
        account_id: null,
        username: '',
        first_name: '',
        last_name: '',
        login_type: '',
        email: '',
        admin: false,
        is_lock: false,
        trial_expiration: null,
      };

      this.setState({
        showStaffModal: true,
        staff_member: staff_member,
        staff_member_key: staff_member_key,
        addingNewStaff: true,
      });
    }

    closeStaffModal() {
      this.setState({showStaffModal: false, staff_member: {}, staff_member_key: ''});
    }

    render() {

      return (
        <>

          <div className="form-header-2">
              <div className="row vcenter">
          		<div className="col-md-5" style={{marginRight: '-15px'}}>
                      <ul className="nav nav-tabs">
                          <li className={this.state.activeTab === "account" ? "active" : ""}>
                              <button className="btn-link" onClick={() => this.setState({activeTab:"account"})}>
                                  Account
                              </button>
                          </li>
                          <li className={this.state.activeTab === "staff" ? "active" : ""}>
                              <button className="btn-link" onClick={() => this.setState({activeTab:"staff"})}>
                                  Staff
                              </button>
                          </li>
                          {/* Make it to where License will only show if the signed in org is super admin*/}
                          {this.props.isSuperAdmin && (
                              <li className={this.state.activeTab === "licensing" ? "active" : ""}>
                                  <button
                                  className="btn-link"
                                  onClick={() => this.setState({activeTab:"licensing"})}
                                  >
                                  Licensing
                                  </button>
                              </li>
                          )}
                          {this.props.isSuperAdmin && (
                            <li className={this.state.activeTab === "documents" ? "active" : ""}>
                                <button
                                className="btn-link"
                                onClick={() => this.setState({activeTab:"documents"})}
                                >
                                Documents
                                </button>
                            </li>
                          )}
                      </ul>
                  </div>

                  {this.state.activeTab === "account" && (['super_org', 'super_admin', 'standard_org', 'trial_org', 'free', 'student'].includes(this.props.org.org_type)) &&
                      (this.props.showEdit  && (
                          <div className="col-md-8 nav-tabs" style={{marginRight: '15px'}}>
                              <button className="btn btn-light btn-xs" style={{marginBottom: '7px'}} onClick={this.props.toggleEditSuperOrgModal}><i className="fa fa-pencil"></i> Edit Account </button>
                          </div>
                          )
                      )
                  }

                  {this.state.activeTab === "account" && (this.props.org.org_type === 'sub_org' ) &&
                      (this.props.showEdit  && (
                          <div className="col-md-8 nav-tabs" style={{marginRight: '15px'}}>
                              <button className="btn btn-light btn-xs" style={{marginBottom: '7px'}} onClick={this.props.toggleEditSubOrgModal}><i className="fa fa-pencil"></i> Edit Account </button>
                          </div>
                          )
                      )
                  }

                  {this.state.activeTab === "staff" &&
                      (   (
                          <div className="col-md-8 nav-tabs" style={{marginRight: '15px'}}>
                              <button className="btn btn-light btn-xs" style={{marginBottom: '7px'}} onClick={this.openStaffModal}><i className="fa fa-plus"></i> Add New Staff</button>
                          </div>
                          )
                      )
                  }

            </div>
          </div>

          {this.state.activeTab === "account" && (['super_org', 'super_admin'].includes(this.props.org.org_type)) &&
            <>
              <SuperAccountDetails org={this.props.org} survey_display={this.props.survey_display} />
              <section className="wow fadeIn section" style={{visibility: 'visible', animationName: 'fadeIn'}}>
                  <SubAccountsTable showFooter={true}
                    showFilter={true}
                    parentOrgId={this.props.org.id}
                    newSubOrg={this.props.newSubOrg}
                    viewSubOrg={(org_id: any) => this.props.viewSubOrg(org_id)}
                    superAdmin={true}
                  />
              </section>
            </>
          }

          {this.state.activeTab === "account" && (['sub_org', 'standard_org', 'trial_org', 'free', 'student'].includes(this.props.org.org_type)) &&
            <SubAccountDetails org={this.props.org} survey_display={this.props.survey_display} />
          }

          {this.state.activeTab === "staff" &&
            <StaffTable
              setCallbackTrigger={(callbackTrigger: any) => this.setCallBackTrigger(callbackTrigger)}
              showFooter={true}
              showFilter={true}
              headerBtnCssClass="btn btn-light btn-xs"
              org_id={this.props.org.id}
              org={this.props.org}
            />
          }

        {/* Add the information to be displayed in the license tab here */}
        {this.state.activeTab === "licensing" && this.props.org.id &&
            <>
                <br></br>
                <LicensingTab
                    org={this.props.org}
                    setOrgLicenseState={this.props.setOrgLicenseState}
                />
            </>
        }

        {this.state.activeTab === "documents" && this.props.org.id &&
          <>
            <br></br>
            <PatientDocuments org={this.props.org} isFamhisSuperAdmin={true}/>
          </>
        }

          {this.state.showStaffModal && (
            <ModalStaff
              loginTypeOptions = {this.state.loginTypeOptions}
              staffMember={this.state.staff_member}
              staffKey={this.state.staff_member_key}
              addingNewStaff={this.state.addingNewStaff}
              onClose={this.closeStaffModal}
              onClickSave={(payload: { staffKey: any; staffMember: any; }) => this.onSaveStaffMember(payload)}
              org={this.props.org}
            />
          )}

        </>
      );
    };
};
export default AccountTabDisplay;
