import React, { Component } from 'react';
import risk_api from "../../api/risk-api";
import familyApi from "../../api/family-api";
import helper_family_api from '../../helpers/helper-family-api';
import helper_dom_printing from '../../helpers/helper-dom-pdf'
import * as model from "../../model-states/m-family-tree";
import Cookie from 'js-cookie';
import colors_255 from "../../assets/json/colors-255.json";
import { clone, cloneDeep, isEmpty } from "lodash";
import ModalConfirmDelete from "../modal-confirm-delete";
import LegendDOM from './legendDOM';
import constants from './constants';
import Node_Line_Store from "./Node_Line_Store";
import ErrorSummary from '../error-summary'
import helper from '../../helpers/index'
import ModalFamilyHistory from '../modal-family-history'
import ActivityIndicator from "../activity-indicator";

import ModalPopUpBlockerAlert from '../modal-popup-blocker-alert'

import { buildSmartDrawPayload, getCenterPoint, createUUID } from './utils';
import { FATHER_ANCESTRY_ID, MOTHER_ANCESTRY_ID } from './ancestry-node';
import { documentToSVG, elementToSVG } from 'dom-to-svg'

import route_helper from '../../route-helper'

import Pedigree from './pedigree';
import PedigreeToolbar from './pedigree-toolbar';
import PedigreeSidebar from './pedigree-sidebar';
import $ from 'jquery'
import {
  PedigreeStylesheet,
  MinZoom,
  MaxZoom,
  defaultViewport,
  ApiNodeTypes,
  ApiEdgeTypes,
  ClientSideNodeTypes,
  ClientSideEdgeTypes,
  PartnerOptions,
  ViewStatusOptions,
  CookieKeys,
  showPersonID,
  oneClickAddActionTypes as actions
} from './pedigree-constants';

import ArchiveDataTableDom from './progeny-archived-data/archive-data-table-dom';
import {set_cookie} from "../../helpers/helper-cookies";
import add_btn from "../../assets/production/images/family-add-btn.png"
import edit_btn from "../../assets/production/images/family-edit-btn.png"

class PedigreeWrapper extends Component {
  constructor(props) {
    super(props);
    this.pedigreeRef = React.createRef();
    this.state = {
      errorMessages: [],
      showPedigree: false,
      nodes: [],
      nodeParentMap: [],
      nodeDiseaseColorMap: {},
      pedigreeHeight: 0,
      pedigreeWidth: 0,
      selectedNode: null,
      showSideBar: false,
      showFamilyHistoryModal: false,

      openModalConfirmDelete: false,
      openCannotDeleteModal: ["none", false],
      openPrintingErrorModal: false,
      modalConfirmDeleteHash: new Date().getTime(),
      delete_msg: null,
      loading_patient_pedigree: false,
    }

	  this.datastore = new Node_Line_Store();
    this.reactFlowInstance = null;
    this.pedigreeCallbacks = null;
    this.zoomCallback = null;
    this.zoom = defaultViewport.zoom;

    this.getCookies = this.getCookies.bind(this);
    this.setCookie = this.setCookie.bind(this);

    this.setData = this.setData.bind(this);
	  this.getNodes = this.getNodes.bind(this);
    this.getPedigreeHeight = this.getPedigreeHeight.bind(this);
    this.getPedigreeWidth = this.getPedigreeWidth.bind(this);
    this.buildDiseaseColorMap = this.buildDiseaseColorMap.bind(this);
    this.getColor = this.getColor.bind(this);
    this.buildAncestryNodes = this.buildAncestryNodes.bind(this);
    this.saveLayoutData = this.saveLayoutData.bind(this);
    this.redrawToOriginalPositions =  this.redrawToOriginalPositions.bind(this);

    this.curatedZoom = this.curatedZoom.bind(this);
    this.nextZoom = this.nextZoom.bind(this);
    this.reRenderLegend = this.reRenderLegend.bind(this);
    this.buildLegend = this.buildLegend.bind(this);
    this.onSavePedigreeNotes = this.onSavePedigreeNotes.bind(this);
    this.onClickLegendEye = this.onClickLegendEye.bind(this);
    this.reactFlowLoaded = this.reactFlowLoaded.bind(this);
    this.resizePedigree = this.resizePedigree.bind(this);
    this.handleZoomInClick = this.handleZoomInClick.bind(this);
    this.handleZoomOutClick = this.handleZoomOutClick.bind(this);
    this.handleActualSizeClick = this.handleActualSizeClick.bind(this);
    this.handleFitToScreenClick = this.handleFitToScreenClick.bind(this);
    this.handleShrinkCheck = this.handleShrinkCheck.bind(this);
    this.handleSaveImageClick = this.handleSaveImageClick.bind(this);
    this.handleToggleViewStatus = this.handleToggleViewStatus.bind(this);
    this.handleToggleLegend = this.handleToggleLegend.bind(this);
    this.handleToggleBlackAndWhite = this.handleToggleBlackAndWhite.bind(this);
    this.handleTogglePedigreeNotes = this.handleTogglePedigreeNotes.bind(this);
    this.handleToggleAncestry = this.handleToggleAncestry.bind(this);
    this.handleToggleShowProgenyArchivedData = this.handleToggleShowProgenyArchivedData.bind(this);
    this.handleToggleGeneticTesting = this.handleToggleGeneticTesting.bind(this);
    this.handleToggleNotes = this.handleToggleNotes.bind(this);

    this.onNodeSelected = this.onNodeSelected.bind(this);
    this.onClearNodeSelection = this.onClearNodeSelection.bind(this);
    this.handleSideBarClose = this.handleSideBarClose.bind(this);
    this.handleInfoAndHistoryUpdate = this.handleInfoAndHistoryUpdate.bind(this);
    this.handleDiseaseUpdate = this.handleDiseaseUpdate.bind(this);
    this.handleGeneTestUpdate = this.handleGeneTestUpdate.bind(this);
    this.handleDiseaseDelete = this.handleDiseaseDelete.bind(this);
    this.handleGeneTestDelete = this.handleGeneTestDelete.bind(this);
    this.onClickOpenModalConfirmDelete = this.onClickOpenModalConfirmDelete.bind(this);
    this.addTwinsEntry = this.addTwinsEntry.bind(this);
    this.validateDeleteOrFail = this.validateDeleteOrFail.bind(this);
    this.handleCheckDeleteSelectedPerson = this.handleCheckDeleteSelectedPerson.bind(this);
    this.handleDeleteSelectedPerson = this.handleDeleteSelectedPerson.bind(this);
    this.deleteFromPedigreeData = this.deleteFromPedigreeData.bind(this);
    this.deleteTwinsEntry = this.deleteTwinsEntry.bind(this);
    this.handleZoomTo = this.handleZoomTo.bind(this);
    this.updateZoomValue = this.updateZoomValue.bind(this);
    this.onLoadPedigreeToolbar = this.onLoadPedigreeToolbar.bind(this);

    this.getChildGender = this.getChildGender.bind(this);
    this.getSpouse = this.getSpouse.bind(this);
    this.addParents = this.addParents.bind(this);
    this.addChildren = this.addChildren.bind(this);
    this.addPartner = this.addPartner.bind(this);
    this.addPartnerWithChildren = this.addPartnerWithChildren.bind(this);
    this.getSiblingGender = this.getSiblingGender.bind(this);
    this.addSiblings = this.addSiblings.bind(this);

    this.getDeleteMsg = this.getDeleteMsg.bind(this);
    this.isNoChildrenOrInfertility = this.isNoChildrenOrInfertility.bind(this);

    this.reRenderPedigree = this.reRenderPedigree.bind(this);
    this.handlePdfFromAPI = this.handlePdfFromAPI.bind(this);
    this.prepareForPrint = this.prepareForPrint.bind(this);
    this.clearPrintPdf = this.clearPrintPdf.bind(this)
    this.removePrintHeader = this.removePrintHeader.bind(this);

    this.runRiskCriteria = this.runRiskCriteria.bind(this);
    this.headlessPrinting = this.headlessPrinting.bind(this);
    this.printPedigree = this.printPedigree.bind(this);
    this.getPrintWidth = this.getPrintWidth.bind(this);
    this.getPrintHeight = this.getPrintHeight.bind(this);
    this.createBlob = this.createBlob.bind(this);
    this.viewArchivedDataTable = this.viewArchivedDataTable.bind(this);
    this.removeArchiveTable = this.removeArchiveTable.bind(this);
    this.archiveDataTableCheck = this.archiveDataTableCheck.bind(this);
    this.setArchiveTablePosition = this.setArchiveTablePosition.bind(this);
    this.setArchiveTableHeaders = this.setArchiveTableHeaders.bind(this);
    this.setArchiveTableWidth = this.setArchiveTableWidth.bind(this);
    this.editArchiveTableValue = this.editArchiveTableValue.bind(this);

    this.sendAddMessageToApp = this.sendAddMessageToApp.bind(this);
    this.sendEditMessageToApp = this.sendEditMessageToApp.bind(this);
    this.showFamilyHistoryModal = this.showFamilyHistoryModal.bind(this);
    this.legendExistsOnDom = this.legendExistsOnDom.bind(this);

    this.archiveTables = {}
    this.openArchiveIds = []
    this.redrawOnLegend = false;
    const archiveTableData = {
      tableKey: null,
      showArchiveTable: false,
      memberId: null,
      memberUid: null,
      archiveTablePosition: null,
      archiveTableHeaders: null,
      archiveTableTitle: null,
      archiveTableWidth: null,
      archiveTableHeight: null
    };

    const render_patient_pedigree = sessionStorage.getItem('render-patient-pedigree');
    this.render_patient_pedigree = render_patient_pedigree == 'true';
  }

  async componentDidMount(){
    try {
      // add fake nodes to the pedigree data store first
      // before calling the layout endpoint
      await this.addFakePartnersAndChildren();
      await this.loadSavedNodes();
    } catch(e) {
      console.log(e.stack);
    }

    window.addEventListener('message', async (event) => {
      // patient web portal print event listener
      if (event.data == 'web-pedigree-iframe-print'){
        this.patientPedigreePrint(event);
      }
      // patient web portal reload event listener
      else if (event.data == 'web-reload-pedigree'){
        this.setState({ loading_patient_pedigree: true });
        await this.props.fetchProbandTree();
        await this.reRenderPedigree();
        this.setState({ loading_patient_pedigree: false });
      }
    })

    // patient app print event listener
    window.addEventListener("mobile-print", (e) => {
      this.prepareForPrint(true, true);
    }, false)

    // patient app reload event listener
    window.addEventListener("mobile-pedigree-reload", async(e) => {
      this.setState({ loading_patient_pedigree: true });
      await this.props.fetchProbandTree();
      await this.reRenderPedigree();
      this.setState({ loading_patient_pedigree: false });
    }, false)


    window.addEventListener("resize", this.resizePedigree);
    window.print = this
    this.overflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    this.setCookie(CookieKeys.SIDEBAR_SHOW_MORE, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizePedigree);

    let consanguineous_tooltip = document.querySelectorAll('#consanguineous-tooltip');
    for (var i = consanguineous_tooltip.length; i--;) {
      consanguineous_tooltip[i].style.visibility = 'hidden';
    }

    let reassign_parents_tooltip = document.querySelectorAll('#re-assign-parents-tooltip');
    for (var i = reassign_parents_tooltip.length; i--;) {
      reassign_parents_tooltip[i].style.visibility = 'hidden';
    }
    window.removeEventListener('mousemove', this.consanguineousToolTipTrack, false);
    window.removeEventListener('mousemove', this.reAssignParentsToolTipTrack, false);

    document.body.style.overflow = this.overflow;
    document.body.style.overflow = "visible";
  }
  legendExistsOnDom() {
    let existsOnDom = false
    let displayLegend = Cookie.get(CookieKeys.PEDIGREE_LEGEND);
    let displayPedigreeNotes = Cookie.get(CookieKeys.PEDIGREE_LEGEND_NOTES)
    if(displayPedigreeNotes == 'true' || displayLegend == 'true') existsOnDom = true

    return existsOnDom
  }

  async loadSavedNodes(collapse) {
    let legendExists = this.legendExistsOnDom();
    let saved_data = this.props.getPedigreeData().getSavedNodePositions();

    //only if there are saved positions
    let partners_option = Cookie.get(CookieKeys.PARTNER_OPTION)
    if(saved_data){
      let initial_data = null
      if((collapse !== null && collapse !== undefined && collapse === true)){
        initial_data = await this.partnersHiddenCollapsePedigreeWithSavedNodes(partners_option);
      }
      else{
        initial_data = await this.getNodes();

        let saved_data_store = [];
        if(partners_option === PartnerOptions.HIDE_PARTNERS){
          saved_data_store = cloneDeep(saved_data.data_hide_partners)
        }
        else if(partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS){
          saved_data_store = cloneDeep(saved_data.data_show_affected_partners)
        }
        else{
          saved_data_store = cloneDeep(saved_data.data)
        }
        for (let node of initial_data.nodes) {
          if ('position' in node) {
            if(Array.isArray(saved_data_store)){
              let saved_node = saved_data_store.find(saved => saved.id == node.id);
              let legendOffset = legendExists ? 150 : 0;
              if (saved_node) {
                node.position.x = (saved_node.position.x + legendOffset)
                node.position.y = saved_node.position.y
              }else{
                node.position.x += legendOffset
              }
            }
          }
        }

        // another way to insert the saved positions, leaving this for when future issues come up
        // let saved_datastore = cloneDeep(saved_data.data.find(saved => 'people' in saved))
        // for(let node of saved_data.data){
        //   if(node.data){
        //     node.data.datastore = this.datastore
        //     node.data.datastore.edges = saved_datastore.edges
        //     node.data.datastore.line_ID_List = saved_datastore.line_ID_List
        //     node.data.datastore.lines = saved_datastore.lines
        //     node.data.datastore.nodes = saved_datastore.nodes
        //     node.data.datastore.people = saved_datastore.people
        //     node.data.datastore.reverseSpouse = saved_datastore.reverseSpouse
        //     node.data.datastore.spouseMap = saved_datastore.spouseMap
        //     node.data.datastore.spousesTemp = saved_datastore.spousesTemp
        //   }
        // }
        // initial_data.nodes = saved_data.data

      }

      this.setData(initial_data);
      return initial_data;
    }
    else{
      const initial_data = await this.getNodes();

      if(legendExists){
        for (let node of initial_data.nodes) {
          if ('position' in node) {
              let legendOffset = legendExists ? 150 : 0;
              node.position.x += legendOffset
          }
        }
      }
    this.setData(initial_data);
    return initial_data;
  }
}

  patientPedigreePrint(event){
    if (event.data == 'web-pedigree-iframe-print'){
      this.prepareForPrint(true)
    }
  }

  async partnersHiddenCollapsePedigreeWithSavedNodes(partners_option){

    let saved_data = this.props.getPedigreeData().getSavedNodePositions();

    // this is when we hide partners and there are no saved positions for when hiding partners and only showing affected partners,
    // thus we have to collapse according to the saved positions when all people are visible
    if((partners_option === PartnerOptions.HIDE_PARTNERS && isEmpty(saved_data.data_hide_partners)) || (partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS && isEmpty(saved_data.data_show_affected_partners))){
      let showAllPeople = true;

      // get the original layout with all people
      let nodes_original_layout_with_all_people = await this.getNodes(showAllPeople);
      let nodes_with_saved_positions_with_all_people = cloneDeep(nodes_original_layout_with_all_people.nodes)
      let node_differences = []

      // get the difference of each nodes compared to the saved positions of the original layout with all people
      for(let node of nodes_with_saved_positions_with_all_people){
        if ('position' in node){
          if(Array.isArray(saved_data.data)){
            let saved_node = saved_data.data.find(saved => saved.id == node.id);
            if(saved_node){
              let diff = {
                id: node.id,
                x: saved_node.position.x - node.position.x,
                y: saved_node.position.y - node.position.y,
              }
              node_differences.push(diff)
            }
          }
        }
      }

      showAllPeople = false
      // get the original layout with original positions without the hidden partners
      let nodes_original_layout_without_hidden_partners = await this.getNodes(showAllPeople);

      // add the position differences to the layout without the hidden partners
      for(let node of nodes_original_layout_without_hidden_partners.nodes){
        if ('position' in node){
          let node_diff = node_differences.find(diff => diff.id == node.id)
          if(node_diff){
            node.position.x += node_diff.x
            node.position.y += node_diff.y
          }
        }
      }

      this.setData(nodes_original_layout_without_hidden_partners);
      // do we save the nodes when we collapse?
      // await this.saveLayoutData(nodes_original_layout_without_hidden_partners.nodes);
      return nodes_original_layout_without_hidden_partners;

    }

    //this is for when there are saved positions for hide partners and show affected partners, so there's no need to collapse the pedigree anymore
    else if ((partners_option === PartnerOptions.HIDE_PARTNERS && !isEmpty(saved_data.data_hide_partners)) || (partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS && !isEmpty(saved_data.data_show_affected_partners))){
      let nodes_original_layout_without_hidden_partners = await this.getNodes(false);

      let saved_data_store = []
      if(partners_option === PartnerOptions.HIDE_PARTNERS){
        saved_data_store = cloneDeep(saved_data.data_hide_partners)
      }
      else if(partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS){
        saved_data_store = cloneDeep(saved_data.data_show_affected_partners)
      }

      for (let node of nodes_original_layout_without_hidden_partners.nodes) {
        if ('position' in node) {
          if(Array.isArray(saved_data_store)){
            let saved_node = saved_data_store.find(saved => saved.id == node.id);
            if (saved_node) {
              node.position.x = saved_node.position.x
              node.position.y = saved_node.position.y
            }
          }
        }
      }
      this.setData(nodes_original_layout_without_hidden_partners);
      return nodes_original_layout_without_hidden_partners;
    }

  }

  getActiveSavedNodePositions(){
    let saved_data = this.props.getPedigreeData().getSavedNodePositions();
    let partners_option = Cookie.get(CookieKeys.PARTNER_OPTION)

    let saved_data_store = [];
    if (partners_option === PartnerOptions.HIDE_PARTNERS) {
      saved_data_store = cloneDeep(saved_data.data_hide_partners)
    }
    else if (partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS) {
      saved_data_store = cloneDeep(saved_data.data_show_affected_partners)
    }
    else {
      saved_data_store = cloneDeep(saved_data.data)
    }
    return saved_data_store
  }

  getCookies() {
    let viewStatus = Cookie.get(CookieKeys.PEDIGREE_VIEW_STATUS);
    let displayLegend = Cookie.get(CookieKeys.PEDIGREE_LEGEND);
    let displayPedigreeNotes = Cookie.get(CookieKeys.PEDIGREE_LEGEND_NOTES)
    let displayAncestry = Cookie.get(CookieKeys.PEDIGREE_ANCESTRY);
    let displayGeneticTesting = Cookie.get(CookieKeys.PEDIGREE_GENETIC_TESTING);
    let displayBlackAndWhite = Cookie.get(CookieKeys.PEDIGREE_BLACK_AND_WHITE);
    let showNotes = Cookie.get(CookieKeys.PEDIGREE_NOTES);
    let alwaysShrink = Cookie.get(CookieKeys.ALWAYS_SHRINK);
    let partnersOption = Cookie.get(CookieKeys.PARTNER_OPTION);
    let displayProgenyArchiveData = Cookie.get(CookieKeys.PEDIGREE_PROGENY_ARCHIVE_DATA);

    if (viewStatus === undefined || viewStatus === null) {
      this.setCookie(CookieKeys.PEDIGREE_VIEW_STATUS, ViewStatusOptions.IDENTIFIED);
      viewStatus = ViewStatusOptions.IDENTIFIED;
    }

    if (alwaysShrink === undefined || alwaysShrink === null) {
      this.setCookie(CookieKeys.ALWAYS_SHRINK, "0");
      alwaysShrink = "0";
    }

    if (displayLegend === undefined || displayLegend === null) {
      this.setCookie(CookieKeys.PEDIGREE_LEGEND, 'true');
      displayLegend = 'true';
    }

    if (displayPedigreeNotes === undefined || displayPedigreeNotes === null) {
      if(this.render_patient_pedigree) {
        this.setCookie(CookieKeys.PEDIGREE_LEGEND_NOTES, 'false');
        displayPedigreeNotes = 'false';
      } else {
        this.setCookie(CookieKeys.PEDIGREE_LEGEND_NOTES, 'true');
        displayPedigreeNotes = 'true';
      }
    }

    if (displayAncestry === undefined || displayAncestry === null) {
      this.setCookie(CookieKeys.PEDIGREE_ANCESTRY, 'true');
      displayAncestry = 'true';
    }

    if (displayGeneticTesting === undefined || displayGeneticTesting === null) {
      this.setCookie(CookieKeys.PEDIGREE_GENETIC_TESTING, 'true');
      displayGeneticTesting = 'true';
    }

    if (displayBlackAndWhite === undefined || displayBlackAndWhite === null) {
      this.setCookie(CookieKeys.PEDIGREE_BLACK_AND_WHITE, 'false');
      displayBlackAndWhite = 'false';
    }

    if (showNotes === undefined || showNotes === null) {
      this.setCookie(CookieKeys.PEDIGREE_NOTES, 'true');
      showNotes = 'true';
    }

    partnersOption = this.getPartnersOption()

    if (displayProgenyArchiveData === undefined || displayProgenyArchiveData === null) {
      this.setCookie(CookieKeys.PEDIGREE_PROGENY_ARCHIVE_DATA, 'false');
      displayProgenyArchiveData = 'false';
    }

    return {
      viewStatus: viewStatus,
      displayLegend: displayLegend,
      displayPedigreeNotes: displayPedigreeNotes,
      displayAncestry: displayAncestry,
      displayGeneticTesting: displayGeneticTesting,
      displayBlackAndWhite: displayBlackAndWhite,
      showNotes: showNotes,
      alwaysShrink: alwaysShrink,
      partnersOption: partnersOption,
      displayProgenyArchiveData: displayProgenyArchiveData
    }
  }

  setCookie(name, value) {
    set_cookie(name, value);
  }

  async selectNode(member_id){
    await helper.delay(0.3)

    let nodes = cloneDeep(this.pedigreeCallbacks.elements);
    for(let node of nodes){
      if(node.id == member_id){
        node.data.selected_after_pedigree_load = true;
        this.setState({ selectedNode: node })
      }
    }

    await this.pedigreeCallbacks.setElements(nodes)
  }

  async reactFlowLoaded(instance, callbacks) {
    this.reRenderLegend();

    console.log("loaded")
    this.reactFlowInstance = instance;
    this.pedigreeCallbacks = callbacks;

    // note: this is done after setting react-flow instance
    const cookies = this.getCookies();
    const alwaysShrink = cookies.alwaysShrink === "1";
    if (alwaysShrink) this.handleShrinkCheck(alwaysShrink);


    if(this.props.member_id_toBeSelected){
      await this.selectNode(this.props.member_id_toBeSelected)
    }

    await this.updateSubtextMargin(this.state.nodes);

    let all_pedigree_handles = document.querySelectorAll('.pedigree-handle');

    for(let handle of all_pedigree_handles){
      let toolTipTimeout = null;
      handle.addEventListener('mouseover', function(event) {
        toolTipTimeout = setTimeout(function() {
          let children = event.target.children;
          for(let child of children){
            child.style.visibility = 'visible';
          }
        }, 500); // Delay of 500ms
      });

      handle.addEventListener('mouseout', function (event) {
        clearTimeout(toolTipTimeout);
        let children = event.target.children;
        for (let child of children) {
          child.style.visibility = 'hidden';
        };
      });
    }

    if(this.render_patient_pedigree) {
      this.handleToggleLegend(false);
    }

  }

  updateSubtextMargin(nodes){

    let all_nodes = this.reactFlowInstance.getNodes().filter(node => node.type != 'ancestry')
    for(let node of all_nodes){
      if(!node.data.profile){
        continue;
      }
      let subtextElement = document.getElementById(`domSubtext${node.data.profile.id}`)
      if(nodes){
        // this is for updating the subtext node when clicked outside instead of another node,
        // this way, subtext would be updated when getting the width for calculating appropriate left value
        let data = node.data
        if(data && subtextElement){
          const subtext = showPersonID ? (data.subtext) ? data.profile.id + '\n' + data.subtext.data.label : data.profile.id : (data.subtext) ? data.subtext.data.label : null;
          subtextElement.childNodes[1] ? subtextElement.childNodes[1].innerText = subtext : subtextElement.childNodes[0].innerText = subtext
        }
      }

      let subTextNodeWidth = $(`#domSubtext${node.data.profile.id}`).width()
      if(subtextElement){

        let left = (140 - subTextNodeWidth) * (1/2)
        subtextElement.style.left = `${left}px`
      }
    }

  }

  resizePedigree() {
    this.setState({
      pedigreeHeight: this.getPedigreeHeight(),
      pedigreeWidth: this.getPedigreeWidth()
    });
  }

  buildAncestryNodes(ancestry_data) {
    let linesForMaternal = 1;
    let linesForPaternal = 1;

    const customWordWrap = (txt, maxChar, side_of_family) => {
      var lines = [];
      var space = -1;
      function cut() {
        for (var i = 0; i < txt.length; i++) {
          (txt[i] == ' ') && (space = i);
          if (i >= maxChar) {
            (space == -1 || txt[i] == ' ') && (space = i);
            if (space > 0) { lines.push(txt.slice((txt[0] == ' ' ? 1 : 0), space)); }
            txt = txt.slice(txt[0] == ' ' ? (space + 1) : space);
            space = -1;
            break;
          }
        } check();
      }
      function check() { if (txt.length <= maxChar) { lines.push(txt[0] == ' ' ? txt.slice(1) : txt); txt = ''; } else if (txt.length) { cut(); } return; }
      check();
      if(side_of_family === 'maternal'){
        linesForMaternal = lines.length;
      }
      else{
        linesForPaternal = lines.length;
      }
      return lines.join('\n');
    };

    let maternal_ancestry_labels = [];
    let paternal_ancestry_labels = [];

    if (ancestry_data.maternal_ancestry && ancestry_data.maternal_ancestry != null) {
      ancestry_data.maternal_ancestry.map(item => maternal_ancestry_labels.push(item.label));
    }
    if (ancestry_data.paternal_ancestry && ancestry_data.paternal_ancestry != null) {
      ancestry_data.paternal_ancestry.map(item => paternal_ancestry_labels.push(item.label));
    }

    let maxCharMaternal = 40;
    let maxCharPaternal = 40;

    maternal_ancestry_labels = String(maternal_ancestry_labels).split(',').join(', ');
    paternal_ancestry_labels = String(paternal_ancestry_labels).split(',').join(', ');
    let negative_y = 0;
    if (maternal_ancestry_labels.length > 8 ||
        paternal_ancestry_labels.length > 8) {

        negative_y = -20;
    } else if (maternal_ancestry_labels.length > 6 ||
        paternal_ancestry_labels.length > 6) {

        negative_y = -10;
    }

    // maternal ancestry
    const maternal_ancestry_txt = customWordWrap(maternal_ancestry_labels.length !== 0 ? String(maternal_ancestry_labels) : "Unknown", maxCharMaternal, 'maternal');

    // paternal ancestry
    const paternal_ancestry_txt = customWordWrap(paternal_ancestry_labels.length !== 0 ? String(paternal_ancestry_labels) : "Unknown", maxCharPaternal, 'paternal');

    const ancestry_y_position = ancestry_data.center.y - 60;
    let m_left = ancestry_data.center.x + 160;
    let f_left = ancestry_data.center.x - 160;

    const cookies = this.getCookies();

    const ancestry_nodes = [
      {
        id: MOTHER_ANCESTRY_ID,
        type: ClientSideNodeTypes.ANCESTRY,
        position: { x: m_left, y: ancestry_y_position + negative_y},
        data: {label: maternal_ancestry_txt},
        hidden: cookies.displayAncestry !== 'true'
      },
      {
        id: FATHER_ANCESTRY_ID,
        type: ClientSideNodeTypes.ANCESTRY,
        position: {x: f_left, y: ancestry_y_position + negative_y},
        data: {label: paternal_ancestry_txt},
        hidden: cookies.displayAncestry !== 'true'
      }
    ];

    return ancestry_nodes;
  }

  buildDiseaseColorMap() {
    const diseases = Object.values(this.props.getPedigreeData().getAllDiseases());
    const diseaseMap = {};

    const allDiseasesOnPedigree = [];
    for(let x=0; x<diseases.length; x++){
      for(let i=0; i<diseases[x].length; i++){
        allDiseasesOnPedigree.push(diseases[x][i]);
      }
    }

    allDiseasesOnPedigree.sort((a, b) => a.disease_id - b.disease_id);

    const key = 'disease_id';
    const uniqueDiseases = [...new Map(allDiseasesOnPedigree.slice().reverse().map(item =>
    [item[key], item])).values()];

    let displayBlackAndWhite = Cookie.get(CookieKeys.PEDIGREE_BLACK_AND_WHITE);
    const displayBlackAndWhiteBool = displayBlackAndWhite === 'true';

    uniqueDiseases.sort((a, b) => a.id - b.id);
    for(let x=0; x<uniqueDiseases.length; x++) {
      if(displayBlackAndWhiteBool){
        diseaseMap[uniqueDiseases[x].disease_id] = this.getSVGImageNumber(uniqueDiseases[x].disease_id, uniqueDiseases);
      }
      else{
        diseaseMap[uniqueDiseases[x].disease_id] = this.getColor(uniqueDiseases[x].disease_id, uniqueDiseases, x);
      }
    }

    return diseaseMap;
  }

  getSVGImageNumber(digit, unique_diseases){

    let digitObject = unique_diseases.find(disease => disease.disease_id === digit);
    let index = unique_diseases.indexOf(digitObject);
    let place = index + 1
    let final = place % 15
    if(final === 0) final = 15
    return final

  }

  getColor(digit, unique_diseases, colorIndex) {
    let colorId = null;
    colorId = colorIndex % 200; // 200 different colors
    let firstTenDiseases = unique_diseases.slice(0, 10);
    let digitOnFirstTen = firstTenDiseases.find(disease => disease.disease_id === digit);

    if(digitOnFirstTen){
      let index = firstTenDiseases.indexOf(digitOnFirstTen);
      switch(index){
        case 0:
          return '#0066cc';
        case 1:
          return '#cc0000';
        case 2:
          return '#009933';
        case 3:
          return '#ffcc00';
        case 4:
          return '#990099';
        case 5:
          return '#ff6600';
        case 6:
          return '#33ccff';
        case 7:
          return '#ff6699';
        case 8:
          return '#00ff66';
        case 9:
          return '#cc99ff';
      }
    }
    else{
      let color = colors_255.find(item => item.colorId === colorId)
      // color id for White or color undefined
      if(typeof(color) === 'undefined') {
        return '#9370DB';
      }
      return color.hexString;
    }
  }

  consanguineousToolTipTrack(e) {
    let tooltip = document.querySelectorAll('#consanguineous-tooltip');
    for (var i = tooltip.length; i--;) {
      tooltip[i].style.visibility = 'visible';
      tooltip[i].style.left = e.pageX + 'px';
      tooltip[i].style.top = e.pageY + 'px';
    }
  }

  reAssignParentsToolTipTrack(e) {
    let tooltip = document.querySelectorAll('#re-assign-parents-tooltip');
    for (var i = tooltip.length; i--;) {
      tooltip[i].style.visibility = 'visible';
      tooltip[i].style.left = e.pageX + 'px';
      tooltip[i].style.top = e.pageY + 'px';
    }
  }

  setData(data) {
    this.setState(data);
  }

  // Note: react-flow only like string id's and source/target and also
  // does not like duplicate id's
  async getNodes(showAllPeople) {
    let partners_option = this.getPartnersOption();
    let is_emr_session = sessionStorage.getItem('is_emr_session');
    is_emr_session = is_emr_session == 'true';

    let people = Object.values(this.props.getPedigreeData().getAllProfiles());
    let all_manually_connected_relationships = [];

    for(let person of people){
      all_manually_connected_relationships = all_manually_connected_relationships.concat(person.relationship_ids);
    }

    all_manually_connected_relationships = all_manually_connected_relationships.filter(rel => rel.is_manually_connected)
    all_manually_connected_relationships = [...new Map(all_manually_connected_relationships.map(item => [item['id'], item])).values()];

    let all_manually_connected_relationships_copy = JSON.parse(JSON.stringify(all_manually_connected_relationships));
    let people_copy = Object.values(this.props.getPedigreeData().getAllProfiles());

    for(let manually_connected_relationship of all_manually_connected_relationships){
      let mother = people.find(person => person.id == manually_connected_relationship.mother_id);
      let father = people.find(person => person.id == manually_connected_relationship.father_id);

      if(mother.level != father.level){
        if(mother.level < father.level){
          this.addFakePartnerConsanguineous(father, mother);
        }
        else if(father.level < mother.level){
          this.addFakePartnerConsanguineous(mother, father);
        }
      }
    }

    const data = {
      proband: this.props.getPedigreeData().getProband(),
      profile: this.props.getPedigreeData().getAllProfiles(),
      displayGeneticTesting: Cookie.get(CookieKeys.PEDIGREE_GENETIC_TESTING),
      showNotes: Cookie.get(CookieKeys.PEDIGREE_NOTES),
      showName: Cookie.get(CookieKeys.PEDIGREE_VIEW_STATUS),
      showAllPeople: showAllPeople ? showAllPeople : null,
      displayProgenyArchiveData: Cookie.get(CookieKeys.PEDIGREE_PROGENY_ARCHIVE_DATA),
      progenyArchivedData: this.props.getPedigreeData().getProgenyArchiveData(),
      progenyArchivedPreferences: this.props.getPedigreeData().getProgenyArchivePreferences(),
      cloned_members: this.props.getPedigreeData().getClonedMembers(),
      linked_members: this.props.getPedigreeData().getLinkedMembers(),
      member_links: this.props.getPedigreeData().getMemberLinks(),
      partners_option,
      is_emr_session
    };
    const payload = buildSmartDrawPayload(data);
    // const payloadv2 = buildSmartDrawPayloadV2(data);
    let react_flow_layout = await familyApi.react_flow_pedigree_layout_dom(payload);
    // const react_flow_layout_v2 = await familyApi.react_flow_pedigree_layout_dom_v2(payloadv2);
    const disease_color_map = this.buildDiseaseColorMap();

    if (!react_flow_layout){
      set_cookie(CookieKeys.PARTNER_OPTION, PartnerOptions.SHOW_ALL_PARTNERS);
      react_flow_layout = await familyApi.react_flow_pedigree_layout_dom(payload);
    }

    for(let manually_connected_relationship of all_manually_connected_relationships_copy){
      let mother = this.props.getPedigreeData().getProfile(`apimem-${manually_connected_relationship.mother_id}`)
      let father = this.props.getPedigreeData().getProfile(`apimem-${manually_connected_relationship.father_id}`)

      mother.relationship_ids.push(manually_connected_relationship);
      father.relationship_ids.push(manually_connected_relationship);

      this.props.getPedigreeData().setProfile(mother.rkey, mother);
      this.props.getPedigreeData().setProfile(father.rkey, father);

      let children = people_copy.filter(person => (person.mother_id == mother.id && person.father_id == father.id) || (person.mother_id == father.id && person.father_id == mother.id))
      for(let child of children){
        child.mother_id = mother.id;
        child.father_id = father.id;

        this.props.getPedigreeData().setProfile(child.rkey, child);
      }
    }
    let people_with_fake_consanguineous_partners = Object.values(this.props.getPedigreeData().getAllProfiles());
    for(let person of people_with_fake_consanguineous_partners){
      let partners = [];
      if(String(person.id).startsWith('fake_consanguineous-partner-')){
        for(let relationship of person.relationship_ids){
          let partner = relationship.mother_id == person.id ? people.find(p => p.id == relationship.father_id) : people.find(p => p.id == relationship.mother_id);
          partners.push(partner);
        }
        this.deleteFromPedigreeData(person, partners);
      }
    }

    const parent_map =  react_flow_layout ?  react_flow_layout.filter(n => 'ParentMap' in n).pop() : []
    const parent_map_length = react_flow_layout ? parent_map["ParentMap"].length : parent_map.length

    const nodeParentMap = {};
    if (parent_map) {
      for(let x=0; x<parent_map_length; x++) {
        const person = parent_map["ParentMap"][x];
        const person_entry = Object.entries(person)[0];
        const key = person_entry[0];
        const val = person_entry[1];
        nodeParentMap[key] = val;
      }
    }

    const nodes = [];
    const subtextNodes = {};

    for(let i=0; i<react_flow_layout.length; i++) {
      // hack force id's to strings
      react_flow_layout[i].id += "";

      if (react_flow_layout[i].id === "-1") continue

	    react_flow_layout[i].data = Object.assign(
	      {},
	      react_flow_layout[i].data,
    		{
    		  datastore: this.datastore,
    		  nodeType: "^^" + react_flow_layout[i].nodeType,
    		  // updateNode:this.updateNode,
    		  id: react_flow_layout[i].id,
          getPedigreeData: this.props.getPedigreeData,
          readOnlyUser: this.props.read_only,
          navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
    		}
	    );


      // edges will have a source & target attribute
      if('source' in react_flow_layout[i] && 'target' in react_flow_layout[i]) {

        let multiple_birth_type = null;

        // check if edge is from a person connecting to a bottom (sibling connector) node when hiding partners
        // if there is, change the edgetype to PersonToBottom, so it would force the connection to the person to be at the bottom
        //
        if(typeof react_flow_layout[i].source == 'number' && String(react_flow_layout[i].target).startsWith('0xr') && react_flow_layout[i].edgeType == ApiEdgeTypes.TopToBottom){
          react_flow_layout[i].type = ApiEdgeTypes.PersonToBottom;
          react_flow_layout[i].edgeType = ApiEdgeTypes.PersonToBottom;
        }

        // check to see if this edge has a Person node connecting to it
        // and if that Person node has a twin. If so then set the edge type to Twin edge type
        if (react_flow_layout[i].edgeType === ApiEdgeTypes.BottomToChild) {
          // assume the 'target' should be the Person node in this edge

          // pull the profile from redux
          const person_profile = this.props.getPedigreeData().getProfile(`apimem-${react_flow_layout[i].target}`);
          if(person_profile){
            if (person_profile.twin_set && person_profile.twin_id) {
              react_flow_layout[i].type = ClientSideEdgeTypes.BottomToTwinChild;
              multiple_birth_type = person_profile.twin_type;
            } else {
              // set edge type
              react_flow_layout[i].type = react_flow_layout[i].edgeType;
            }
          }
        } else {
          // set edge type
          react_flow_layout[i].type = react_flow_layout[i].edgeType;
        }

        // set appropriate sources/targets for manually connected consanguineous relationships
        if(String(react_flow_layout[i].source).startsWith("fake_consanguineous-partner-")){
          let spouse = this.props.getPedigreeData().getProfile(`apimem-${react_flow_layout[i].source.replace("fake_consanguineous-partner-", "")}`);
          let relationship = all_manually_connected_relationships_copy.find(rel => rel.mother_id + '' == spouse.id + '' || rel.father_id + '' == spouse.id + '');
          let real_consanguineous_partner = people.find(person => (person.id + '' == relationship.mother_id + '' || person.id + '' == relationship.father_id + '') && person.id + '' != spouse.id + '');
          react_flow_layout[i].source = real_consanguineous_partner.id + '';
        }
        else if(String(react_flow_layout[i].target).startsWith("fake_consanguineous-partner-")){
          let spouse = this.props.getPedigreeData().getProfile(`apimem-${react_flow_layout[i].target.replace("fake_consanguineous-partner-", "")}`);
          let relationship = all_manually_connected_relationships_copy.find(rel => rel.mother_id + '' == spouse.id + '' || rel.father_id + '' == spouse.id + '');
          let real_consanguineous_partner = people.find(person => (person.id + '' == relationship.mother_id + '' || person.id + '' == relationship.father_id + '') && person.id + '' != spouse.id + '');
          react_flow_layout[i].target = real_consanguineous_partner.id + '';
        }


        // set edge style
        react_flow_layout[i].style = PedigreeStylesheet.edgeNode;

        // hack force id's to strings
        react_flow_layout[i].source += "";
        react_flow_layout[i].target += "";

        react_flow_layout[i].data = Object.assign(
  	      {},
  	      react_flow_layout[i].data,
      		{
            multiple_birth_type: multiple_birth_type
      		}
  	    );

        nodes.push(react_flow_layout[i]);

      } else if('ParentMap' in react_flow_layout[i]) {
        // if its the parent map node skip it, we have already processed it
        continue;
      } else {
        if (react_flow_layout[i].nodeType === ApiNodeTypes.SUBTEXT) {

          subtextNodes[react_flow_layout[i].id] = react_flow_layout[i];

        } else if (react_flow_layout[i].nodeType === ApiNodeTypes.TOP ||
                    react_flow_layout[i].nodeType === ApiNodeTypes.BOTTOM) {

          //this is for top connector nodes (partner nodes)
          if (react_flow_layout[i].nodeType === ApiNodeTypes.TOP) {

            let nodeClickCount = 0;
            if(sessionStorage.getItem('famgenix_last_selected_node') !== null && sessionStorage.getItem('famgenix_last_selected_node') !== undefined){
              if(JSON.parse(sessionStorage.getItem('famgenix_last_selected_node')) == react_flow_layout[i].id){
                nodeClickCount = 1;
              }
            }

            react_flow_layout[i].data.nodesSelectedCount = 0
            react_flow_layout[i].data.isPartnerConnectorNode = true
            react_flow_layout[i].data.isNodeDragging = false
            react_flow_layout[i].data.nodeClickCount = nodeClickCount
            react_flow_layout[i].data.reRenderPedigree = this.reRenderPedigree
          }

          react_flow_layout[i].style = PedigreeStylesheet.connectorNode;

          // hack to make sure the connector nodes lines up with the edge
          react_flow_layout[i].position.x = react_flow_layout[i].position.x - 2;
          react_flow_layout[i].position.y = react_flow_layout[i].position.y - 2;

          nodes.push(react_flow_layout[i]);

        } else if (react_flow_layout[i].nodeType === ApiNodeTypes.NORMAL) {

          // console.log("normal node type");
          // console.log(react_flow_layout[i]);

        } else if (react_flow_layout[i].nodeType === ApiNodeTypes.PERSON) {

          // hack force id's to strings
          const is_proband = (react_flow_layout[i].id === (this.props.getPedigreeData().getProband().id+"")) ? true : false;

          // hack to make sure the person node are centered vertically with the edge
          react_flow_layout[i].position.y = react_flow_layout[i].position.y - 5;

          // set node type
          react_flow_layout[i].type = ClientSideNodeTypes.PERSON;

          // pull the profile from redux
          const person_profile = this.props.getPedigreeData().getProfile(`apimem-${react_flow_layout[i].id}`);

          let nodeClickCount = 0;
          if(sessionStorage.getItem('famgenix_last_selected_node') !== null && sessionStorage.getItem('famgenix_last_selected_node') !== undefined){
            if(JSON.parse(sessionStorage.getItem('famgenix_last_selected_node')) == react_flow_layout[i].id){
              nodeClickCount = 1;
            }
          }

          react_flow_layout[i].data = Object.assign(
            {},
            react_flow_layout[i].data,
            {
              nodesSelectedCount: 0,
              nodeClickCount: nodeClickCount,
              isNodeDragging: false,
              is_proband: is_proband,
              proband_id: this.props.getPedigreeData().getProband().id+"",
              proband_mother_id: this.props.getPedigreeData().getProband().mother_id+"",
              proband_father_id: this.props.getPedigreeData().getProband().father_id+"",
              proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id+"",
              proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id+"",
              proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id+"",
              proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id+"",

              // perform a deep clone here so its not tied to the redux reference
              profile: cloneDeep(person_profile),
              disease_color_map: disease_color_map,

              onDeleteCheck: this.handleCheckDeleteSelectedPerson,
              onDeleteOpen: this.onClickOpenModalConfirmDelete,
              onAddParents: this.addParents,
              onAddChildren: this.addChildren,
              onAddPartner: this.addPartner,
              onAddSiblings: this.addSiblings,
              onCreatePartnerWtihChildren: this.addPartnerWithChildren,
            }
          );

          // never push fake consanguineous partners to the nodes array
          if(!react_flow_layout[i].id.startsWith('fake_consanguineous-partner-')){
            nodes.push(react_flow_layout[i]);
          }
        }
      }

    }

    // add subtext to data of nodes with subtext
    for(let z=0; z<nodes.length; z++) {
      if (nodes[z].id in nodeParentMap) {
        const subtext_key = nodeParentMap[nodes[z].id][0];
        if (subtext_key in subtextNodes) {
          nodes[z].data = Object.assign({}, nodes[z].data, {subtext: subtextNodes[subtext_key]});
        }
      }
    }

    /********************Build Ancestry********************/
    const person_nodes = nodes.filter(n => n.nodeType === ApiNodeTypes.PERSON);
    const proband = person_nodes.filter(n => n.data.is_proband).pop();
    let father = null;
    let mother = null;

    // hack force id's to strings
    if (proband) father = person_nodes.filter(n => n.id === proband.data.profile.father_id+"").pop();
    if (proband) mother = person_nodes.filter(n => n.id === proband.data.profile.mother_id+"").pop();

    let ancestry_data = null;
    if (mother && father) {
      let min_x = null;
      let max_x = null;
      let min_y = null;
      let max_y = null;

      for (let i=0; i<person_nodes.length; i++) {
        let x_position = person_nodes[i].position.x;
        let y_position = person_nodes[i].position.y;
        if (min_x === null) {
          min_x = x_position;
        } else {
          if (x_position < min_x) {
            min_x = x_position;
          }
        }

        if (max_x === null) {
          max_x = x_position;
        } else {
          if (x_position > max_x) {
            max_x = x_position;
          }
        }

        if (min_y === null) {
          min_y = y_position;
        } else {
          if (y_position < min_y) {
            min_y = y_position;
          }
        }

        if (max_y === null) {
          max_y = y_position;
        } else {
          if (y_position > max_y) {
            max_y = y_position;
          }
        }
      }

      // find center of the x's and have 300px of space between the ancestries
      const center = {x: (min_x + max_x) / 2, y: min_y};

      //mother details
      let maternal_ancestry = this.props.getPedigreeData().getProband().mother.ancestry;

      //father details
      let paternal_ancestry = this.props.getPedigreeData().getProband().father.ancestry;

      ancestry_data = {
        maternal_ancestry: maternal_ancestry,
        paternal_ancestry: paternal_ancestry,
        center: center
      };
    }
    /********************End Ancestry**********************/

    function getTwinSets(nodes) {
      let sets = {}
      // Arrage twin into sets
      for(var node of nodes) {
        if(node.twin_set === null) continue;

        if(node.twin_set in sets) {
          sets[node.twin_set].members.push(node)
        } else {
          sets[node.twin_set] = {members: [node]}
        }
      }

      return sets;
    }

    //getting center point of twins
    let peopleWithTwins = people.filter(person => person.twin_id)
    let twin_sets = getTwinSets(peopleWithTwins)
    twin_sets = Object.values(twin_sets)

    for(let set of twin_sets){
      let setOnNodeStore = nodes.filter((node) => set.members.find((twin) => node.id && twin.id && node.id == twin.id));
      /*
      ** Sometimes when adding new twins to a set there is no position object
      ** make sure to check and see
      */

      let left_most_pos = setOnNodeStore.reduce(function(prev, curr) {
        if (prev.x && curr.x) {
          return prev.x < curr.x ? prev : curr;
        } else if (prev.position && curr.x) {
          return prev.position.x < curr.x ? prev.position : curr;
        } else if (prev.x && curr.position) {
          return prev.x < curr.position.x ? prev : curr.position;
        } else {
          return prev.position.x < curr.position.x ? prev.position : curr.position;
        }
      });
      let right_most_pos = setOnNodeStore.reduce(function(prev, curr) {
        if (prev.x && curr.x) {
          return prev.x > curr.x ? prev : curr;
        } else if (prev.position && curr.x) {
          return prev.position.x > curr.x ? prev.position : curr;
        } else if (prev.x && curr.position) {
          return prev.x > curr.position.x ? prev : curr.position;
        } else {
          return prev.position.x > curr.position.x ? prev.position : curr.position;
        }
      });
      // left_most_pos.x += 40
      // right_most_pos.x += 40
      let center_point = getCenterPoint(left_most_pos, right_most_pos)
      let twins_connector_line = nodes.find(node => 'edgeType' in node && node.edgeType === 'BottomToChild' && node.target == set.members[0].id)
      let twins_connector_node = nodes.find(node => node.id == twins_connector_line.source)

      //create new connector node for the twin set
      let new_twins_connector_node = JSON.parse(JSON.stringify(twins_connector_node))
      new_twins_connector_node.position.x = center_point.x

      //TODO: going to want to change this back for future use
      // new_twins_connector_node.id = createUUID()

      //changed this to a specific id so it would be updated if there is a saved position for this
      new_twins_connector_node.id = `twin-connector-node-${set.members[0].twin_id}`

      new_twins_connector_node.data.id = new_twins_connector_node.id
      new_twins_connector_node.data.datastore = this.datastore

      let nodeClickCount = 0;
      if(sessionStorage.getItem('famgenix_last_selected_node') !== null && sessionStorage.getItem('famgenix_last_selected_node') !== undefined){
        if(JSON.parse(sessionStorage.getItem('famgenix_last_selected_node')) == new_twins_connector_node.id){
          nodeClickCount = 1;
        }
      }

      new_twins_connector_node.data.nodesSelectedCount = 0
      new_twins_connector_node.data.isTwinConnectorNode = true
      new_twins_connector_node.data.isNodeDragging = false
      new_twins_connector_node.data.nodeClickCount = nodeClickCount
      new_twins_connector_node.data.reRenderPedigree = this.reRenderPedigree
      new_twins_connector_node.data.getPedigreeData = this.props.getPedigreeData

      nodes.push(new_twins_connector_node);

      //connect each member of the set to the new connector node
      for(let member of set.members){
        let twin_line = nodes.find(node => 'edgeType' in node && node.edgeType === 'BottomToChild' && node.target == member.id)
        twin_line.source = new_twins_connector_node.id
      }

      //connect new connector node to the previous connector node
      let new_twins_connector_line = JSON.parse(JSON.stringify(twins_connector_line));

      //TODO: going to want to change this back for future use
      // new_twins_connector_line.id = createUUID()

      //changed this to a specific id so it would be updated if there is a saved position for this
      new_twins_connector_line.id = `twin-connector-line-${set.members[0].twin_id}`

      new_twins_connector_line.data.id = new_twins_connector_line.id;
      new_twins_connector_line.data.datastore = this.datastore;
      new_twins_connector_line.source = twins_connector_node.id;
      new_twins_connector_line.target = new_twins_connector_node.id;
      new_twins_connector_line.type = 'BottomToChild'
      nodes.push(new_twins_connector_line);
    }

    // for(let node of nodes){
    //   if(node.id === '0x1'){
    //     node.position.x += 50
    //   }
    // }

    let final_nodes = cloneDeep(nodes);

    // for(let manually_connected_relationship of all_manually_connected_relationships){
    //   let father_node = final_nodes.find(node => node.data && node.data.profile && node.data.profile.id == manually_connected_relationship.father_id);
    //   let mother_node = final_nodes.find(node => node.data && node.data.profile && node.data.profile.id == manually_connected_relationship.mother_id);
    //   let relationship_connector_node = final_nodes.find(node => node.data && 'isPartnerConnectorNode' in node.data && node.id == `0x${manually_connected_relationship.rkey}_t`);

    //   for(let node of final_nodes){
    //     if(father_node.data.profile.level != mother_node.data.profile.level){
    //       if(node.data && node.data.profile && node.data.profile.id == father_node.data.profile.id){
    //         node.position.x = node.position.x + 35;
    //       }
    //       else if(node.data && node.data.profile && node.data.profile.id == mother_node.data.profile.id){
    //         node.position.x = node.position.x + 35;
    //       }
    //       else if(node.data && 'isPartnerConnectorNode' in node.data && node.id == relationship_connector_node.id){
    //         node.position.x = node.position.x + 35;
    //       }
    //     }
    //   }
    // }

    if (ancestry_data) {
      final_nodes = final_nodes.concat( this.buildAncestryNodes(ancestry_data) );
    }

    return {
      nodes: final_nodes,
      nodeParentMap: nodeParentMap,
      nodeDiseaseColorMap: disease_color_map,
      showPedigree: true,
      pedigreeHeight: this.getPedigreeHeight(),
      pedigreeWidth: this.getPedigreeWidth()
    }
  }

  getPedigreeHeight() {
    return window.innerHeight;
  }

  getPedigreeWidth() {
    return window.innerWidth;
  }

  curatedZoom() {
    // 20% - 0.21     // 120% - 1.26
    // 30% - 0.32     // 130% - 1.36
    // 40% - 0.42     // 140% - 1.47
    // 50% - 0.53     // 150% - 1.57
    // 60% - 0.63     // 160% - 1.68
    // 70% - 0.74     // 170% - 1.78
    // 80% - 0.84     // 180% - 1.89
    // 90% - 0.95     // 190% - 1.99
    // 100% - 1.05    // 200% - 2.1
    // 110% - 1.15    // 210% - 2.2
    if (this.zoom >= 0.2 && this.zoom <= 0.26) {
      return 0.21;
    } else if (this.zoom > 0.26 && this.zoom <= 0.37) {
      return 0.32;
    } else if (this.zoom > 0.37 && this.zoom <= 0.47) {
      return 0.42;
    } else if (this.zoom > 0.47 && this.zoom <= 0.58) {
      return 0.53;
    } else if (this.zoom > 0.58 && this.zoom <= 0.68) {
      return 0.63;
    } else if (this.zoom > 0.68 && this.zoom <= 0.79) {
      return 0.74;
    } else if (this.zoom > 0.79 && this.zoom <= 0.89) {
      return 0.84;
    } else if (this.zoom > 0.89 && this.zoom <= 1.0) {
      return 0.95;
    } else if (this.zoom > 1.0 && this.zoom <= 1.1) {
      return 1.05;
    } else if (this.zoom > 1.1 && this.zoom <= 1.2) {
      return 1.15;
    } else if (this.zoom > 1.2 && this.zoom <= 1.31) {
      return 1.26;
    } else if (this.zoom > 1.31 && this.zoom <= 1.41) {
      return 1.36;
    } else if (this.zoom > 1.41 && this.zoom <= 1.52) {
      return 1.47;
    } else if (this.zoom > 1.52 && this.zoom <= 1.62) {
      return 1.57;
    } else if (this.zoom > 1.62 && this.zoom <= 1.73) {
      return 1.68;
    } else if (this.zoom > 1.73 && this.zoom <= 1.83) {
      return 1.78;
    } else if (this.zoom > 1.83 && this.zoom <= 1.94) {
      return 1.89;
    } else if (this.zoom > 1.94 && this.zoom <= 2.04) {
      return 1.99;
    } else if (this.zoom > 2.04 && this.zoom <= 2.15) {
      return 2.1;
    } else if (this.zoom > 2.15 && this.zoom <= 2.2) {
      return 2.2;
    }
  }

  nextZoom(zoom, direction) {
    switch(zoom) {
      case 0.21:
        if (direction === 'up') {
          return 0.32;
        } else {
          return 0.21;
        }
      case 0.32:
        if (direction === 'up') {
          return 0.42;
        } else {
          return 0.21;
        }
      case 0.42:
        if (direction === 'up') {
          return 0.53;
        } else {
          return 0.32;
        }
      case 0.53:
        if (direction === 'up') {
          return 0.63;
        } else {
          return 0.42;
        }
      case 0.63:
        if (direction === 'up') {
          return 0.74;
        } else {
          return 0.53;
        }
      case 0.74:
        if (direction === 'up') {
          return 0.84;
        } else {
          return 0.63;
        }
      case 0.84:
        if (direction === 'up') {
          return 0.95;
        } else {
          return 0.74;
        }
      case 0.95:
        if (direction === 'up') {
          return 1.05;
        } else {
          return 0.84;
        }
      case 1.05:
        if (direction === 'up') {
          return 1.15;
        } else {
          return 0.95;
        }
      case 1.15:
        if (direction === 'up') {
          return 1.26;
        } else {
          return 1.05;
        }
      case 1.26:
        if (direction === 'up') {
          return 1.36;
        } else {
          return 1.15;
        }
      case 1.36:
        if (direction === 'up') {
          return 1.47;
        } else {
          return 1.26;
        }
      case 1.47:
        if (direction === 'up') {
          return 1.57;
        } else {
          return 1.36;
        }
      case 1.57:
        if (direction === 'up') {
          return 1.68;
        } else {
          return 1.47;
        }
      case 1.68:
        if (direction === 'up') {
          return 1.78;
        } else {
          return 1.57;
        }
      case 1.78:
        if (direction === 'up') {
          return 1.89;
        } else {
          return 1.68;
        }
      case 1.89:
        if (direction === 'up') {
          return 1.99;
        } else {
          return 1.78;
        }
      case 1.99:
        if (direction === 'up') {
          return 2.1;
        } else {
          return 1.89;
        }
      case 2.1:
        if (direction === 'up') {
          return 2.2;
        } else {
          return 1.99;
        }
      case 2.2:
        if (direction === 'up') {
          return 2.2;
        } else {
          return 2.1;
        }
      default:
        return zoom;
    }
  }

  handleZoomInClick() {
    const z = this.curatedZoom();
    this.handleZoomTo(this.nextZoom(z, 'up'));
  }

  handleZoomOutClick() {
    const z = this.curatedZoom();
    this.handleZoomTo(this.nextZoom(z, 'down'));
  }

  handleActualSizeClick() {
    this.handleZoomTo(defaultViewport.zoom);
  }

  handleFitToScreenClick() {
    this.reactFlowInstance.fitView({ padding: 0.4 });
    this.updateZoomValue(null, {zoom: this.reactFlowInstance.getZoom()})
  }

  handleShrinkCheck(alwaysShrink) {
    if (alwaysShrink) {
      this.setCookie("famgenix_always_shrink", "1");
      this.handleFitToScreenClick();
    } else {
      this.setCookie("famgenix_always_shrink", "0");
      this.handleActualSizeClick();
    }
  }

  handleZoomTo(zoom_val) {
    this.reactFlowInstance.zoomTo(zoom_val);
    this.updateZoomValue(null, {zoom: zoom_val})
  }

  updateZoomValue(event, flowTransform) {
    this.zoom = flowTransform.zoom;
    if (this.zoomCallback) {
      this.zoomCallback(flowTransform.zoom);
    }
  }

  onLoadPedigreeToolbar(zoomCallback) {
    this.zoomCallback = zoomCallback;
  }

  async handleSaveImageClick() {

    let heightPadding = 50
    let widthPadding = 200
    let data = null
    const proband = this.props.getPedigreeData().getProband();
    let age = proband.age_string ? proband.age_string : proband.age ? proband.age : ''

    const cookies = this.getCookies();
    let is_identified = cookies.viewStatus === ViewStatusOptions.IDENTIFIED;

    let patientData = {
      firstName: proband.first_name,
      lastName: proband.last_name,
      id: proband.patient_id,
      dob: proband.dob,
      age: age,
      is_identified
    }

    const standardNodePosition = this.state.nodes.filter(n => n.nodeType === ApiNodeTypes.PERSON);
    const savedNodes = this.props.getPedigreeData().getSavedNodePositions()
    let nodePositions = (savedNodes && Array.isArray(savedNodes)) ? savedNodes.data.filter(n => n.nodeType === ApiNodeTypes.PERSON) : standardNodePosition

    let print_width = this.getPrintWidth(nodePositions)
    let max_height = this.getPrintHeight(nodePositions)
    let max_width = print_width['width']
    let negativeOffset = print_width['negativeOffset']
    let pedigree_svg = document.getElementById('react-flow-pedigree')


    let printHeight = max_height + heightPadding
    let printWidth = max_width + widthPadding
    let svgData = helper_dom_printing.createImageString(pedigree_svg, printWidth, printHeight, patientData, negativeOffset)
    let payload = {
      svgData: svgData
    }

    try {
      data = await familyApi.flux_image_generator(payload)
    } catch(err) {
      this.setState({ openPrintingErrorModal: true })
      return;
    }

    this.reRenderLegend();
    let blob = this.createBlob(data, 'image/png')
    if(blob.size < 50){
      this.setState({ openPrintingErrorModal: true })
      return;
    }
    const blubUrl = URL.createObjectURL(blob)
    var downloadLink = document.createElement("a");
    downloadLink.href = blubUrl;
    downloadLink.download = this.getFileName('image');
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }


  async prepareForPrint(singlePage, isMobilePrint){
    let payload = await this.handlePdfFromAPI(singlePage)
    await this.printPedigree(payload, isMobilePrint)

    let partners_option = Cookie.get(CookieKeys.PARTNER_OPTION)

    if(partners_option === PartnerOptions.HIDE_PARTNERS || partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS) {
      const collaspse = true;
      await this.reRenderPedigree(collaspse);
    } else{
      await this.reRenderPedigree();
    }

    this.reRenderLegend()
  }

 async headlessPrinting(singlePage){
      let data = await this.handlePdfFromAPI(singlePage)
      return data
  }

  removePrintHeader(){
    let pedigreeTimeStamp = document.getElementById('pedigreeTimeStamp')
    let patientName = document.getElementById('patientName')
    let patientID = document.getElementById('patientID')
    let divDOB = document.getElementById('divDOB')

    if(divDOB) divDOB.remove()
    if(patientID) patientID.remove();
    if(patientName) patientName.remove()
    if(pedigreeTimeStamp) pedigreeTimeStamp.remove()

  }

  async clearPrintPdf() {
    this.removePrintHeader()
  }

  getPrintHeight(peopleNodes){
    let height = 0
    let subtextPadding = 0
    peopleNodes.forEach(person => {
        let element = document.querySelector(`div[data-id="${person.id}"]`)
        if (element) subtextPadding = element.clientHeight

        let personHeight = subtextPadding + person.position.y
        if(personHeight > height) height = personHeight;
      }
    );
    return height;
  }

  getPrintWidth(peopleNodes){
    let rightEnd = 0
    let leftEnd = 0

    peopleNodes.forEach(person => {
      if(person.position.x > rightEnd) rightEnd = person.position.x;
      if(person.position.x < leftEnd) leftEnd = person.position.x
    });

    if(leftEnd < 0) leftEnd = (leftEnd * (-1)) + 25 //add some padding
    let width = rightEnd + leftEnd


    return {
      'width': width,
      'negativeOffset': leftEnd
    }
  }

  getNodePostion(currentNodes, standardNodes){
    let nodeTypes = ['bottom', 'Person']
    let finalNodePositions = []
    if(!currentNodes) return standardNodes.filter(n => (nodeTypes.includes(n.nodeType)))

    const savedNodes = currentNodes.data
    const hidePartners = currentNodes.data_hide_partners
    const showAffected = currentNodes.data_show_affected_partners
    finalNodePositions =  savedNodes.length > 0 ? savedNodes : hidePartners.length > 0 ? hidePartners : showAffected.length > 0 ? showAffected : standardNodes
    return finalNodePositions.filter(n => (nodeTypes.includes(n.nodeType)))
  }

  async handlePdfFromAPI(singlePage) {

    let heightPadding = 25
    let widthPadding = 200
    let minHeight = 700
    let minWidth = 1000
    // if single page is true we set the scale to fit on to one page else we set the scale to 1
    // create header in the top right corner to indicate which patient the pdf is for
    const savedNodes = this.props.getPedigreeData().getSavedNodePositions()
    const standardNodePosition = this.state.nodes

    let nodePositions = this.getNodePostion(savedNodes, standardNodePosition)
    let widthAdjusted =  this.getPrintWidth(nodePositions)

    let max_width = widthAdjusted['width']
    let negativeOffset = widthAdjusted['negativeOffset']


    // Future can calculate max height using lowest node
    let nodeHeight = this.getPrintHeight(nodePositions)
    let max_height = nodeHeight

    const proband = this.props.getPedigreeData().getProband();
    let age = proband.age_string ? proband.age_string : proband.age

    const cookies = this.getCookies();
    let is_identified = cookies.viewStatus === ViewStatusOptions.IDENTIFIED;

    let patientData = {
      firstName: proband.first_name,
      lastName: proband.last_name,
      id: proband.patient_id,
      dob: proband.dob,
      age: age,
      is_identified
    }

    let headerSVGString = helper_dom_printing.createPatientHeader(patientData)
    let footerSvg = helper_dom_printing.createPatientFooter()

   //create svg string from dom and pass to api end pt to generate pdf
  let pedigree_svg = document.getElementById('react-flow-pedigree')

  // determine scale based on zoom factor of pedigree and determine non scaled height and width of pedigree

  let printHeight = max_height + heightPadding
  let printWidth = max_width + widthPadding
  let svgString =  helper_dom_printing.createSVGPdf(pedigree_svg, printWidth, printHeight, negativeOffset)

  if (printHeight < minHeight) printHeight = minHeight
  if (printWidth < minWidth) printWidth = minWidth

  let printScale = singlePage ? 'FitToPage' : 'FitToOnePageTall'
  let printPayload ={
      height: printHeight,
      width: printWidth,
      scale: printScale,
      svgData: svgString,
      header: headerSVGString,
      footer: footerSvg['footerSVG'],
      footerHeight: footerSvg['footerHeight']
    }
    return printPayload

  }

  createBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

getFileName(pdfOrImage){
  let proband = this.props.getPedigreeData().getProband()
  let fileName = `${proband.last_name ? proband.last_name + '-' : ''}${proband.first_name ? proband.first_name + '-' : ''}${proband.patient_id ? proband.patient_id : ''}`
  if((proband.last_name || proband.first_name) && !proband.patient_id && fileName){
    fileName = fileName.slice(0, -1);
  }

  if(this.render_patient_pedigree) {
    return fileName
  }

  if(!fileName){
    if(pdfOrImage == 'pdf'){
      fileName = 'patient.pdf'
    }
    else{
      fileName = 'patient.png'
    }
  }
  else{
    if(pdfOrImage == 'pdf'){
      fileName = `${fileName}.pdf`
    }
    else{
      fileName = `${fileName}.png`
    }
  }
  return fileName
}

async printPedigree(printPayload, isMobilePrint){
  let data = null
    try {
    data = await familyApi.flux_pdf_generator(printPayload)
  } catch(err) {
    this.setState({ openPrintingErrorModal: true })
    return;
  }

  const blob = this.createBlob(data, 'application/pdf')
  if(blob.size < 50){
    this.setState({ openPrintingErrorModal: true })
    return;
  }
  const file_name = this.getFileName('pdf');
  if(isMobilePrint){
    window.ReactNativeWebView.postMessage(JSON.stringify({data: data, type: 'print-data', file_name: file_name}))
  }
  else{
    const blubUrl = URL.createObjectURL(blob)
    var downloadLink = document.createElement("a");
    downloadLink.href = blubUrl;
    downloadLink.download = file_name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}

  async handleToggleViewStatus(viewStatus) {
    this.setCookie(CookieKeys.PEDIGREE_VIEW_STATUS, viewStatus);

    // const data = await this.getNodes();

    // // call pedigree callback to render changes
    // if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
    //   this.pedigreeCallbacks.setElements(data.nodes);
    // }
    await this.reRenderPedigree()
  }

  async onSavePedigreeNotes(pedigreeNotes){
    try{
      const payload = {
        family_id: this.props.getPedigreeData().getProband().family_id,
        pedigreeNotes: pedigreeNotes
      }

      const data = await familyApi.save_family_pedigree_notes(payload);
      this.props.getPedigreeData().setPedigreeNotes(data.pedigree_notes);
      const cookies = this.getCookies();
      const legend = cookies.displayLegend === 'true';
      this.buildLegend(legend, true);

    } catch(error){
      console.log(error.message);
    }
  }

  async onClickLegendEye(disease_id, save) {
    try {
      let payload = {
        disease_id,
        organization_id: this.props.organization_id,
        family_id: this.props.getPedigreeData().getProband().family_id,
        clinician_id: this.props.clinician_id
      };

      if (save) {
        const saved_hidden_disease_color = await helper_family_api.save_hidden_disease_colors(payload);
        // add disease to hidden disease colors
        this.props.getPedigreeData().addHiddenDiseaseColor(saved_hidden_disease_color);
        this.reRenderLegend();
        await this.reRenderPedigree();
      } else {
        const removed_hidden_disease_color = await helper_family_api.remove_hidden_disease_colors(payload)
        // remove disease to hidden disease colors
        this.props.getPedigreeData().removeHiddenDiseaseColor(removed_hidden_disease_color);
        this.reRenderLegend();
        await this.reRenderPedigree();
      }
    } catch(error) {
      console.log(error);
    }
  }

  reRenderLegend() {
    const cookies = this.getCookies();
    const legend = cookies.displayLegend === 'true';
    const notes = cookies.displayPedigreeNotes === 'true';
    this.buildLegend(legend, notes);
  }

  editArchiveTableValue(key, valueName ,value){
    this.archiveTables[key][valueName]= value
  }
  setArchiveTableHeaders(table_key, tableHeaders){
    this.editArchiveTableValue(table_key, 'archiveTableHeaders', tableHeaders)
  }

  setArchiveTablePosition(table_key, position) {
    this.editArchiveTableValue(table_key, 'archiveTablePosition',  position)
  }

  setArchiveTableWidth(table_key, position) {
    let height = position['height']
    let width = position['width']
    this.editArchiveTableValue(table_key, 'archiveTableHeight', height)
    this.editArchiveTableValue(table_key, 'archiveTableWidth', width)
  }
  createArchiveTable(tableKey, member_uid){

    this.archiveTables[tableKey] = {
      'tableKey' : tableKey,
      'memberId' : member_uid,
      'showArchiveTable' : true,
      'member_uid' : member_uid,
      'archiveTableHeight': null,
      'archiveTableWidth': null,
      'archiveTablePosition': null,
      'archiveTableHeaders': null,
    }

    this.openArchiveIds.push(tableKey)

  }

  viewArchivedDataTable(table_key, member_uid) {
    if(!this.archiveTables.hasOwnProperty(table_key)){
      this.createArchiveTable(table_key, member_uid)
    }

    const progeny_archive_data = this.props.getPedigreeData().getProgenyArchiveData();

    let member_data = null;
    let table_data = null
    let table_name = null

    //If member id coming in is null, means the patient has no data for the table but we still generate the table with the previous headers
    if (progeny_archive_data
      && member_uid
      && "family" in progeny_archive_data
      && "data_dictionary" in progeny_archive_data){

      for (const [key, value] of Object.entries(progeny_archive_data["family"])) {
        if (value["member_uid"]+"" === member_uid+"") {
          member_data = value;
          break;
        }
    }

    table_data = member_data[table_key];

    // commenting this out to show null columns

    // let table_values = Object.values(table_data)
    // let table_keys = Object.keys(table_values[0])

    // let keys_to_delete = [];

    // for(let key of table_keys){
    //   let all_null = true;
    //   for(let value of table_values){
    //     if (key in value){
    //       if (value[key]){
    //         all_null = false;
    //       }
    //     }
    //   }
    //   if (all_null){
    //     keys_to_delete.push(key)
    //   }
    // }

    // for(let table_key in table_data){
    //   for(let key of keys_to_delete){
    //     delete table_data[table_key][key]
    //   }
    // }

    table_name = progeny_archive_data["data_dictionary"][table_key];
    // this.archiveTables[table_key].archiveTableTitle = table_name
    this.editArchiveTableValue(table_key, 'archiveTableTitle', table_name )
  }


    const parentNode = document.getElementById('react-flow-pedigree');
    let savedPosition = this.archiveTables[table_key].archiveTablePosition;
    let sideBarWidth = 400;
    let bottomHeight = 50;
    let offset = this.openArchiveIds.indexOf(table_key) > 0 ? this.openArchiveIds.indexOf(table_key) * -10 : 15

    const table = document.querySelector(`.${table_key}`)
    if (table) parentNode.removeChild(table);

    // side bar is 400 px so we want to place to the left of the sidebar by approx 15px
    //bottom of screen would be 0 --> so we raise up 50px
    const self = this;

    const options = {
      tableKey: table_key,
      parentNode: parentNode,
      bottom: bottomHeight + offset,
      right: sideBarWidth + offset,
      inset: savedPosition,
      savedHeight: this.archiveTables[table_key].archiveTableHeight,
      savedWidth: this.archiveTables[table_key].archiveTableWidth,
      height: 250,
      width: 650,
      tableName: this.archiveTables[table_key].archiveTableTitle,
      tableData: table_data,
      previousHeaders: this.archiveTables[table_key].archiveTableHeaders,
      progenyFieldName: progeny_archive_data["data_dictionary"],
      setTableHeaders: (headers) => {
        self.setArchiveTableHeaders(table_key, headers)
      },
      setTablePosition: (position) => {
        self.setArchiveTablePosition(table_key, position)
      },
      setTableWidth: (width) =>{
        self.setArchiveTableWidth(table_key, width)
      },
      onClose: (tableKey) => {
        this.removeArchiveTable(tableKey)
      }
    };
    const archiveTable = new ArchiveDataTableDom(options);
  }

  removeArchiveTable(table_key) {
    this.loadLegend = false;
    this.setState({ showArchiveTable: false, archiveDataTableKey: null})
    const parentNode = document.getElementById('react-flow-pedigree');
    // remove current Archive Table if its on the DOM tree already
    const table = document.querySelector(`.${table_key}`)
    if (table) parentNode.removeChild(table);

    const index = this.openArchiveIds.indexOf(table_key)
    if (index !== -1) {
      this.openArchiveIds.splice(index, 1);
    }

    delete this.archiveTables[table_key]
  }

  async buildLegend(showLegend, showPedigreeNotes) {
    const colorsForLegend = [];
    const parentNode = document.getElementById('react-flow-pedigree');
    if (showLegend || showPedigreeNotes) {

      // remove current DOM legend if its on the DOM tree already
      const legend_container = document.getElementById(constants.LEGEND_DOM_CONTAINER_ELEMENT);
      if (legend_container) parentNode.removeChild(legend_container);

      const top_banners_height = parentNode.offsetTop + 5;
      const extra_space = 15;

      let top = top_banners_height;
      let left = extra_space;

      let savedCenter = JSON.parse(localStorage.getItem("famgenix_legend_center"));
      if (savedCenter != null && savedCenter != undefined) {
        top = savedCenter[0];
        left = savedCenter[1];
      }

      const self = this;
      const options = {
        parentNode: parentNode,
        left: left,
        top: top,
        height: 140,
        width: 220,
        showDiseases: showLegend,
        showNotes: showPedigreeNotes,
        historyDiseases: Object.values(this.props.getPedigreeData().getAllDiseases()),
        hiddenDiseaseColors: this.props.getPedigreeData().getHiddenDiseaseColors(),
        legendColors: colorsForLegend,
        pedigreeNotes: this.props.getPedigreeData().getPedigreeNotes(),
        toggleDisease: (disease_id, visible) => {
          self.onClickLegendEye(disease_id, visible)
        },
        savePedigreeNotes: (pedigreeNotes) =>{
          self.onSavePedigreeNotes(pedigreeNotes)
        },
        onClose: () => {
          console.log("Closing Legend Callback");
        }
      };

      const legendDOM = new LegendDOM(options);
      if(!this.redrawOnLegend){
        await this.reRenderPedigree()
        this.redrawOnLegend = true
      }
    } else {
      // remove current DOM legend if its on the DOM tree already
      const legend_container = document.getElementById(constants.LEGEND_DOM_CONTAINER_ELEMENT);
      if (legend_container) parentNode.removeChild(legend_container);
    }
  }

  handleToggleLegend(showLegend) {
    this.setCookie(CookieKeys.PEDIGREE_LEGEND, showLegend);
    const cookies = this.getCookies();
    const notes = cookies.displayPedigreeNotes === 'true';
    this.buildLegend(showLegend, notes);
  }

  handleTogglePedigreeNotes(showPedigreeNotes) {
    if(this.render_patient_pedigree) {
      this.setCookie(CookieKeys.PEDIGREE_LEGEND_NOTES, false);
      return;
    }


    this.setCookie(CookieKeys.PEDIGREE_LEGEND_NOTES, showPedigreeNotes);
    const cookies = this.getCookies();
    const legend = cookies.displayLegend === 'true';
    this.buildLegend(legend, showPedigreeNotes);
  }

  handleToggleAncestry(showAncestry) {
    this.setCookie(CookieKeys.PEDIGREE_ANCESTRY, showAncestry);

    // call pedigree callback to render changes
    if (this.pedigreeCallbacks && this.pedigreeCallbacks.showAncestry) {
      this.pedigreeCallbacks.showAncestry(showAncestry);
    }
  }

  async handleToggleShowProgenyArchivedData(showProgenyArchivedData) {
    this.setCookie(CookieKeys.PEDIGREE_PROGENY_ARCHIVE_DATA, showProgenyArchivedData);

    await this.reRenderPedigree()
  }

  async handleToggleGeneticTesting(showGeneticTesting) {
    this.setCookie(CookieKeys.PEDIGREE_GENETIC_TESTING, showGeneticTesting);

    // const data = await this.getNodes();
    // // call pedigree callback to render changes
    // if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
    //   this.pedigreeCallbacks.setElements(data.nodes);
    // }
    await this.reRenderPedigree()
  }

  async handleToggleBlackAndWhite(showBlackAndWhite) {
    this.setCookie(CookieKeys.PEDIGREE_BLACK_AND_WHITE, showBlackAndWhite);

    // const data = await this.getNodes();
    // // call pedigree callback to render changes
    // if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
    //   this.pedigreeCallbacks.setElements(data.nodes);
    // }
    await this.reRenderPedigree()
    this.reRenderLegend();
  }

  async handleToggleNotes(showNotes) {
    this.setCookie(CookieKeys.PEDIGREE_NOTES, showNotes);

    // const data = await this.getNodes();

    // // call pedigree callback to render changes
    // if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
    //   this.pedigreeCallbacks.setElements(data.nodes);
    // }

    await this.reRenderPedigree();
  }

  async onNodeSelected(node, nodesSelectedCount) {

    let nodes = this.reactFlowInstance.getNodes();
    let selected_nodes = nodes.filter(node => 'selected' in node && node.selected)
    if (node.type === ClientSideNodeTypes.PERSON && (selected_nodes.length === 1 || this.props.read_only)) {
      let updated_profile = cloneDeep(this.props.getPedigreeData().getProfile(node.data.profile.rkey))
      node.data.profile = cloneDeep(updated_profile)
      this.setState({ selectedNode: node, showSideBar: true });
    }
    //get state to see if table is open
    if(this.openArchiveIds.length > 0 ) this.archiveDataTableCheck()
  }

  archiveDataTableCheck(){
    const tableKey = this.openArchiveIds;
    let found = false;
    tableKey.forEach(uniqueKey =>{
      if(this.props.getPedigreeData().getProgenyArchiveData() !== null){
        let progenyData = this.props.getPedigreeData().getProgenyArchiveData();
        let data = progenyData['family'];
        let selected_node_uid = this.state.selectedNode.data.profile ?this.state.selectedNode.data.profile.uuid_code: null;
        for (var key in data) {
          const obj = data[key];
          if(obj.member_uid+"" === selected_node_uid+"" && uniqueKey in obj) {
            found = true;
            this.viewArchivedDataTable(uniqueKey,  obj.member_uid);
            break;
          }
        }
      }
    })

    if(!found) tableKey.forEach(key=> this.viewArchivedDataTable(key, null))
  }

  onClearNodeSelection() {
    this.handleSideBarClose();
  }

  handleSideBarClose() {
    this.setState({ selectedNode: null, showSideBar: false });
  }

  async handleInfoAndHistoryUpdate(data) {
    let pedigree_data = await this.reRenderPedigree();
    return pedigree_data
  }

  async handleDiseaseUpdate(data) {
    try {
      const rkey = data.rkey;
      const new_disease = data.disease;

      /* UPDATE THE CENTRAL PEDIGREE DATA STORE */
      const profile = this.props.getPedigreeData().getProfile(rkey);
      const diseases = this.props.getPedigreeData().getDiseases(rkey);

      if (profile.age && Number(new_disease.age_diagnosed) > Number(profile.age)) {
        throw new Error('The age of diagnosis cannot be greater than this person’s current age/age of death.');
      }

      if (diseases) {
        var index = diseases.findIndex(disease => disease.rkey === new_disease.rkey);
        if (index > -1) {
          diseases[index] = new_disease;
        } else {
          diseases.push(new_disease);
        }

        profile.diseases = diseases;
        this.props.getPedigreeData().setProfile(rkey, profile);
        this.props.getPedigreeData().setDiseases(rkey, diseases);
      } else {
        const d = [new_disease];
        profile.diseases = d;
        this.props.getPedigreeData().setProfile(rkey, profile);
        this.props.getPedigreeData().setDiseases(rkey, d);
      }
      await this.reRenderPedigree();
      this.reRenderLegend();
      this.runRiskCriteria();
    }
    catch (e) {
      this.setState({ errorMessages: [e.message] });
    }
  }

  async handleGeneTestUpdate(data) {
    const rkey = data.rkey;
    const new_gene = data.gene;

    /* UPDATE THE CENTRAL PEDIGREE DATA STORE */
    const profile = this.props.getPedigreeData().getProfile(rkey);
    const genes = this.props.getPedigreeData().getGenes(rkey);

    if (genes) {
      var index = genes.findIndex(gene => gene.rkey === new_gene.rkey);
      if (index > -1) {
        genes[index] = new_gene;
      } else {
        genes.push(new_gene);
      }

      profile.genetic_testing = genes;
      this.props.getPedigreeData().setProfile(rkey, profile);
      this.props.getPedigreeData().setGenes(rkey, genes);
    } else {
      const g = [new_gene];
      profile.genetic_testing = g;
      this.props.getPedigreeData().setProfile(rkey, profile);
      this.props.getPedigreeData().setGenes(rkey, g);
    }
    await this.reRenderPedigree();
    this.runRiskCriteria();
  }

  async handleDiseaseDelete(data) {
    const rkey = data.rkey;
    const disease_rkey = data.disease_rkey;

    /* UPDATE THE CENTRAL PEDIGREE DATA STORE */
    // const profile = this.props.getPedigreeData().getProfile(rkey);
    // const diseases = profile.diseases.filter((d) => d.rkey !== disease_rkey);
    // profile.diseases = diseases;

    // this.props.getPedigreeData().deleteDisease(rkey, disease_rkey);
    let diseases = cloneDeep(this.props.getPedigreeData().getDiseases(rkey))
    let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(rkey))
    diseases = diseases.filter(disease => disease.rkey !== disease_rkey)
    profile_proband.diseases = diseases;
    this.props.getPedigreeData().setDiseases(rkey, diseases)
    this.props.getPedigreeData().setProfile(rkey, profile_proband)
    await this.reRenderPedigree();
    this.reRenderLegend();
    this.runRiskCriteria();
  }

  async handleGeneTestDelete(data) {
    const rkey = data.rkey;
    const gene_rkey = data.gene_rkey;

    /* UPDATE THE CENTRAL PEDIGREE DATA STORE */
    // const profile = this.props.getPedigreeData().getProfile(rkey);
    // const genes = profile.genetic_testing.filter((g) => g.rkey !== gene_rkey);
    // profile.genetic_testing = genes;

    // this.props.getPedigreeData().deleteGene(rkey, gene_rkey);

    let genes = cloneDeep(this.props.getPedigreeData().getGenes(rkey))
    let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(rkey))
    genes = genes.filter(gene => gene.rkey !== gene_rkey)
    profile_proband.genetic_testing = genes;
    this.props.getPedigreeData().setGenes(rkey, genes)
    this.props.getPedigreeData().setProfile(rkey, profile_proband)
    await this.reRenderPedigree();
    this.runRiskCriteria();
  }

  onClickOpenModalConfirmDelete(delete_msg=null) {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime(),
      delete_msg: delete_msg
    });
  }

  validateDeleteOrFail(node) {
    /* if node is not the:
    *  proband, probands parents or probands grandparents
    */
    const profile = node.data.profile;
    const proband = this.props.getPedigreeData().getProband();
    if (profile.id === proband.id
        || profile.id === proband.father.id
        || profile.id === proband.mother.id
        || profile.id === proband.paternal_grandfather.id
        || profile.id === proband.paternal_grandmother.id
        || profile.id === proband.maternal_grandfather.id
        || profile.id === proband.maternal_grandmother.id) {

      return false
    }
    return true
  }

  async handleCheckDeleteSelectedPerson() {

    const node = this.state.selectedNode;
    if (node) {
      const profile = node.data.profile;
      //handle checking of fake no children and infertility nodes if can delete
      if ((profile.is_no_children_node || profile.is_infertility_node) && profile.is_fake_node){
        let mother = this.props.getPedigreeData().getProfile(`apimem-${profile.mother_id}`)
        let father = this.props.getPedigreeData().getProfile(`apimem-${profile.father_id}`)
        if(mother.infertile || mother.no_children || father.infertile || father.no_children){
          return {msg: null, canDelete: true};
        }
      }

      try {

        const deletable_members_map = await familyApi.can_delete_member(profile.id);
        if (deletable_members_map.member_ids.length === 0 && deletable_members_map.message !== "") {
          const error = {
            message: deletable_members_map.message,
            type: ""
          };
          this.setState({ errorMessages: [error.message] });
          return {msg: null, canDelete: false};
        }

        if (deletable_members_map.member_ids.length === 2) {
          // hack to check if the two members are ancestors of proband (not full proof in the future)
          let member_one = this.props.getPedigreeData().getProfile(`apimem-${deletable_members_map.member_ids[0]}`);
          let member_two = this.props.getPedigreeData().getProfile(`apimem-${deletable_members_map.member_ids[1]}`);

          if ( !("father_id" in member_one) && !("mother_id" in member_one) && !("father_id" in member_two) && !("mother_id" in member_two) ) {
            return {msg: null, canDelete: true};
          } else {
            return {msg: "This family member has partners and/or children. Do you want to delete this family member, their partners and children?", canDelete: true};
          }

        } else if (deletable_members_map.member_ids.length > 1) {
          return {msg: "This family member has partners and/or children. Do you want to delete this family member, their partners and children?", canDelete: true};
        } else {
          return {msg: null, canDelete: true};
        }

      } catch (error) {
        this.setState({ errorMessages: [error.message] });
        return {msg: null, canDelete: false};
      }

      // const can_delete = this.validateDeleteOrFail(node);
      // if (can_delete) {
      //   try {
      //     const profile = node.data.profile;
      //     //handle checking of fake no children and infertility nodes if can delete
      //     if ((profile.is_no_children_node || profile.is_infertility_node) && profile.is_fake_node){
      //       let mother = this.props.getPedigreeData().getProfile(`apimem-${profile.mother_id}`)
      //       let father = this.props.getPedigreeData().getProfile(`apimem-${profile.father_id}`)
      //       if(mother.infertile || mother.no_children || father.infertile || father.no_children){
      //         return true;
      //       }
      //     }
      //
      //     // TODO: switch this over to use the central store instead of hitting API
      //
      //     const partners = await familyApi.get_member_memberid_partners(profile.id);
      //     if (Array.isArray(partners) && partners.length > 0) {
      //       const children = await familyApi.get_members_memberid_children(profile.id, partners[0].id);
      //       if (!profile.mother_id && !profile.father_id && profile.is_blood_related_to_proband) {
      //
      //         let consanguineous = profile.partners.length > 0 ? profile.partners[0].is_blood_related_to_proband : false
      //         if(consanguineous && children.length === 0){
      //           return true;
      //         }
      //         // top level member above grandparent
      //         // delete top level parents
      //         if (children.length === 0) {
      //           return false;
      //         }
      //
      //       } else {
      //
      //         //check first for partners before children
      //         if (partners.length > 0 && profile.is_blood_related_to_proband) {
      //           throw new Error('This family member cannot be deleted because they have partner. Please delete the partner first.')
      //         }
      //
      //         if (children.length > 0 || profile.is_blood_related_to_proband) {
      //           throw new Error('This family member cannot be deleted because they have children. Please delete the children first.')
      //         }
      //
      //         // otherwise we assume either there is children or the blood related
      //         // family member still has partners that need to be deleted
      //
      //       }
      //     }
      //     return true;
      //
      //   } catch (error) {
      //     if(error.message == 'Cannot delete because this person has 2 or more children.'){
      //       error.type = 'toplevel'
      //     }
      //     else if(error.message == 'This family member cannot be deleted because they have children. Please delete the children first.'){
      //       error.type = 'children'
      //     }
      //     console.log(error)
      //     this.setState({ errorMessages: [error.message] });
      //     // this.setState({ openCannotDeleteModal: [error.type, true] });
      //     return false;
      //   }
      // }

    }

    return false;
  }

  async handleDeleteSelectedPerson() {

    const node = this.state.selectedNode;
    if (node) {

      const profile = node.data.profile;

      //handle deleting of fake nodes
      if ((profile.is_no_children_node || profile.is_infertility_node) && profile.is_fake_node){
        let mother = this.props.getPedigreeData().getProfile(`apimem-${profile.mother_id}`)
        let father = this.props.getPedigreeData().getProfile(`apimem-${profile.father_id}`)
        let people = Object.values(this.props.getPedigreeData().getAllProfiles());
        // delete the fake child node, but not the partner that is not a fake node,
        // and is a record on the database
        if((mother.infertile || mother.no_children) && !mother.is_fake_node){
          this.deleteFromPedigreeData(profile, []);
          // make sure to delete all is_no_children_nodes and is_infertility_nodes that has that mother too
          let peopleToDelete = people.filter(person => (person.is_no_children_node || person.is_infertility_node) && person.mother_id == mother.id || person.father_id == mother.id)
          for(let p of peopleToDelete){
            this.deleteFromPedigreeData(p, []);
          }
          await this.updateInfertileNoChildrenValue(mother, mother.infertile ? 'infertility' : 'no_children', false)
        }
        else if((father.infertile || father.no_children) && !father.is_fake_node){
          this.deleteFromPedigreeData(profile, []);
          // make sure to delete all is_no_children_nodes and is_infertility_nodes that has that father too
          let peopleToDelete = people.filter(person => (person.is_no_children_node || person.is_infertility_node) && person.father_id == father.id || person.mother_id == father.id)
          for(let p of peopleToDelete){
            this.deleteFromPedigreeData(p, []);
          }
          await this.updateInfertileNoChildrenValue(father, father.infertile ? 'infertility' : 'no_children', false)
        }

        // when deleting a fake child node, also
        // remove the mother from the pedigree data store if mother is fake node
        if(mother.is_fake_node){
          this.deleteFromPedigreeData(mother, [father]);
          this.deleteFakePartnerAndChild(father)
        }

        // remove the father from the pedigree data store if father is fake node
        if(father.is_fake_node){
          this.deleteFromPedigreeData(father, [mother]);
          this.deleteFakePartnerAndChild(mother)
        }

        if (this.state.showSideBar) {
          this.handleSideBarClose();
        }

        this.setState({
          openModalConfirmDelete: false,
          errorMessages: []
        });

        await this.addFakePartnersAndChildren();
        await this.reRenderPedigree();
        return;
      }

      try {

        const deletable_members_map = await familyApi.can_delete_member(profile.id);
        if (deletable_members_map.member_ids.length === 0 && deletable_members_map.message !== "") {
          const error = {
            message: deletable_members_map.message,
            type: ""
          };
          console.log(error)
          this.setState({ errorMessages: [error.message] });
        }

        // make a map of the delete ids
        const deletable_member_ids_map = {}
        for (let z=0; z<deletable_members_map.member_ids.length; z++) {
          deletable_member_ids_map[deletable_members_map.member_ids[z]] = deletable_members_map.member_ids[z];
        }


        // this is a holdover from the old delete, kept it for now
        const partners = await familyApi.get_member_memberid_partners(profile.id);

        let partner_is_only_one_and_partner_is_fake_node = false;

        if(partners.length === 1){
          let people = Object.values(this.props.getPedigreeData().getAllProfiles());
          let one_partner = people.find(person => person.id == partners[0].id)
          if(one_partner.is_fake_node){
            partner_is_only_one_and_partner_is_fake_node = true;
          }
        }

        if((profile.infertile || profile.no_children) && partner_is_only_one_and_partner_is_fake_node){
          await this.deleteFakePartnerAndChild(profile);
        }

        // loop through and see if any of the members to be deleted are marked as no_children or infertile and delete the fake nodes
        for (let z=0; z<deletable_members_map.member_ids.length; z++) {
          const people = Object.values(this.props.getPedigreeData().getAllProfiles());
          const marked_for_deletion = this.props.getPedigreeData().getProfile(`apimem-${deletable_members_map.member_ids[z]}`);
          if ((marked_for_deletion.infertile || marked_for_deletion.no_children) && !marked_for_deletion.is_fake_node) {
            let peopleToDelete = people.filter((person) => {
              return (person.is_no_children_node || person.is_infertility_node) &&
              (person.mother_id == marked_for_deletion.id || person.father_id == marked_for_deletion.id)
            });
            for(let p of peopleToDelete){
              this.deleteFromPedigreeData(p, []);
            }
          }
        }

        // loop through and get any children of the memebrs to be deleted and set their mother_id and father_id to null
        let temp_profiles = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
        for (let z=0; z<temp_profiles.length; z++) {
          let temp_profile = temp_profiles[z];
          if (temp_profile.mother_id && temp_profile.father_id) {
            if ( (temp_profile.mother_id in deletable_member_ids_map) ||
                (temp_profile.father_id in deletable_member_ids_map)) {
                  temp_profile.mother_id = null;
                  temp_profile.mother_id_id = null;
                  temp_profile.father_id = null;
                  temp_profile.father_id_id = null;
                  this.props.getPedigreeData().setProfile(`apimem-${temp_profile.id}`, temp_profile);
                }
          }
        }

        // loop through and remove the person from their partners partner array and relationship_ids array
        // relationship_data should also be reset if it matches the id
        temp_profiles = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
        for (let z=0; z<temp_profiles.length; z++) {
          let changed = false;
          let temp_profile = temp_profiles[z];
          let partners = temp_profile.partners;
          let relationship_ids = temp_profile.relationship_ids;

          if (Array.isArray(partners)) {
            const new_partners = [];
            for (let l=0; l<partners.length; l++) {
              if (partners[l].id in deletable_member_ids_map) {
                continue;
              } else {
                new_partners.push(partners[l]);
              }
            }

            if (new_partners.length !== partners.length) {
              temp_profile.partners = new_partners;
              changed = true;
            }
          }

          if (Array.isArray(relationship_ids)) {
            const new_relationships = [];
            for (let l=0; l<relationship_ids.length; l++) {
              if (relationship_ids[l].mother_id in deletable_member_ids_map || relationship_ids[l].father_id in deletable_member_ids_map) {
                continue;
              } else {
                new_relationships.push(relationship_ids[l]);
              }
            }

            if (new_relationships.length !== relationship_ids.length) {
              temp_profile.relationship_ids = new_relationships;
              changed = true;
            }
          }

          if (temp_profile.relationship_data &&
            (temp_profile.relationship_data.mother_id in deletable_member_ids_map || temp_profile.relationship_data.father_id in deletable_member_ids_map)) {
              delete temp_profile.relationship_data;
          }

          if (changed) {
            this.props.getPedigreeData().setProfile(`apimem-${temp_profile.id}`, temp_profile);
          }
        }

        // delete all member ids from central partners array
        for (let z=0; z<deletable_members_map.member_ids.length; z++) {
          const key = `apimem-${deletable_members_map.member_ids[z]}`;
          if (key in this.props.getPedigreeData().getAllPartners()) {
            this.props.getPedigreeData().deletePartnersByKey(key);
          }
        }

        // delete all member ids from data
        for (let z=0; z<deletable_members_map.member_ids.length; z++) {
          let p = this.props.getPedigreeData().getProfile(`apimem-${deletable_members_map.member_ids[z]}`);
          this.deleteFromPedigreeData(p, []);
        }

        // hit bulk delete endpoint
        await familyApi.bulk_delete_members(profile.id, deletable_members_map.member_ids);

        if (profile.twin_id_id) {
          let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
          const twins = [];
          for (let i=0; i<people.length; i++) {
            if (people[i].twin_id_id === profile.twin_id_id) twins.push(people[i]);
          }

          if (twins.length === 1) {
            // delete the twin entry and clear twin_id and twin_set
            // if there is only one twin left after deletion

            /* Note: deletion of twin record is done within member deletion if
            ** only one twin is left, no need to manually do it
            */
            // await this.deleteTwinsEntry(profile);

            twins[0].twin_id_id = null;
            twins[0].twin_id = null;
            twins[0].twin_set = null;
            twins[0].twin_type = null;
            this.props.getPedigreeData().setProfile(twins[0].rkey, twins[0]);
          }
        }

        if (this.state.showSideBar) {
          this.handleSideBarClose();
        }

        this.setState({
          openModalConfirmDelete: false,
          errorMessages: []
        });

        await this.addFakePartnersAndChildren();
        await this.reRenderPedigree();

      } catch (error) {
        console.log(error);
        this.setState({ errorMessages: [error.message] });
      }

      //////////////////////////////////////////////////////////////////////////////////////////////

      // const can_delete = this.validateDeleteOrFail(node);
      // if (can_delete) {
      //   try {
      //     const profile = node.data.profile;
      //
      //     //handle deleting of fake nodes
      //     if ((profile.is_no_children_node || profile.is_infertility_node) && profile.is_fake_node){
      //       let mother = this.props.getPedigreeData().getProfile(`apimem-${profile.mother_id}`)
      //       let father = this.props.getPedigreeData().getProfile(`apimem-${profile.father_id}`)
      //       let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
      //       // delete the fake child node, but not the partner that is not a fake node,
      //       // and is a record on the database
      //       if((mother.infertile || mother.no_children) && !mother.is_fake_node){
      //         this.deleteFromPedigreeData(profile, []);
      //         // make sure to delete all is_no_children_nodes and is_infertility_nodes that has that mother too
      //         let peopleToDelete = people.filter(person => (person.is_no_children_node || person.is_infertility_node) && person.mother_id == mother.id || person.father_id == mother.id)
      //         for(let p of peopleToDelete){
      //           this.deleteFromPedigreeData(p, []);
      //         }
      //         await this.updateInfertileNoChildrenValue(mother, mother.infertile ? 'infertility' : 'no_children', false)
      //       }
      //       else if((father.infertile || father.no_children) && !father.is_fake_node){
      //         this.deleteFromPedigreeData(profile, []);
      //         // make sure to delete all is_no_children_nodes and is_infertility_nodes that has that father too
      //         let peopleToDelete = people.filter(person => (person.is_no_children_node || person.is_infertility_node) && person.father_id == father.id || person.mother_id == father.id)
      //         for(let p of peopleToDelete){
      //           this.deleteFromPedigreeData(p, []);
      //         }
      //         await this.updateInfertileNoChildrenValue(father, father.infertile ? 'infertility' : 'no_children', false)
      //       }
      //
      //       // when deleting a fake child node, also
      //       // remove the mother from the pedigree data store if mother is fake node
      //       if(mother.is_fake_node){
      //         this.deleteFromPedigreeData(mother, [father]);
      //         this.deleteFakePartnerAndChild(father)
      //       }
      //
      //       // remove the father from the pedigree data store if father is fake node
      //       if(father.is_fake_node){
      //         this.deleteFromPedigreeData(father, [mother]);
      //         this.deleteFakePartnerAndChild(mother)
      //       }
      //
      //       if (this.state.showSideBar) {
      //         this.handleSideBarClose();
      //       }
      //
      //       this.setState({
      //         openModalConfirmDelete: false,
      //         errorMessages: []
      //       });
      //
      //       await this.addFakePartnersAndChildren();
      //       await this.reRenderPedigree();
      //       return;
      //     }
      //
      //     const partners = await familyApi.get_member_memberid_partners(profile.id);
      //
      //     let partner_is_only_one_and_partner_is_fake_node = false;
      //
      //     if(partners.length === 1){
      //       let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
      //       let one_partner = people.find(person => person.id == partners[0].id)
      //       if(one_partner.is_fake_node){
      //         partner_is_only_one_and_partner_is_fake_node = true;
      //       }
      //     }
      //
      //     if((profile.infertile || profile.no_children) && partner_is_only_one_and_partner_is_fake_node){
      //       await this.deleteFakePartnerAndChild(profile);
      //     }
      //
      //     if (Array.isArray(partners) && partners.length > 0) {
      //       const children = await familyApi.get_members_memberid_children(profile.id, partners[0].id);
      //       if (!profile.mother_id && !profile.father_id && profile.is_blood_related_to_proband) {
      //
      //         let consanguineous = profile.partners.length > 0 ? profile.partners[0].is_blood_related_to_proband : false
      //         if(consanguineous && children.length === 0){
      //           await familyApi.delete_member_memberid(profile.id);
      //           this.deleteFromPedigreeData(profile, partners);
      //         }
      //
      //         // top level member above grandparent
      //         // delete top level parents
      //         if (children.length > 0) {
      //           await familyApi.delete_parents_top_level(children[0].id);
      //
      //           // remove father_id and mother_id from children
      //           for (let m=0; m<children.length; m++) {
      //             const child_profile = this.props.getPedigreeData().getProfile(`apimem-${children[m].id}`);
      //             child_profile.father_id = null;
      //             child_profile.father_id_id = null;
      //             child_profile.mother_id = null;
      //             child_profile.mother_id_id = null;
      //             this.props.getPedigreeData().setProfile(`apimem-${children[m].id}`, child_profile);
      //           }
      //
      //           // delete the two top level parents from central store
      //           this.deleteFromPedigreeData(profile, []);
      //
      //           partners[0].rkey = `apimem-${partners[0].id}`;
      //           this.deleteFromPedigreeData(partners[0], []);
      //         }
      //
      //       } else {
      //
      //         if (children.length === 0 && !profile.is_blood_related_to_proband) {
      //           await familyApi.delete_member_memberid(profile.id);
      //           this.deleteFromPedigreeData(profile, partners);
      //           await this.deleteFakePartnerAndChild(profile)
      //           await this.addFakePartnersAndChildren();
      //         }
      //
      //       }
      //     } else {
      //       await familyApi.delete_member_memberid(profile.id);
      //       this.deleteFromPedigreeData(profile, partners);
      //     }
      //
      //     if (profile.twin_id_id) {
      //       let people = Object.values(this.props.getPedigreeData().getAllProfiles());
      //       const twins = [];
      //       for (let i=0; i<people.length; i++) {
      //         if (people[i].twin_id_id === profile.twin_id_id) twins.push(people[i]);
      //       }
      //
      //       if (twins.length === 1) {
      //         // delete the twin entry and clear twin_id and twin_set
      //         // if there is only one twin left after deletion
      //
      //         /* Note: deletion of twin record is done within member deletion if
      //         ** only one twin is left, no need to manually do it
      //         */
      //         // await this.deleteTwinsEntry(profile);
      //
      //         twins[0].twin_id_id = null;
      //         twins[0].twin_id = null;
      //         twins[0].twin_set = null;
      //         twins[0].twin_type = null;
      //         this.props.getPedigreeData().setProfile(twins[0].rkey, twins[0]);
      //       }
      //     }
      //
      //     if (this.state.showSideBar) {
      //       this.handleSideBarClose();
      //     }
      //
      //     this.setState({
      //       openModalConfirmDelete: false,
      //       errorMessages: []
      //     });
      //
      //     // we don't have to redraw when deleting anymore because we already have a unique and constant id for the connector nodes
      //     // await this.saveLayoutData({})
      //     // await this.props.getPedigreeData().setSavedNodePositions('');
      //
      //     await this.reRenderPedigree();
      //
      //   } catch (error) {
      //     console.log(error)
      //     this.setState({ errorMessages: [error.message] });
      //   }
      // }

    }
  }

  deleteFromPedigreeData(profile, partners) {
    // delete the profile from central data along with other links to the deleted member
    // delete relationship_data, relationship_ids and partners reference from other profiles
    // disease, genes
    this.props.getPedigreeData().deleteProfile(profile.rkey);
    this.props.getPedigreeData().deleteDiseases(profile.rkey);
    this.props.getPedigreeData().deleteGenes(profile.rkey);

    if (partners.length === 1) {
      const p = this.props.getPedigreeData().getProfile(`apimem-${partners[0].id}`);
      if (p) {
        if (p.relationship_data) {
          p.relationship_data = null;
        }

        if (p.relationship_ids) {
          p.relationship_ids = p.relationship_ids.filter((r) => {
            if (r.mother_id_id !== profile.id && r.father_id_id !== profile.id) {
              return r;
            }
            return undefined;
          });

          p.relationship_ids = p.relationship_ids.filter(r => r != undefined);
        }

        if (p.partners) {
          p.partners = p.partners.filter((partner) => {
            if (partner.id !== profile.id) {
              return partner;
            }
            return undefined;
          });

          p.partners = p.partners.filter(partner => partner != undefined);
        }

        this.props.getPedigreeData().setProfile(p.rkey, p);
      }
    }
  }

  async deleteTwinsEntry(profile) {
    await familyApi.delete_twin_set(profile.twin_id_id);
  }

  getDeleteMsg() {
    let msg = null;
    const node = this.state.selectedNode;
    if (node && node.data && node.data.profile) {
      if (node.data.profile.is_no_children_node) {
        msg = "Are you sure you want to remove No Children?";
      } else if (node.data.profile.is_infertility_node) {
        msg = "Are you sure you want to remove Infertility?";
      }
    }

    if (this.state.delete_msg) {
      msg = this.state.delete_msg;
    }

    return msg;
  }

  isNoChildrenOrInfertility() {
    const node = this.state.selectedNode;
    if (node && node.data && node.data.profile) {
      if (node.data.profile.is_no_children_node
          || node.data.profile.is_infertility_node) {

        return true;
      }
    }

    return false;
  }

  getChildGender(value) {
    switch (value) {
      case actions.SON:
      case actions.MALE_FETAS:
        return "m";
      case actions.DAUGHTER:
      case actions.FEMALE_FETAS:
        return "f";
      case actions.UNKNOWN:
      case actions.PREGNANCY_UNKNOWN:
      case actions.SAB:
        return null;
    }

    return null;
  }

  async addParents(profile) {
    try {

      // Save to API
      const parents = await helper_family_api.create_parents(this.props.getPedigreeData().getProband().id+"", profile.id)

      //Id
      let mother = parents.mother;
      let father = parents.father;
      let relationship = parents.relationship;
      mother.rkey = `apimem-${mother.id}`;
      father.rkey = `apimem-${father.id}`;

      // set extra attributes expected to be in relationship data
      relationship.father_id_id = relationship.father_id;
      relationship.mother_id_id = relationship.mother_id;
      relationship.rkey = `r-${relationship.id}`;

      // set extra attributes expected to be in member profile
      mother.diseases = [];
      mother.genetic_testing = [];
      mother.family_id_id = mother.family_id;
      mother.mother_id = null;
      mother.father_id = null;
      mother.partners = [father];
      mother.relationship_ids = [relationship];
      mother.twin_id_id = mother.twin_id;
      mother.relationship_data = relationship;

      // set extra attributes expected to be in member profile
      father.diseases = [];
      father.genetic_testing = [];
      father.family_id_id = father.family_id;
      father.mother_id = null;
      father.father_id = null;
      father.partners = [mother];
      father.relationship_ids = [relationship];
      father.twin_id_id = father.twin_id;

      this.props.getPedigreeData().setProfile(father.rkey, father);
      this.props.getPedigreeData().setProfile(mother.rkey, mother);

      const p = this.props.getPedigreeData().getProfile(profile.rkey);
      p.father_id = father.id;
      p.father_id_id = father.id;
      p.mother_id = mother.id;
      p.mother_id_id = mother.id;
      this.props.getPedigreeData().setProfile(p.rkey, p);

      let saved_data = this.getActiveSavedNodePositions();

      if (!isEmpty(saved_data)) {
        // add the new parent nodes, connector nodes and edges to react-flow
        let nodes = cloneDeep(this.reactFlowInstance.getNodes()).concat(cloneDeep(this.reactFlowInstance.getEdges()));
        const profile_node = nodes.find((n) => n.id + "" === profile.id + "");

        // set the father_id and mother_id of the profile having parents created
        profile_node.data.profile.father_id = father.id;
        profile_node.data.profile.father_id_id = father.id;
        profile_node.data.profile.mother_id = mother.id;
        profile_node.data.profile.mother_id_id = mother.id;

        // profile_node.position.x += 40

        let connector_node_position = { x: profile_node.position.x + 19, y: profile_node.position.y - 67 };
        let child_connector_node_position = { x: profile_node.position.x + 19, y: profile_node.position.y - 35 };
        let father_node_position = { x: profile_node.position.x - 70, y: profile_node.position.y - 85 };
        let mother_node_position = { x: profile_node.position.x + 70, y: profile_node.position.y - 85 };

        //need specific ids for the connector nodes

        const father_node = {
          id: father.id + "",
          type: ClientSideNodeTypes.PERSON,
          nodeType: ApiNodeTypes.PERSON,
          position: father_node_position,
          data: {
            label: father.id,

            gender: "male",

            datastore: this.datastore,
            nodeType: "^^" + ApiNodeTypes.PERSON,
            id: father.id + "",

            nodesSelectedCount: 0,
            isNodeDragging: false,
            is_proband: false,
            proband_id: this.props.getPedigreeData().getProband().id + "",
            proband_mother_id: this.props.getPedigreeData().getProband().mother_id + "",
            proband_father_id: this.props.getPedigreeData().getProband().father_id + "",
            proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id + "",
            proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id + "",
            proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id + "",
            proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id + "",

            // perform a deep clone here so its not tied to the redux reference
            profile: cloneDeep(father),
            disease_color_map: profile_node.data.disease_color_map,

            onDeleteCheck: this.handleCheckDeleteSelectedPerson,
            onDeleteOpen: this.onClickOpenModalConfirmDelete,
            onAddParents: this.addParents,
            onAddChildren: this.addChildren,
            onAddPartner: this.addPartner,
            onAddSiblings: this.addSiblings,
            onCreatePartnerWtihChildren: this.addPartnerWithChildren,
            getPedigreeData: this.props.getPedigreeData,
            navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
          },
          sourcePosition: "left",
          targetPosition: "top"
        };

        const mother_node = {
          id: mother.id + "",
          type: ClientSideNodeTypes.PERSON,
          nodeType: ApiNodeTypes.PERSON,
          position: mother_node_position,
          data: {
            label: mother.id,

            gender: "female",

            datastore: this.datastore,
            nodeType: "^^" + ApiNodeTypes.PERSON,
            id: mother.id + "",

            nodesSelectedCount: 0,
            isNodeDragging: false,
            is_proband: false,
            proband_id: this.props.getPedigreeData().getProband().id + "",
            proband_mother_id: this.props.getPedigreeData().getProband().mother_id + "",
            proband_father_id: this.props.getPedigreeData().getProband().father_id + "",
            proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id + "",
            proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id + "",
            proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id + "",
            proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id + "",

            // perform a deep clone here so its not tied to the redux reference
            profile: cloneDeep(mother),
            disease_color_map: profile_node.data.disease_color_map,

            onDeleteCheck: this.handleCheckDeleteSelectedPerson,
            onDeleteOpen: this.onClickOpenModalConfirmDelete,
            onAddParents: this.addParents,
            onAddChildren: this.addChildren,
            onAddPartner: this.addPartner,
            onAddSiblings: this.addSiblings,
            onCreatePartnerWtihChildren: this.addPartnerWithChildren,
            getPedigreeData: this.props.getPedigreeData,
            navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
          },
          sourcePosition: "left",
          targetPosition: "top"
        };

        const connector_uuid = `0xr-${relationship.id}_t`;
        const connector_node = {
          id: `${connector_uuid}`,
          nodeType: ApiNodeTypes.TOP,
          position: connector_node_position,
          sourcePosition: "bottom",
          targetPosition: "bottom",
          style: {
            backgroundColor: "#777",
            border: "1px solid #777",
            borderRadius: "40px",
            height: "3px",
            padding: "0px",
            width: "3px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^" + ApiNodeTypes.TOP,
            id: `${connector_uuid}`,
          }
        };

        const child_connector_uuid = `0xr-${relationship.id}_b`;
        const child_connector_node = {
          id: `${child_connector_uuid}`,
          nodeType: ApiNodeTypes.BOTTOM,
          position: child_connector_node_position,
          sourcePosition: "left",
          targetPosition: "left",
          style: {
            backgroundColor: "#777",
            border: "1px solid #777",
            borderRadius: "40px",
            height: "3px",
            padding: "0px",
            width: "3px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^" + ApiNodeTypes.BOTTOM,
            id: `${child_connector_uuid}`,
          }
        };

        const father_edge_uuid = createUUID();
        const father_edge = {
          id: `${father_edge_uuid}`,
          edgeType: ApiEdgeTypes.PersonToTop,
          type: ApiEdgeTypes.PersonToTop,
          source: father.id + "",
          target: connector_node.id,
          style: {
            stroke: "#bdbdbd",
            strokeWidth: "2px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^undefined",
            id: `${father_edge_uuid}`,
            multiple_birth_type: null
          }
        };

        const mother_edge_uuid = createUUID();
        const mother_edge = {
          id: `${mother_edge_uuid}`,
          edgeType: ApiEdgeTypes.PersonToTop,
          type: ApiEdgeTypes.PersonToTop,
          source: mother.id + "",
          target: connector_node.id,
          style: {
            stroke: "#bdbdbd",
            strokeWidth: "2px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^undefined",
            id: `${mother_edge_uuid}`,
            multiple_birth_type: null
          }
        };

        const top_to_bottom_edge_uuid = createUUID();
        const top_to_bottom_edge = {
          id: `${top_to_bottom_edge_uuid}`,
          edgeType: ApiEdgeTypes.TopToBottom,
          type: ApiEdgeTypes.TopToBottom,
          source: connector_node.id,
          target: child_connector_node.id,
          style: {
            stroke: "#bdbdbd",
            strokeWidth: "2px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^undefined",
            id: `${top_to_bottom_edge_uuid}`,
            multiple_birth_type: null
          }
        };

        const bottom_to_child_edge_uuid = createUUID();
        const bottom_to_child_edge = {
          id: `${bottom_to_child_edge_uuid}`,
          edgeType: ApiEdgeTypes.BottomToChild,
          type: ApiEdgeTypes.BottomToChild,
          source: child_connector_node.id,
          target: profile.id + "",
          style: {
            stroke: "#bdbdbd",
            strokeWidth: "2px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^undefined",
            id: `${bottom_to_child_edge_uuid}`,
            multiple_birth_type: null
          }
        };

        nodes.push(father_node);
        nodes.push(mother_node);
        nodes.push(connector_node);
        nodes.push(child_connector_node);
        nodes.push(father_edge);
        nodes.push(mother_edge);
        nodes.push(top_to_bottom_edge);
        nodes.push(bottom_to_child_edge);
        //
        // // do we need this, they should be getting added in the edge class?
        // // this.datastore.updateEdge({"src":father.id+"","target":connector_node.id, "type":"Spouse","edgelist":[father_edge.id]});
        // // this.datastore.updateEdge({"src":mother.id+"","target":connector_node.id, "type":"Spouse","edgelist":[mother_edge.id]});
        // // this.datastore.updateEdge({"src":connector_node.id,"target":child_connector_node.id, "type":"","edgelist":[top_to_bottom_edge.id]});
        // // this.datastore.updateEdge({"src":child_connector_node.id,"target":profile.id+"", "type":"","edgelist":[bottom_to_child_edge.id]});
        //
        // call pedigree callback to render changes
        if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
          await this.pedigreeCallbacks.setElements(nodes);
        }
        let currentEls = cloneDeep(nodes)
        await this.saveLayoutData(currentEls)
      }
      else{
        await this.reRenderPedigree();
      }


    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  async getSpouse(profile) {
    let partners = null;
    try {
      partners = await familyApi.get_member_memberid_partners(profile.id);
    } catch (error) {
      throw error;
    }

    if (partners && Array.isArray(partners) && partners.length > 0 && partners.length === 1) {
      return partners[0]
    } else {
      throw new Error(`Multiple Partners - Please add children to the proper partner`);
    }
  }

  async addPartnerWithChildren(profile, type, num_to_add) {
    // if adding no children or infertility nodes without a partner, call function
    // to update the node's property to render the fake nodes, and return so it would
    // not be added to the actual database
    if (type == 'no_children' || type == 'infertility'){
      await this.updateInfertileNoChildrenValue(profile, type, true)
      return;
    }

    await this.addPartner(profile, "married", true, true);
    await this.addChildren(profile, type, num_to_add);
  }

  async addChildren(profile, type, num_to_add) {
    try {

      // if adding no_children or infertility nodes to an existing couple, update the
      // actual selected profile's infertile or no_children property to true to render
      // fake child, then return so it would not be added to the actual database
      let people = Object.values(this.props.getPedigreeData().getAllProfiles())

      if (type == 'no_children' || type == 'infertility'){
        let updated_profile = people.find(person => person.id == profile.id)
        if (updated_profile.infertile || updated_profile.no_children){
          throw new Error("Cannot add children if parents are infertile or marked no children.");
        }
        await this.updateInfertileNoChildrenValue(profile, type, true)
        return;
      }

      let partner = profile;
      let spouse = await this.getSpouse(profile);

      if (!spouse) return
      if (!partner) return

      let saved_data = this.getActiveSavedNodePositions();

      let has_children = false;

      // const elmnts = this.reactFlowInstance.getElements();
      // for (let z=0; z<elmnts.length; z++) {
      //   if (elmnts[z].type === ClientSideNodeTypes.PERSON &&
      //     (elmnts[z].data.profile.mother_id+"" === spouse.id+"" ||
      //     elmnts[z].data.profile.father_id+"" === spouse.id+"")) {
      //     has_children = true;
      //   }
      // }
      let children = people.filter(person => (person.mother_id == partner.id && person.father_id == spouse.id) || (person.mother_id == spouse.id && person.father_id == partner.id))

      if(children.find(child => child.is_infertility_node || child.is_no_children_node)) throw new Error("Cannot add children if parents are infertile or marked no children.");

      // start react-flow update code
      let nodes = cloneDeep(this.reactFlowInstance.getNodes()).concat(cloneDeep(this.reactFlowInstance.getEdges()))

      if(children.length > 0) has_children = true;

      let child_data = {};
      for(let i = 0; i < num_to_add; i++){
        let gender = this.getChildGender(type);
        let child = model.createSonDaughter(gender);
        child.pregnancy = false;
        if ([actions.MALE_FETAS, actions.FEMALE_FETAS, actions.PREGNANCY_UNKNOWN].includes(type)) {
          child.pregnancy = true;
        }

        child.value = type;

        // Save to API
        child_data = await helper_family_api.create_member_as_child(
          spouse,
          partner,
          child,
          profile.side
        );

        // set extra attributes expected to be in member profile
        child_data.rkey = `apimem-${child_data.id}`;
        child_data.diseases = [];
        child_data.genetic_testing = [];
        child_data.family_id_id = child_data.family_id;
        child_data.partners = [];
        child_data.relationship_ids = [];
        child_data.twin_id_id = child_data.twin_id;

        this.props.getPedigreeData().setProfile(child_data.rkey, child_data);

        let siblings = people.filter(person => person.id !== child_data.id && ((person.mother_id == partner.id && person.father_id == spouse.id) || (person.mother_id == spouse.id && person.father_id == partner.id)))

        if(!isEmpty(saved_data)){
          const profile_node = nodes.find((n) => n.id + "" === profile.id + "");

          // get the top connector node
          const profile_partner_edge = nodes.find((n) => n.source && n.target && n.source === profile.id + "");
          const profile_partner_connector = nodes.find((n) => n.id === profile_partner_edge.target);

          let child_x_position = profile_partner_connector.position.x-19
          let child_y_position = profile_partner_connector.position.y+110
          //child_y_position should be equal to the y of the s
          if(siblings.length > 0){
            let nodes_of_siblings = nodes.filter((node) => siblings.find((sibling) => node.id == sibling.id));

            //get x of most left and most right nodes from the siblings
            let most_right = Math.max(...nodes_of_siblings.map(node => node.position.x))
            let most_left = Math.min(...nodes_of_siblings.map(node => node.position.x))

            //get y of most left and most right nodes from the sibling
            let y_most_right = nodes_of_siblings.find(node => node.position.x == most_right).position.y
            let y_most_left = nodes_of_siblings.find(node => node.position.x == most_left).position.y

            //for now, we choose randomly if we add it to the right or left
            const rndInt = Math.floor(Math.random() * 2) + 1
            child_x_position = rndInt === 1 ? most_left - 70 : most_right + 70
            child_y_position = rndInt === 1 ? y_most_left : y_most_right
          }

          const child_node_position = {x: child_x_position, y: child_y_position};

          let g = 'unknown';
          if (child_data.gender === 'm') {
            g = 'male';
          } else if (child_data.gender === 'f') {
            g = 'female';
          }

          const child_node = {
            id: child_data.id+"",
            type: ClientSideNodeTypes.PERSON,
            nodeType: ApiNodeTypes.PERSON,
            position: child_node_position,
            data: {
              label: child_data.id,

              gender: g,

              datastore: this.datastore,
              nodeType: "^^" + ApiNodeTypes.PERSON,
              id: child_data.id+"",

              nodesSelectedCount: 0,
              isNodeDragging: false,
              is_proband: false,
              proband_id: this.props.getPedigreeData().getProband().id+"",
              proband_mother_id: this.props.getPedigreeData().getProband().mother_id+"",
              proband_father_id: this.props.getPedigreeData().getProband().father_id+"",
              proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id+"",
              proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id+"",
              proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id+"",
              proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id+"",

              // perform a deep clone here so its not tied to the redux reference
              profile: cloneDeep(child_data),
              disease_color_map: profile_node.data.disease_color_map,

              onDeleteCheck: this.handleCheckDeleteSelectedPerson,
              onDeleteOpen: this.onClickOpenModalConfirmDelete,
              onAddParents: this.addParents,
              onAddChildren: this.addChildren,
              onAddPartner: this.addPartner,
              onAddSiblings: this.addSiblings,
              onCreatePartnerWtihChildren: this.addPartnerWithChildren,
              getPedigreeData: this.props.getPedigreeData,
              navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
            },
            sourcePosition: "left",
            targetPosition: "top"
          };

          let connector_node = null;
          if (!has_children) {
            const connector_node_position = {x: profile_partner_connector.position.x, y: profile_partner_connector.position.y+50};
            let p = this.props.getPedigreeData().getProfile(profile.rkey)
            let specific_relationship_id = p.relationship_ids.find(relationship => (relationship.mother_id == partner.id && relationship.father_id == spouse.id) || (relationship.mother_id == spouse.id && relationship.father_id == partner.id))

            const connector_uuid = `0xr-${specific_relationship_id.id}_b`;
            connector_node = {
              id: `${connector_uuid}`,
              nodeType: ApiNodeTypes.BOTTOM,
              position: connector_node_position,
              sourcePosition: "bottom",
              targetPosition: "bottom",
              style: {
                backgroundColor: "#777",
                border: "1px solid #777",
                borderRadius: "40px",
                height: "3px",
                padding: "0px",
                width: "3px"
              },
              data: {
                datastore: this.datastore,
                getPedigreeData: this.props.getPedigreeData,
                id: `${connector_uuid}`,
                label: "",
                navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
                nodeClickCount: 0,
                nodeType: "^^" + ApiNodeTypes.BOTTOM,
                readOnlyUser: false,
              }
            };

            const top_edge_uuid = createUUID();
            const top_edge = {
              id: `${top_edge_uuid}`,
              edgeType: ApiEdgeTypes.TopToBottom,
              type: ApiEdgeTypes.TopToBottom,
              source: profile_partner_connector.id,
              target: connector_node.id,
              style: {
                stroke: "#bdbdbd",
                strokeWidth: "2px"
              },
              data: {
                label: "",

                getPedigreeData: this.props.getPedigreeData,
                datastore: this.datastore,
                nodeType: "^^undefined",
                id: `${top_edge_uuid}`,
                multiple_birth_type: null
              }
            };

            nodes.push(connector_node);
            nodes.push(top_edge);

            // do we need this, they should be getting added in the edge class?
            // this.datastore.updateEdge({"src":profile_partner_connector.id,"target":connector_node.id, "type":"","edgelist":[top_edge.id]});
          } else {
            // const connector_node_edge = nodes.find((n) => n.source && n.target && n.source === profile_partner_connector.id && n.type === ApiEdgeTypes.TopToBottom);
            // connector_node = nodes.find((n) => n.id === connector_node_edge.target);
            let nodes_of_siblings = nodes.filter((node) => siblings.find((sibling) => node.id == sibling.id));

            const sibling_group_edge = nodes.find((n) => n.source && n.target && n.target === nodes_of_siblings[0].id && (n.type === ApiEdgeTypes.BottomToChild || n.type === ClientSideEdgeTypes.BottomToTwinChild));
            connector_node = nodes.find((n) => n.id === sibling_group_edge.source);

          }

          const bottom_edge_uuid = createUUID();
          const bottom_edge = {
            id: `${bottom_edge_uuid}`,
            edgeType: ApiEdgeTypes.BottomToChild,
            type: ApiEdgeTypes.BottomToChild,
            source: connector_node.id,
            target: child_data.id+"",
            style: {
              stroke: "#bdbdbd",
              strokeWidth: "2px"
            },
            data: {
              label: "",

              getPedigreeData: this.props.getPedigreeData,
              datastore: this.datastore,
              nodeType: "^^undefined",
              id: `${bottom_edge_uuid}`,
              multiple_birth_type: null
            }
          };

          nodes.push(child_node);
          nodes.push(bottom_edge);

          // do we need this, they should be getting added in the edge class?
          // this.datastore.updateEdge({"src":connector_node.id,"target":child_data.id+"", "type":"","edgelist":[bottom_edge.id]});
        }
      }



      // if there are saved positions, then savelayout data of the current elements,
      // if no saved positions, just reRenderPedigree
      if(!isEmpty(saved_data)){
        // call pedigree callback to render changes
        if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
          this.pedigreeCallbacks.setElements(nodes);
        }
        let currentEls = cloneDeep(nodes)
        await this.saveLayoutData(currentEls)
      }
      else{
        await this.reRenderPedigree();
      }

      //else
      //

    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] });
    }
  }

  // function to delete fake nodes of a profile, this only updates the pedigree data store,
  // not the actual deletion of the node
  async deleteFakePartnerAndChild(profile){
    let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
    let fake_child = people.find(person => person.is_fake_node && (person.is_no_children_node || person.is_infertility_node) && (person.mother_id == profile.id || person.father_id == profile.id))
    if (fake_child == null || fake_child == undefined) return;
    let fake_partner_id = fake_child.mother_id == profile.id ? fake_child.father_id : fake_child.mother_id
    let fake_partner = people.find(person => person.id == fake_partner_id && person.is_fake_node)

    let fake_nodes = [fake_partner, fake_child]

    for(let node of fake_nodes){
      if(node){
        if(node.infertile || node.no_children){
          let partner_of_fake_partner = fake_partner.relationship_data.father_id == fake_partner.id ? fake_partner.relationship_data.mother_id : fake_partner.relationship_data.father_id
          partner_of_fake_partner = people.find(person => person.id == partner_of_fake_partner)
          this.deleteFromPedigreeData(node, [partner_of_fake_partner])
        }
        else{
          this.deleteFromPedigreeData(node, [])
        }
      }
    }
  }

  // function to update the pedigree data store's existing infertile and no_children members
  // and add their fake nodes as well to the profiles
  // (this is not for actual rendering, this is for the data to be sent to the layout endpoint)
  async addFakePartnersAndChildren(){
    let people = Object.values(cloneDeep(this.props.getPedigreeData().getAllProfiles()))
    let infertile_or_no_children_people = people.filter(person => person.infertile || person.no_children)

    for(let person of infertile_or_no_children_people){
      if(person.partners.length === 0){
        let partner = await this.addFakePartnerInfertileNoChildrenNode(person, 'married', person.infertile ? 'infertile' : 'no_children')
        await this.addFakeChildIsInfertilityIsNoChildrenNode(partner, person, person.infertile ? 'is_infertility_node' : 'is_no_children_node')
      }
      else{
        for(let partner of person.partners){
          let is_infertile_or_no_children = person.infertile ? 'infertile' : 'no_children'
          if(is_infertile_or_no_children == 'infertile'){
            await this.addFakeChildIsInfertilityIsNoChildrenNode(partner, person, 'is_infertility_node')
          }
          else{
            await this.addFakeChildIsInfertilityIsNoChildrenNode(partner, person, 'is_no_children_node')
          }
        }
      }
    }

  }

  // function to set a member to be infertile or no_children
  async updateInfertileNoChildrenValue(profile, type, value){
    // patch member call to update
    let key = type == 'infertility' ? 'infertile' : 'no_children'

    await familyApi.patch_member_memberid(profile.id, {[key]: value})
    let profile_data = cloneDeep(this.props.getPedigreeData().getProfile(profile.rkey))
    if(key == 'infertile'){
      profile_data.infertile = value
    }
    else{
      profile_data.no_children = value
    }
    await this.props.getPedigreeData().setProfile(profile.rkey, profile_data)

    // if value is true, it means adding, so execute these.
    if(value){
      await this.addFakePartnersAndChildren();

      let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()))

      let saved_data = this.getActiveSavedNodePositions();

      let nodes = cloneDeep(this.reactFlowInstance.getNodes()).concat(cloneDeep(this.reactFlowInstance.getEdges()))

      // if there are saved nodes, we have to render the fake nodes,
      // without rerendering the pedigree
      if(!isEmpty(saved_data)){
        const profile_node = nodes.find((n) => n.id + "" === profile.id + "");

        for(let partner of profile_data.partners){
          let infertile_no_children_data = people.find(person => ((person.father_id == partner.id && person.mother_id == profile_data.id) || (person.father_id == profile_data.id && person.mother_id == partner.id)) && (person.is_no_children_node || person.is_infertility_node) && person.is_fake_node)
          if(!profile_data.is_blood_related_to_proband){
            infertile_no_children_data = people.find(person => (person.father_id == profile_data.id || person.mother_id == profile_data.id) && (person.is_no_children_node || person.is_infertility_node) && person.is_fake_node)
          }

          let edge_to_find = profile_data.is_blood_related_to_proband ? partner.id + "" : profile.id + ""

          const profile_partner_edge = nodes.find((n) => n.source && n.target && n.source === edge_to_find);
          let profile_partner_connector = profile_partner_edge ? nodes.find((n) => n.id === profile_partner_edge.target) : null;

          let child_x_position = profile_partner_connector ? profile_partner_connector.position.x-19 : profile_node.position.x
          let child_y_position = profile_partner_connector ? profile_partner_connector.position.y+110 : profile_node.position.y+195

          const child_node_position = {x: child_x_position, y: child_y_position};

          let g = 'unknown';
          if (infertile_no_children_data.gender === 'm') {
            g = 'male';
          } else if (infertile_no_children_data.gender === 'f') {
            g = 'female';
          }

          const child_node = {
            id: infertile_no_children_data.id+"",
            type: ClientSideNodeTypes.PERSON,
            nodeType: ApiNodeTypes.PERSON,
            position: child_node_position,
            data: {
              label: infertile_no_children_data.id,

              gender: g,

              datastore: this.datastore,
              nodeType: "^^" + ApiNodeTypes.PERSON,
              id: infertile_no_children_data.id+"",

              nodesSelectedCount: 0,
              isNodeDragging: false,
              is_proband: false,
              proband_id: this.props.getPedigreeData().getProband().id+"",
              proband_mother_id: this.props.getPedigreeData().getProband().mother_id+"",
              proband_father_id: this.props.getPedigreeData().getProband().father_id+"",
              proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id+"",
              proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id+"",
              proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id+"",
              proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id+"",

              // perform a deep clone here so its not tied to the redux reference
              profile: cloneDeep(infertile_no_children_data),
              disease_color_map: profile_node.data.disease_color_map,

              onDeleteCheck: this.handleCheckDeleteSelectedPerson,
              onDeleteOpen: this.onClickOpenModalConfirmDelete,
              onAddParents: this.addParents,
              onAddChildren: this.addChildren,
              onAddPartner: this.addPartner,
              onAddSiblings: this.addSiblings,
              onCreatePartnerWtihChildren: this.addPartnerWithChildren,
              getPedigreeData: this.props.getPedigreeData,
              navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
            },
            sourcePosition: "left",
            targetPosition: "top"
          };

          let connector_node = null;
          const connector_node_position = {x: profile_partner_connector ? profile_partner_connector.position.x : profile_node.position.x+19, y: profile_partner_connector ? profile_partner_connector.position.y+50 : profile_node.position.y+133};
          let p = this.props.getPedigreeData().getProfile(profile.rkey)
          let specific_relationship_id = p.relationship_ids.find(relationship => (relationship.mother_id == infertile_no_children_data.mother_id && relationship.father_id == infertile_no_children_data.father_id) || (relationship.mother_id == infertile_no_children_data.father_id && relationship.father_id == infertile_no_children_data.mother_id))

          const connector_uuid = `0xr-${specific_relationship_id.id}_b`;
          connector_node = {
            id: `${connector_uuid}`,
            nodeType: ApiNodeTypes.BOTTOM,
            position: connector_node_position,
            sourcePosition: "bottom",
            targetPosition: "bottom",
            style: {
              backgroundColor: "#777",
              border: "1px solid #777",
              borderRadius: "40px",
              height: "3px",
              padding: "0px",
              width: "3px"
            },
            data: {
              label: "",

              getPedigreeData: this.props.getPedigreeData,
              datastore: this.datastore,
              nodeType: "^^" + ApiNodeTypes.BOTTOM,
              id: `${connector_uuid}`,
            }
          };

          const top_edge_uuid = createUUID();
          const top_edge = {
            id: `${top_edge_uuid}`,
            edgeType: ApiEdgeTypes.TopToBottom,
            type: ApiEdgeTypes.TopToBottom,
            source: profile_partner_connector ? profile_partner_connector.id : profile_node.id,
            target: connector_node.id,
            style: {
              stroke: "#bdbdbd",
              strokeWidth: "2px"
            },
            data: {
              label: "",

              getPedigreeData: this.props.getPedigreeData,
              datastore: this.datastore,
              nodeType: "^^undefined",
              id: `${top_edge_uuid}`,
              multiple_birth_type: null
            }
          };

          nodes.push(connector_node);
          nodes.push(top_edge);


          const bottom_edge_uuid = createUUID();
          const bottom_edge = {
            id: `${bottom_edge_uuid}`,
            edgeType: ApiEdgeTypes.BottomToChild,
            type: ApiEdgeTypes.BottomToChild,
            source: connector_node.id,
            target: infertile_no_children_data.id+"",
            style: {
              stroke: "#bdbdbd",
              strokeWidth: "2px"
            },
            data: {
              label: "",

              getPedigreeData: this.props.getPedigreeData,
              datastore: this.datastore,
              nodeType: "^^undefined",
              id: `${bottom_edge_uuid}`,
              multiple_birth_type: null
            }
          };

          nodes.push(child_node);
          nodes.push(bottom_edge);
        }


        // do we need this, they should be getting added in the edge class?
        // this.datastore.updateEdge({"src":connector_node.id,"target":child_data.id+"", "type":"","edgelist":[bottom_edge.id]});
      }


      if(!isEmpty(saved_data)){
        // call pedigree callback to render changes
        if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
          this.pedigreeCallbacks.setElements(nodes);
        }
        let currentEls = cloneDeep(nodes)
        await this.saveLayoutData(currentEls)
      }
      else{
        // if there is no saved positions, use the layout endpoint
        // to render the fake nodes
        await this.reRenderPedigree();
      }

    }
  }

  // function to add a fake partner profile with infertile or no_children properties
  // on the pedigree data store so it would be sent to the layout endpoint
  async addFakePartnerInfertileNoChildrenNode(profile, type, infertile_or_no_children){

    let partner = model.createPartner();

    partner.relationship_data.is_parent_blood_related =
      type === "consanguineous";
    partner.relationship_data.marital_status =
      type === "consanguineous" ? "other" : type;

    partner.sons_count = 0;
    partner.daughters_count = 0;

    // since there is also one possible fake partner for each person, this should work
    let temp_partner_id = `fake_partner-${profile.id}`;
    let temp_relationship_id = `fake_relationship-${profile.id}`;

    partner.id = temp_partner_id

    let partner_gender = '';
    if(profile.gender !== null && profile.gender.toLowerCase() !== 'u'){
      partner_gender = (profile.gender.toLowerCase() == 'm' ? 'f' : 'm')
    }
    else{
      partner_gender = null;
    }

    let father_id = 0;
    let mother_id = 0;
    if(profile.gender !== null && profile.gender.toLowerCase() !== 'u'){

      if(profile.gender.toLowerCase() == 'm') {
        father_id = profile.id;
        mother_id = partner.id;
      } else if (profile.gender.toLowerCase() == 'f') {
        father_id = partner.id;
        mother_id = profile.id;
      }

    }
    else{
      father_id = partner.id;
      mother_id = profile.id;
    }

    partner.relationship_data.id = temp_relationship_id
    partner.relationship_data.rkey = `r-${temp_relationship_id}`;
    partner.gender = partner_gender;
    partner.relationship_data.blood_relation_type = ''
    partner.relationship_data.mother_id = mother_id;
    partner.relationship_data.father_id = father_id;
    partner.relationship_data.mother_id_id = mother_id;
    partner.relationship_data.father_id_id = father_id;
    partner.relationship_data.pregnancy = null;
    partner.rkey = `apimem-${partner.id}`;
    partner.diseases = [];
    partner.genetic_testing = [];
    partner.family_id = this.props.getPedigreeData().getProband().family_id
    partner.is_abortion_node = false;
    partner.is_blood_related_to_proband = false;
    partner.is_dead = false;
    partner.is_infertility_node = false;
    partner.is_no_children_node = false;
    partner.is_proband = false
    partner.infertile = infertile_or_no_children === 'infertile'
    partner.no_children = infertile_or_no_children === 'no_children'
    partner.family_id_id = partner.family_id;
    partner.mother_id = null;
    partner.father_id = null;
    partner.mother_id_id = null;
    partner.father_id_id = null;
    partner.partners = [profile];
    partner.relationship_ids = [partner.relationship_data]
    partner.twin_id = null;
    partner.twin_id_id = null;
    partner.is_fake_node = true;

    this.props.getPedigreeData().setProfile(partner.rkey, partner);

    const p = this.props.getPedigreeData().getProfile(profile.rkey);
    if (p.relationship_ids) {
      p.relationship_ids.push(partner.relationship_data);
    } else {
      p.relationship_ids = [partner.relationship_data];
    }

    if (p.partners) {
      p.partners.push(partner);
    } else {
      p.partners = [partner];
    }
    this.props.getPedigreeData().setProfile(p.rkey, p);


    return partner;
  }

  addFakePartnerConsanguineous(profile, original_partner){
    let p = this.props.getPedigreeData().getProfile(profile.rkey);
    let original_relationship_data = p.relationship_ids.find(relationship => (relationship.mother_id == original_partner.id && relationship.father_id == profile.id) || (relationship.mother_id == profile.id && relationship.father_id == original_partner.id))
    let partner = model.createPartner();

    partner.relationship_data.is_parent_blood_related = true
    partner.relationship_data.marital_status = "other"

    partner.sons_count = 0;
    partner.daughters_count = 0;

    // since there is also one possible fake partner for each person, this should work
    let temp_partner_id = `fake_consanguineous-partner-${profile.id}`;
    let temp_relationship_id = original_relationship_data.id;

    partner.id = temp_partner_id

    let partner_gender = '';
    if(profile.gender !== null && profile.gender.toLowerCase() !== 'u'){
      partner_gender = (profile.gender.toLowerCase() == 'm' ? 'f' : 'm')
    }
    else{
      partner_gender = null;
    }

    let father_id = 0;
    let mother_id = 0;
    if(profile.gender !== null && profile.gender.toLowerCase() !== 'u'){

      if(profile.gender.toLowerCase() == 'm') {
        father_id = profile.id;
        mother_id = partner.id;
      } else if (profile.gender.toLowerCase() == 'f') {
        father_id = partner.id;
        mother_id = profile.id;
      }

    }
    else{
      father_id = partner.id;
      mother_id = profile.id;
    }

    let people = Object.values(this.props.getPedigreeData().getAllProfiles());
    let children = people.filter(person => (person.mother_id == profile.id && person.father_id == original_partner.id) || (person.mother_id == original_partner.id && person.father_id == profile.id))
    for(let child of children){
      child.mother_id = mother_id;
      child.father_id = father_id;

      this.props.getPedigreeData().setProfile(child.rkey, child);
    }

    partner.relationship_data.id = temp_relationship_id
    partner.relationship_data.rkey = `r-${temp_relationship_id}`;
    partner.gender = partner_gender;
    partner.relationship_data.blood_relation_type = ''
    partner.relationship_data.mother_id = mother_id;
    partner.relationship_data.father_id = father_id;
    partner.relationship_data.mother_id_id = mother_id;
    partner.relationship_data.father_id_id = father_id;
    partner.relationship_data.pregnancy = null;
    partner.rkey = `apimem-${partner.id}`;
    partner.diseases = [];
    partner.genetic_testing = [];
    partner.family_id = this.props.getPedigreeData().getProband().family_id
    partner.is_abortion_node = false;
    partner.is_blood_related_to_proband = false;
    partner.is_dead = false;
    partner.is_infertility_node = false;
    partner.is_no_children_node = false;
    partner.is_proband = false
    partner.infertile = false
    partner.no_children = false
    partner.family_id_id = partner.family_id;
    partner.mother_id = null;
    partner.father_id = null;
    partner.mother_id_id = null;
    partner.father_id_id = null;
    partner.partners = [profile];
    partner.relationship_ids = [partner.relationship_data]
    partner.twin_id = null;
    partner.twin_id_id = null;
    partner.is_fake_node = true;

    this.props.getPedigreeData().setProfile(partner.rkey, partner);

    p.relationship_ids = p.relationship_ids.filter(rid => rid.rkey != original_relationship_data.rkey);

    if (p.relationship_ids) {
      p.relationship_ids.push(partner.relationship_data);
    } else {
      p.relationship_ids = [partner.relationship_data];
    }

    if (p.partners) {
      p.partners.push(partner);
    } else {
      p.partners = [partner];
    }
    this.props.getPedigreeData().setProfile(p.rkey, p);

    let original_partner_data = this.props.getPedigreeData().getProfile(original_partner.rkey);
    original_partner_data.relationship_ids = original_partner_data.relationship_ids.filter(rid => rid.rkey != original_relationship_data.rkey);
    this.props.getPedigreeData().setProfile(original_partner_data.rkey, original_partner_data);

    return partner;
  }

  // function to add a fake child profile with is_no_children_node or is_infertility_node
  // properties on the pedigree data store so it would be sent to the layout endpoint
  async addFakeChildIsInfertilityIsNoChildrenNode(profile, spouse, infertility_node_or_no_children_node){
    let partner = profile;

    if (!spouse) return
    if (!partner) return

    let has_children = false;
    let people = Object.values(this.props.getPedigreeData().getAllProfiles())

    let children = people.filter(person => (person.mother_id == partner.id && person.father_id == spouse.id) || (person.mother_id == spouse.id && person.father_id == partner.id))

    if(children.find(child => child.is_infertility_node || child.is_no_children_node)) return

    if(children.length > 0) has_children = true;

    let gender = this.getChildGender(infertility_node_or_no_children_node);
    let child = model.createSonDaughter(gender);

    child.pregnancy = false;
    if ([actions.MALE_FETAS, actions.FEMALE_FETAS, actions.PREGNANCY_UNKNOWN].includes(infertility_node_or_no_children_node)) {
      child.pregnancy = true;
    }

    let father_id = 0;
    let mother_id = 0;
    if(partner.gender !== null && partner.gender.toLowerCase() !== 'u'){

      if(partner.gender.toLowerCase() == 'm') {
        father_id = partner.id;
        mother_id = spouse.id;
      } else if (partner.gender.toLowerCase() == 'f') {
        father_id = spouse.id;
        mother_id = partner.id;
      }

    }
    else{
      father_id = spouse.id;
      mother_id = partner.id;
    }

    let temp_child_id = `fake_child-${father_id}-${mother_id}`;
    child.id = temp_child_id

    // set extra attributes expected to be in member profile
    child.rkey = `apimem-${child.id}`;
    child.father_id = father_id
    child.mother_id = mother_id
    child.diseases = [];
    child.genetic_testing = [];
    child.family_id = this.props.getPedigreeData().getProband().family_id
    child.family_id_id = child.family_id;
    child.partners = [];
    child.relationship_ids = [];
    child.is_abortion_node = false;
    child.is_infertility_node = infertility_node_or_no_children_node == 'is_infertility_node'
    child.is_no_children_node = infertility_node_or_no_children_node == 'is_no_children_node'
    child.twin_id = null
    child.twin_id_id = null
    child.is_fake_node = true;

    await this.props.getPedigreeData().setProfile(child.rkey, child);

    return child
  }

  async addPartner(profile, type, skip_reRender=false, hide_after_adding=true) {
    try {

      let partner = model.createPartner();
      let parent_side = profile.side;

      partner.relationship_data.is_parent_blood_related =
        type === "consanguineous";
      partner.relationship_data.marital_status =
        type === "consanguineous" ? "other" : type;

      let partner_data = await helper_family_api.create_partner(
        profile,
        partner,
        parent_side
      );
      partner_data.sons_count = 0;
      partner_data.daughters_count = 0;

      // set extra attributes expected to be in member profile
      partner_data.relationship_data.rkey = `r-${partner_data.relationship_data.id}`;
      partner_data.relationship_data.mother_id_id = partner_data.relationship_data.mother_id;
      partner_data.relationship_data.father_id_id = partner_data.relationship_data.father_id;
      partner_data.rkey = `apimem-${partner_data.id}`;
      partner_data.diseases = [];
      partner_data.genetic_testing = [];
      partner_data.family_id_id = partner_data.family_id;
      partner_data.mother_id = null;
      partner_data.father_id = null;
      partner_data.mother_id_id = null;
      partner_data.father_id_id = null;
      partner_data.partners = [profile];
      partner_data.relationship_ids = [partner_data.relationship_data];
      partner_data.twin_id_id = partner_data.twin_id;

      if(hide_after_adding){
        partner_data.hidden = true;
      }
      else{
        partner_data.hidden = false;
      }

      this.props.getPedigreeData().setProfile(partner_data.rkey, partner_data);

      const p = this.props.getPedigreeData().getProfile(profile.rkey);
      if (p.relationship_ids) {
        p.relationship_ids.push(partner_data.relationship_data);
      } else {
        p.relationship_ids = [partner_data.relationship_data];
      }

      if (p.partners) {
        p.partners.push(partner_data);
      } else {
        p.partners = [partner_data];
      }
      this.props.getPedigreeData().setProfile(p.rkey, p);

      let saved_data = this.getActiveSavedNodePositions();

      if (!isEmpty(saved_data)) {
        // start react-flow update code
        let nodes = cloneDeep(this.reactFlowInstance.getNodes()).concat(cloneDeep(this.reactFlowInstance.getEdges()))
        const profile_node = nodes.find((n) => n.id + "" === profile.id + "");

        if (profile_node.data.profile.relationship_ids) {
          profile_node.data.profile.relationship_ids.push(partner_data.relationship_data);
        } else {
          profile_node.data.profile.relationship_ids = [partner_data.relationship_data];
        }

        if (profile_node.data.profile.partners) {
          profile_node.data.profile.partners.push(partner_data.relationship_data);
        } else {
          profile_node.data.profile.partners = [partner_data.relationship_data];
        }

        let partner_x_position = profile_node.position.x + 130
        let partner_y_position = profile_node.position.y
        let connector_x_position = profile_node.position.x + 85
        let connector_y_position = profile_node.position.y + 18
        //child_y_position should be equal to the y of the s

        let nodes_of_partners_with_profile_node = nodes.filter((node) => p.partners.find((partner) => node.id == partner.id));
        nodes_of_partners_with_profile_node.push(profile_node)

        //get x of most left and most right nodes from the siblings
        let most_right = Math.max(...nodes_of_partners_with_profile_node.map(node => node.position.x))
        let most_left = Math.min(...nodes_of_partners_with_profile_node.map(node => node.position.x))

        //get y of most left and most right nodes from the sibling
        let y_most_right = nodes_of_partners_with_profile_node.find(node => node.position.x == most_right).position.y
        let y_most_left = nodes_of_partners_with_profile_node.find(node => node.position.x == most_left).position.y

        //for now, we choose randomly if we add it to the right or left
        const rndInt = Math.floor(Math.random() * 2) + 1
        partner_x_position = rndInt === 1 ? most_left - 130 : most_right + 130
        partner_y_position = rndInt === 1 ? y_most_left : y_most_right


        connector_x_position = rndInt === 1 ? partner_x_position + 80 : partner_x_position - 45
        connector_y_position = rndInt === 1 ? y_most_left + 18 : y_most_right + 18

        let connector_node_position = {x: connector_x_position, y: connector_y_position};
        let partner_node_position = {x: partner_x_position, y: partner_y_position};

        // if (partner_data.gender === 'm') {
        //   connector_node_position = { x: profile_node.position.x - 45, y: profile_node.position.y + 18 };
        //   partner_node_position = { x: profile_node.position.x - 130, y: profile_node.position.y };
        // } else {
        //   connector_node_position = { x: profile_node.position.x + 85, y: profile_node.position.y + 18 };
        //   partner_node_position = { x: profile_node.position.x + 130, y: profile_node.position.y };
        // }

        let g = 'unknown';
        if (partner_data.gender === 'm') {
          g = 'male';
        } else if (partner_data.gender === 'f') {
          g = 'female';
        }

        const partner_node = {
          id: partner_data.id + "",
          type: ClientSideNodeTypes.PERSON,
          nodeType: ApiNodeTypes.PERSON,
          position: partner_node_position,
          data: {
            label: partner_data.id,

            gender: g,

            datastore: this.datastore,
            nodeType: "^^" + ApiNodeTypes.PERSON,
            id: partner_data.id + "",

            nodesSelectedCount: 0,
            isNodeDragging: false,
            is_proband: false,
            proband_id: this.props.getPedigreeData().getProband().id + "",
            proband_mother_id: this.props.getPedigreeData().getProband().mother_id + "",
            proband_father_id: this.props.getPedigreeData().getProband().father_id + "",
            proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id + "",
            proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id + "",
            proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id + "",
            proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id + "",

            // perform a deep clone here so its not tied to the redux reference
            profile: cloneDeep(partner_data),
            disease_color_map: profile_node.data.disease_color_map,

            onDeleteCheck: this.handleCheckDeleteSelectedPerson,
            onDeleteOpen: this.onClickOpenModalConfirmDelete,
            onAddParents: this.addParents,
            onAddChildren: this.addChildren,
            onAddPartner: this.addPartner,
            onAddSiblings: this.addSiblings,
            onCreatePartnerWtihChildren: this.addPartnerWithChildren,
            getPedigreeData: this.props.getPedigreeData,
            navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
          },
          sourcePosition: "left",
          targetPosition: "top"
        };

        const connector_uuid = `0xr-${partner_data.relationship_data.id}_t`;
        // const connector_uuid = createUUID();
        const partner_connector_node = {
          id: `${connector_uuid}`,
          nodeType: ApiNodeTypes.TOP,
          position: connector_node_position,
          sourcePosition: "bottom",
          targetPosition: "bottom",
          style: {
            backgroundColor: "#777",
            border: "1px solid #777",
            borderRadius: "40px",
            height: "3px",
            padding: "0px",
            width: "3px"
          },
          data: {
            label: "",

            datastore: this.datastore,
            nodeType: "^^" + ApiNodeTypes.TOP,
            isPartnerConnectorNode: true,
            id: `${connector_uuid}`,
            nodesSelectedCount: 0,
            isNodeDragging: false,
            nodeClickCount: 0,
            reRenderPedigree: this.reRenderPedigree,
            getPedigreeData: this.props.getPedigreeData,
            navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
          }
        };

        let left_edge_source = null;
        let right_edge_source = null;
        if (profile_node.data.gender === 'male') {
          left_edge_source = profile.id + "";
          right_edge_source = partner_data.id + "";
        } else {
          left_edge_source = partner_data.id + "";
          right_edge_source = profile.id + "";
        }

        const left_edge_uuid = createUUID();
        const left_edge = {
          id: `${left_edge_uuid}`,
          edgeType: ApiEdgeTypes.PersonToTop,
          type: ApiEdgeTypes.PersonToTop,
          source: left_edge_source,
          target: `${connector_uuid}`,
          style: {
            stroke: "#bdbdbd",
            strokeWidth: "2px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^undefined",
            id: `${left_edge_uuid}`,
            multiple_birth_type: null
          }
        };

        const right_edge_uuid = createUUID();
        const right_edge = {
          id: `${right_edge_uuid}`,
          edgeType: ApiEdgeTypes.TopToBottom,
          type: ApiEdgeTypes.TopToBottom,
          source: right_edge_source,
          target: `${connector_uuid}`,
          style: {
            stroke: "#bdbdbd",
            strokeWidth: "2px"
          },
          data: {
            label: "",

            getPedigreeData: this.props.getPedigreeData,
            datastore: this.datastore,
            nodeType: "^^undefined",
            id: `${right_edge_uuid}`,
            multiple_birth_type: null
          }
        };

        nodes.push(partner_node);
        nodes.push(partner_connector_node);
        nodes.push(left_edge);
        nodes.push(right_edge);

        // reset and update the pedigree data store's infertile and no children people
        await this.deleteFakePartnerAndChild(profile)
        await this.addFakePartnersAndChildren();

        let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()));
        let updated_profile = people.find(person => person.id == profile.id)

        if (updated_profile.infertile || updated_profile.no_children) {

          // get the actual no_children and infertility node
          const no_children_infertility_exists = people.find(person =>
            (person.father_id == profile.id && person.mother_id == partner_data.id) ||
            (person.father_id == partner_data.id && person.mother_id == profile.id) &&
            (person.is_infertility_node || person.is_no_children_node) &&
            person.is_fake_node)

          const no_children_infertility_top_edge = nodes.find((n) => n.source && n.target && n.source === updated_profile.id + "" && n.target == `0xr-fake_relationship-${profile.id}_b`);
          const no_children_infertility_bottom_edge = nodes.find((n) => n.source && n.target && n.source === `0xr-fake_relationship-${profile.id}_b`);

          // use the specific relationship id that's just been added with the new partner
          let specific_relationship_id = updated_profile.relationship_ids.find(relationship => (relationship.mother_id == no_children_infertility_exists.mother_id && relationship.father_id == no_children_infertility_exists.father_id) || (relationship.mother_id == no_children_infertility_exists.father_id && relationship.father_id == no_children_infertility_exists.mother_id))

          if (no_children_infertility_top_edge && no_children_infertility_bottom_edge) {
            // if only one partner, update the existing nodes for the infertility/no_children
            if(updated_profile.partners.length == 1){
              for (let node of nodes) {
                // update these so saved positions would be applied on their specific ids

                // update the bottom connector node's id
                if (no_children_infertility_top_edge.target == node.id) {
                  node.position.x = partner_connector_node.position.x
                  node.id = `0xr-${specific_relationship_id.id}_b`
                }

                // update the bottom edge's source and target with the updated id
                if (no_children_infertility_bottom_edge.id == node.id) {
                  node.source = `0xr-${specific_relationship_id.id}_b`
                  node.target = no_children_infertility_exists.id
                }
                // update the bottom connector node's id
                if (no_children_infertility_top_edge.id == node.id) {
                  node.source = partner_connector_node.id;
                  node.target = `0xr-${specific_relationship_id.id}_b`
                }
                if (no_children_infertility_exists && node.data.profile && (node.data.profile.father_id == no_children_infertility_exists.father_id || node.data.profile.mother_id == no_children_infertility_exists.mother_id)) {
                  node.position.x = partner_connector_node.position.x - 19
                  node.position.y = partner_connector_node.position.y + 195
                  node.id = no_children_infertility_exists.id
                  node.data.profile = cloneDeep(no_children_infertility_exists)
                }
              }
            }

          }
          else{
            // if there are more than one partners, we need to
            // create new infertility/no_children nodes, edges,
            // and connector nodes for them
            if(updated_profile.partners.length > 1){
              const profile_node = nodes.find((n) => n.id + "" === profile.id + "");

              const profile_partner_connector = nodes.find((n) => n.id === partner_connector_node.id + "") ? nodes.find((n) => n.id === partner_connector_node.id + "") : null;

              let child_x_position = profile_partner_connector ? profile_partner_connector.position.x-19 : profile_node.position.x
              let child_y_position = profile_partner_connector ? profile_partner_connector.position.y+195 : profile_node.position.y+195

              const child_node_position = {x: child_x_position, y: child_y_position};

              let g = 'unknown';
              if (no_children_infertility_exists.gender === 'm') {
                g = 'male';
              } else if (no_children_infertility_exists.gender === 'f') {
                g = 'female';
              }

              const child_node = {
                id: no_children_infertility_exists.id+"",
                type: ClientSideNodeTypes.PERSON,
                nodeType: ApiNodeTypes.PERSON,
                position: child_node_position,
                data: {
                  label: no_children_infertility_exists.id,

                  gender: g,

                  datastore: this.datastore,
                  nodeType: "^^" + ApiNodeTypes.PERSON,
                  id: no_children_infertility_exists.id+"",

                  nodesSelectedCount: 0,
                  isNodeDragging: false,
                  is_proband: false,
                  proband_id: this.props.getPedigreeData().getProband().id+"",
                  proband_mother_id: this.props.getPedigreeData().getProband().mother_id+"",
                  proband_father_id: this.props.getPedigreeData().getProband().father_id+"",
                  proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id+"",
                  proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id+"",
                  proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id+"",
                  proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id+"",

                  // perform a deep clone here so its not tied to the redux reference
                  profile: cloneDeep(no_children_infertility_exists),
                  disease_color_map: profile_node.data.disease_color_map,

                  onDeleteCheck: this.handleCheckDeleteSelectedPerson,
                  onDeleteOpen: this.onClickOpenModalConfirmDelete,
                  onAddParents: this.addParents,
                  onAddChildren: this.addChildren,
                  onAddPartner: this.addPartner,
                  onAddSiblings: this.addSiblings,
                  onCreatePartnerWtihChildren: this.addPartnerWithChildren,
                  getPedigreeData: this.props.getPedigreeData,
                  navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
                },
                sourcePosition: "left",
                targetPosition: "top"
              };

              let connector_node = null;
              const connector_node_position = {x: profile_partner_connector ? profile_partner_connector.position.x : profile_node.position.x+19, y: profile_partner_connector ? profile_partner_connector.position.y+50 : profile_node.position.y+133};
              let p = this.props.getPedigreeData().getProfile(profile.rkey)
              let specific_relationship_id = p.relationship_ids.find(relationship => (relationship.mother_id == no_children_infertility_exists.mother_id && relationship.father_id == no_children_infertility_exists.father_id) || (relationship.mother_id == no_children_infertility_exists.father_id && relationship.father_id == no_children_infertility_exists.mother_id))

              const connector_uuid = `0xr-${specific_relationship_id.id}_b`;
              connector_node = {
                id: `${connector_uuid}`,
                nodeType: ApiNodeTypes.BOTTOM,
                position: connector_node_position,
                sourcePosition: "bottom",
                targetPosition: "bottom",
                style: {
                  backgroundColor: "#777",
                  border: "1px solid #777",
                  borderRadius: "40px",
                  height: "3px",
                  padding: "0px",
                  width: "3px"
                },
                data: {
                  label: "",

                  getPedigreeData: this.props.getPedigreeData,
                  datastore: this.datastore,
                  nodeType: "^^" + ApiNodeTypes.BOTTOM,
                  id: `${connector_uuid}`,
                }
              };

              const top_edge_uuid = createUUID();
              const top_edge = {
                id: `${top_edge_uuid}`,
                edgeType: ApiEdgeTypes.TopToBottom,
                type: ApiEdgeTypes.TopToBottom,
                source: profile_partner_connector ? profile_partner_connector.id : profile_node.id,
                target: connector_node.id,
                style: {
                  stroke: "#bdbdbd",
                  strokeWidth: "2px"
                },
                data: {
                  label: "",

                  getPedigreeData: this.props.getPedigreeData,
                  datastore: this.datastore,
                  nodeType: "^^undefined",
                  id: `${top_edge_uuid}`,
                  multiple_birth_type: null
                }
              };

              nodes.push(connector_node);
              nodes.push(top_edge);


              const bottom_edge_uuid = createUUID();
              const bottom_edge = {
                id: `${bottom_edge_uuid}`,
                edgeType: ApiEdgeTypes.BottomToChild,
                type: ApiEdgeTypes.BottomToChild,
                source: connector_node.id,
                target: no_children_infertility_exists.id+"",
                style: {
                  stroke: "#bdbdbd",
                  strokeWidth: "2px"
                },
                data: {
                  label: "",

                  getPedigreeData: this.props.getPedigreeData,
                  datastore: this.datastore,
                  nodeType: "^^undefined",
                  id: `${bottom_edge_uuid}`,
                  multiple_birth_type: null
                }
              };

              nodes.push(child_node);
              nodes.push(bottom_edge);

            }
          }
        }

        // do we need this, they should be getting added in the edge class?
        // this.datastore.updateEdge({"src":left_edge_source,"target":connector_node.id, "type":"","edgelist":[left_edge_uuid]});
        // this.datastore.updateEdge({"src":right_edge_source,"target":connector_node.id, "type":"","edgelist":[right_edge_uuid]});
        // call pedigree callback to render changes
        if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
          await this.pedigreeCallbacks.setElements(nodes);
        }
        let currentEls = cloneDeep(nodes)
        await this.saveLayoutData(currentEls)
      }

      else {
        if (!skip_reRender) {
          await this.deleteFakePartnerAndChild(profile)
          await this.addFakePartnersAndChildren();
          await this.reRenderPedigree();
        }

      }

      return partner_data;

    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] });
    }
  }

  getSiblingGender(value){
    switch (value) {
      case actions.SIBLING_SON:
      case actions.SIBLING_TWIN_BROTHER:
        return "m";
      case actions.SIBLING_DAUGHTER:
      case actions.SIBLING_TWIN_SISTER:
        return "f";
      case actions.SIBLING_UNKNOWN:
      case actions.SIBLING_TWIN_UNKNOWN:
        return null;
    }

    return null;
  }

  async addTwinsEntry(twin_type, profile, child_data = null) {
    if (child_data !== null) {
      const { father_id, mother_id } = profile;

      // Save to API
      let payload = {
        father_id: profile.father_id,
        mother_id: profile.mother_id,
        twin_type: twin_type,
        adder_member_id: profile.id,
        addee_member_id: child_data.id
      };

      const data = await familyApi.post_add_twin(payload);
      return data;
    }
    return null;
  }

  async addSiblings(profile, type, num_to_add) {
    try {

      // let nodes = cloneDeep(this.reactFlowInstance.getElements());
      // const profile_node = nodes.find((n) => n.id+"" === profile.id+"");

      let saved_data = this.getActiveSavedNodePositions();

      // start react-flow update code
      let nodes = cloneDeep(this.reactFlowInstance.getNodes()).concat(cloneDeep(this.reactFlowInstance.getEdges()))
      const profile_node = nodes.find((n) => n.id+"" === profile.id+"");

      for(let i = 0; i < num_to_add; i++){
        let gender = this.getSiblingGender(type);
        var child = model.createSonDaughter(gender);
        let parents = await familyApi.get_member_memberid_parents(profile.id)
        let father = {
          family_id: profile.family_id,
          level: parents.father.level,
          id: parents.father.id,
          gender: "m"
        };
        let mother = { id: parents.mother.id };

        // Save to API
        let child_data = await helper_family_api.create_member_as_child(
          father,
          mother,
          child,
          profile.side
        );

        child_data.rkey = `apimem-${child_data.id}`;
        child_data.diseases = [];
        child_data.genetic_testing = [];
        child_data.family_id_id = child_data.family_id;
        child_data.partners = [];
        child_data.relationship_ids = [];
        child_data.twin_id_id = child_data.twin_id;
        let people = Object.values(this.props.getPedigreeData().getAllProfiles())
        let siblings = people.filter(person => person.id !== child_data.id && ((person.mother_id == mother.id && person.father_id == father.id) || (person.mother_id == father.id && person.father_id == mother.id)))

        if(!isEmpty(saved_data)){
          // // get the top connector node
          // const father_edge = nodes.find((n) => n.source && n.target && n.source === parents.father.id+"");
          // const parents_connector = nodes.find((n) => n.id === father_edge.target);

          // const bottom_connector_node_edge = nodes.find((n) => n.source && n.target && n.source === parents_connector.id && n.type === ApiEdgeTypes.TopToBottom);

          // let bottom_connector_node = null
          // if(bottom_connector_node_edge !== undefined && bottom_connector_node_edge !== null){
          //   bottom_connector_node = nodes.find((n) => n.id === bottom_connector_node_edge.target);
          // }
          // else{
          //   bottom_connector_node = parents_connector
          // }

          let nodes_of_siblings = nodes.filter((node) => siblings.find((sibling) => node.id == sibling.id));

          const sibling_group_edge = nodes.find((n) => n.source && n.target && n.target === nodes_of_siblings[0].id && (n.type === ApiEdgeTypes.BottomToChild || n.type === ClientSideEdgeTypes.BottomToTwinChild));
          const bottom_connector_node = nodes.find((n) => n.id === sibling_group_edge.source);

          let child_x_position = bottom_connector_node.position.x
          let child_y_position = bottom_connector_node.position.y+110
          //child_y_position should be equal to the y of the s
          if(siblings.length > 0){

            //get x of most left and most right nodes from the siblings
            let most_right = Math.max(...nodes_of_siblings.map(node => node.position.x))
            let most_left = Math.min(...nodes_of_siblings.map(node => node.position.x))

            //get y of most left and most right nodes from the sibling
            let y_most_right = nodes_of_siblings.find(node => node.position.x == most_right).position.y
            let y_most_left = nodes_of_siblings.find(node => node.position.x == most_left).position.y

            if(type.includes("twin")){
              const p = people.find(person => person.id == profile.id)
              if(!p.twin_id){
                most_right = profile_node.position.x
                most_left = profile_node.position.x
                y_most_right = profile_node.position.y
                y_most_left = profile_node.position.y
              }
              else{
                nodes_of_siblings = nodes.filter((node) => siblings.find((sibling) => node.id == sibling.id && sibling.twin_id == p.twin_id));
                most_right = Math.max(...nodes_of_siblings.map(node => node.position.x))
                most_left = Math.min(...nodes_of_siblings.map(node => node.position.x))

                //get y of most left and most right nodes from the sibling
                y_most_right = nodes_of_siblings.find(node => node.position.x == most_right).position.y
                y_most_left = nodes_of_siblings.find(node => node.position.x == most_left).position.y
              }
            }



            //for now, we choose randomly if we add it to the right or left
            const rndInt = Math.floor(Math.random() * 2) + 1
            child_x_position = rndInt === 1 ? most_left - 70 : most_right + 70
            child_y_position = rndInt === 1 ? y_most_left : y_most_right
          }

          const child_node_position = {x: child_x_position, y: child_y_position};

          let g = 'unknown';
          if (child_data.gender === 'm') {
            g = 'male';
          } else if (child_data.gender === 'f') {
            g = 'female';
          }

          const child_node = {
            id: child_data.id+"",
            type: ClientSideNodeTypes.PERSON,
            nodeType: ApiNodeTypes.PERSON,
            position: child_node_position,
            data: {
              label: child_data.id,

              gender: g,

              datastore: this.datastore,
              nodeType: "^^" + ApiNodeTypes.PERSON,
              id: child_data.id+"",

              nodesSelectedCount: 0,
              isNodeDragging: false,
              is_proband: false,
              proband_id: this.props.getPedigreeData().getProband().id+"",
              proband_mother_id: this.props.getPedigreeData().getProband().mother_id+"",
              proband_father_id: this.props.getPedigreeData().getProband().father_id+"",
              proband_paternal_grandfather_id: this.props.getPedigreeData().getProband().paternal_grandfather.id+"",
              proband_paternal_grandmother_id: this.props.getPedigreeData().getProband().paternal_grandmother.id+"",
              proband_maternal_grandfather_id: this.props.getPedigreeData().getProband().maternal_grandfather.id+"",
              proband_maternal_grandmother_id: this.props.getPedigreeData().getProband().maternal_grandmother.id+"",

              // perform a deep clone here so its not tied to the redux reference
              profile: cloneDeep(child_data),
              disease_color_map: profile_node.data.disease_color_map,

              onDeleteCheck: this.handleCheckDeleteSelectedPerson,
              onDeleteOpen: this.onClickOpenModalConfirmDelete,
              onAddParents: this.addParents,
              onAddChildren: this.addChildren,
              onAddPartner: this.addPartner,
              onAddSiblings: this.addSiblings,
              onCreatePartnerWtihChildren: this.addPartnerWithChildren,
              getPedigreeData: this.props.getPedigreeData,
              navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
            },
            sourcePosition: "left",
            targetPosition: "top"
          };

          const bottom_edge_uuid = createUUID();
          const bottom_edge = {
            id: `${bottom_edge_uuid}`,
            edgeType: type.includes("twin") ? ClientSideEdgeTypes.BottomToTwinChild : ApiEdgeTypes.BottomToChild,
            type: type.includes("twin") ? ClientSideEdgeTypes.BottomToTwinChild : ApiEdgeTypes.BottomToChild,
            source: bottom_connector_node.id,
            target: child_data.id+"",
            style: {
              stroke: "#bdbdbd",
              strokeWidth: "2px"
            },
            data: {
              label: "",

              datastore: this.datastore,
              nodeType: "^^undefined",
              id: `${bottom_edge_uuid}`,
              multiple_birth_type: type.includes("twin") ? "fraternal" : null,
              getPedigreeData: this.props.getPedigreeData,
              navigateToRecord: (event, member_id) => this.navigateToRecordNewTab(event, member_id),
            }
          };

          nodes.push(child_node);
          nodes.push(bottom_edge);
          //
          // // do we need this, they should be getting added in the edge class?
          // // this.datastore.updateEdge({"src":bottom_connector_node.id,"target":child_data.id+"", "type":"","edgelist":[bottom_edge.id]});
        }

        const p = this.props.getPedigreeData().getProfile(profile.rkey);
        const p_copy_before_changes = cloneDeep(this.props.getPedigreeData().getProfile(profile.rkey))

        // Save if there is twins
        if (type.includes("twin")) {
          const data = await this.addTwinsEntry(profile.twin_type ? profile.twin_type : "fraternal", profile, child_data);
          if (data) {
            let twin_type = null;
            const member_pair = data.members;

            if (member_pair[0].id+"" === p.id+"") {
              twin_type = member_pair[0].twin_type;
              p.twin_id = member_pair[0].twin_id;
              p.twin_id_id = member_pair[0].twin_id;
              p.twin_type = member_pair[0].twin_type;
              p.twin_set = member_pair[0].twin_set;
            } else if (member_pair[0].id+"" === child_data.id+"") {
              twin_type = member_pair[0].twin_type;
              child_data.twin_id = member_pair[0].twin_id;
              child_data.twin_id_id = member_pair[0].twin_id;
              child_data.twin_type = member_pair[0].twin_type;
              child_data.twin_set = member_pair[0].twin_set;
            }

            if (member_pair[1].id+"" === p.id+"") {
              twin_type = member_pair[1].twin_type;
              p.twin_id = member_pair[1].twin_id;
              p.twin_id_id = member_pair[1].twin_id;
              p.twin_type = member_pair[1].twin_type;
              p.twin_set = member_pair[1].twin_set;
            } else if (member_pair[1].id+"" === child_data.id+"") {
              twin_type = member_pair[1].twin_type;
              child_data.twin_id = member_pair[1].twin_id;
              child_data.twin_id_id = member_pair[1].twin_id;
              child_data.twin_type = member_pair[1].twin_type;
              child_data.twin_set = member_pair[1].twin_set;
            }

            // to add a twin to a member who doesn't have a twin yet, create a new twin connector node at the center of the two members,
            // and also create a new edge that connects the new twin connector node to the siblings connector node (bottom_connector_node)
            // and connect edges from children to the new connector node (edit edgetype to ClientSideEdgeTypes.BottomToTwinChild)
            if(!isEmpty(saved_data)){
              let left_most_node_pos = null
              let right_most_node_pos = null

              if(siblings.length > 0){
                let nodes_of_twins = nodes.filter((node) => siblings.find((sibling) => node.id == sibling.id && sibling.twin_id === child_data.twin_id) );
                let child_node = nodes.find(node => node.id+'' === child_data.id+'')
                nodes_of_twins.push(child_node)

                //get x of most left and most right nodes from the siblings
                let most_right_x = Math.max(...nodes_of_twins.map(node => node.position.x))
                let most_left_x = Math.min(...nodes_of_twins.map(node => node.position.x))

                //get y of most left and most right nodes from the sibling
                let most_right_y = nodes_of_twins.find(node => node.position.x == most_right_x).position.y
                let most_left_y = nodes_of_twins.find(node => node.position.x == most_left_x).position.y

                left_most_node_pos = {x: most_left_x, y: most_left_y}
                right_most_node_pos = {x: most_right_x, y: most_right_y}

              }

              let center_point = getCenterPoint(left_most_node_pos, right_most_node_pos)

              if(type.includes("twin") && !p_copy_before_changes.twin_id){

                const father_edge = nodes.find((n) => n.source && n.target && n.source === parents.father.id+"");
                const parents_connector = nodes.find((n) => n.id === father_edge.target);

                const bottom_connector_node_edge = nodes.find((n) => n.source && n.target && n.source === parents_connector.id && n.type === ApiEdgeTypes.TopToBottom);
                const bottom_connector_node = nodes.find((n) => n.id === bottom_connector_node_edge.target);

                //create new connector node for the twin set
                let new_twins_connector_node = JSON.parse(JSON.stringify(bottom_connector_node))
                new_twins_connector_node.position.x = center_point.x

                //changed this to a specific id so it would be updated if there is a saved position for this
                new_twins_connector_node.id = `twin-connector-node-${child_data.twin_id}`

                new_twins_connector_node.data.id = new_twins_connector_node.id
                
                // had to reset the datastore because it was empty anyways and only 
                //adding the new nodes causing it to crash when rendering
                new_twins_connector_node.data.datastore = new Node_Line_Store();

                let nodeClickCount = 0;
                if(sessionStorage.getItem('famgenix_last_selected_node') !== null && sessionStorage.getItem('famgenix_last_selected_node') !== undefined){
                  if(JSON.parse(sessionStorage.getItem('famgenix_last_selected_node')) == new_twins_connector_node.id){
                    nodeClickCount = 1;
                  }
                }

                new_twins_connector_node.data.nodesSelectedCount = 0
                new_twins_connector_node.data.isTwinConnectorNode = true;
                new_twins_connector_node.data.isNodeDragging = false
                new_twins_connector_node.data.nodeClickCount = nodeClickCount
                new_twins_connector_node.data.reRenderPedigree = this.reRenderPedigree
                new_twins_connector_node.data.getPedigreeData = this.props.getPedigreeData
                nodes.push(new_twins_connector_node);

                //connect new connector node to the previous connector node
                let new_twins_connector_line = JSON.parse(JSON.stringify(bottom_connector_node_edge));

                //changed this to a specific id so it would be updated if there is a saved position for this
                new_twins_connector_line.id = `twin-connector-line-${child_data.twin_id_id}`

                new_twins_connector_line.data.id = new_twins_connector_line.id;

                // had to reset the datastore because it was empty anyways and only 
                //adding the new nodes causing it to crash when rendering
                new_twins_connector_line.data.datastore = new Node_Line_Store();

                new_twins_connector_line.source = bottom_connector_node.id;
                new_twins_connector_line.target = new_twins_connector_node.id;
                new_twins_connector_line.type = 'BottomToChild'
                nodes.push(new_twins_connector_line);

                //connect the twin edge of the newly added child
                let twin_line_1 = nodes.find(node => 'edgeType' in node && node.edgeType === 'BottomToTwinChild' && node.target == child_data.id)
                twin_line_1.source = new_twins_connector_node.id

                //find and remove the bottomtochild edge that connects the profile node, to the bottom connector node
                let bottom_to_child_line = nodes.find(node => 'edgeType' in node && node.edgeType === 'BottomToChild' && node.target == profile.id)
                nodes = nodes.filter(node => node.id != bottom_to_child_line.id)

                //then replace the removed edge with a new bottomtotwinchild edge that's connected to the new twins connector node
                let twin_line_2 = JSON.parse(JSON.stringify(twin_line_1))
                twin_line_2.id = createUUID();
                
                // had to reset the datastore because it was empty anyways and only 
                //adding the new nodes causing it to crash when rendering
                twin_line_2.data.datastore = new Node_Line_Store();
                
                twin_line_2.data.id = twin_line_2.id;
                twin_line_2.source = new_twins_connector_node.id
                twin_line_2.target = profile.id+''
                twin_line_2.type = 'BottomToTwinChild'
                twin_line_2.edgeType = 'BottomToTwinChild'

                nodes.push(twin_line_2)

              }
              else if (type.includes("twin") && p_copy_before_changes.twin_id){
              // if already have a twin, connect the edges to the existing twin connector node
                let existing_twins_connector_node = nodes.find(node => node.id === `twin-connector-node-${child_data.twin_id}`)
                existing_twins_connector_node.position.x = center_point.x

                let newly_added_twin_line = nodes.find(node => 'edgeType' in node && node.edgeType === 'BottomToTwinChild' && node.target == child_data.id)
                newly_added_twin_line.source = existing_twins_connector_node.id

              }
            }




            // for (let d=0; d<nodes.length; d++) {
            //   if (nodes[d].id+"" === profile.id+"" || nodes[d].id+"" === child_data.id+"") {
            //     if (member_pair[0].id+"" === profile.id+"" || member_pair[0].id+"" === child_data.id+"") {
            //       twin_type = member_pair[0].twin_type;
            //       nodes[d].data.profile.twin_id = member_pair[0].twin_id;
            //       nodes[d].data.profile.twin_id_id = member_pair[0].twin_id;
            //       nodes[d].data.profile.twin_type = member_pair[0].twin_type;
            //       nodes[d].data.profile.twin_set = member_pair[0].twin_set;
            //     } else if (member_pair[1].id+"" === profile.id+"" || member_pair[1].id+"" === child_data.id+"") {
            //       twin_type = member_pair[1].twin_type;
            //       nodes[d].data.profile.twin_id = member_pair[1].twin_id;
            //       nodes[d].data.profile.twin_id_id = member_pair[1].twin_id;
            //       nodes[d].data.profile.twin_type = member_pair[1].twin_type;
            //       nodes[d].data.profile.twin_set = member_pair[1].twin_set;
            //     }
            //   }
            // }

            // // if adding a twin to a member who doesn't have a twin yet, change their edge to a twin edge
            // const profile_node_bttom_to_child_edge = nodes.find((n) => n.source && n.target && n.source === bottom_connector_node.id && n.target === profile.id+"" && n.type === ApiEdgeTypes.BottomToChild);
            // profile_node_bttom_to_child_edge.edgeType = ClientSideEdgeTypes.BottomToTwinChild;
            // profile_node_bttom_to_child_edge.type = ClientSideEdgeTypes.BottomToTwinChild;
            // profile_node_bttom_to_child_edge.data.multiple_birth_type = twin_type;
          }
        }

        this.props.getPedigreeData().setProfile(p.rkey, p);
        this.props.getPedigreeData().setProfile(child_data.rkey, child_data);
      }

      // // call pedigree callback to render changes
      // if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
      //   this.pedigreeCallbacks.setElements(nodes);
      // }
      if(!isEmpty(saved_data)){
        // call pedigree callback to render changes
        if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
          await this.pedigreeCallbacks.setElements(nodes);
        }
        let currentEls = cloneDeep(nodes)
        await this.saveLayoutData(currentEls)
      }
      else{
        await this.reRenderPedigree();
      }

      // await this.reRenderPedigree();

    } catch (error) {
      console.log(error.stack)
      this.setState({ errorMessages: [error.message] });
    }
  }

  closeCannotDeleteModal() {
    this.setState({ openCannotDeleteModal: ["none", false] })
  }

  closePrintingErrorModal() {
    this.setState({ openPrintingErrorModal: false })
  }

  navigateToRecord(member_id){
    let url = route_helper.pedigree.pedigree_root.replace(":member_id", member_id) + "/#family-history";
    this.props.history.push(url);
    window.location.reload();
  }

  // this is to handle ctrl click and metakey click to open a new tab
  navigateToRecordNewTab(e, member_id){
    console.log(e)
    if(e.type == 'click'){
      if (e.ctrlKey || e.metaKey){

        let url = route_helper.pedigree.pedigree_root.replace(":member_id", member_id) + "/#family-history";
        // focus on new window or just stay on the window?
        let newWindow = window.open(url)
        newWindow.blur();
        window.focus();
      }
      else{
        this.navigateToRecord(member_id);
      }
    }
  }

  async reRenderPedigree(collapse){
    let data = null
    if(collapse !== null && collapse !== undefined){
      data = await this.loadSavedNodes(collapse);
    }
    else{
      data = await this.loadSavedNodes();
    }

    // call pedigree callback to render changes
    if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
      this.pedigreeCallbacks.setElements(data.nodes);
    }

    if (this.state.selectedNode) {
      for (let i=0; i<data.nodes.length; i++) {
        if (parseInt(data.nodes[i].id) === parseInt(this.state.selectedNode.id)) {
          this.setState({selectedNode: data.nodes[i]});
          break;
        }
      }
    }

    return data
  }

  runRiskCriteria() {
    const setRiskCriteria = this.props.setRiskCriteria;
    risk_api.post_check_risk(this.props.memberid).then(risk_criteria_result => {
      setRiskCriteria(risk_criteria_result.result);
      // TODO: if sidebar open then refresh
    }).catch(error => console.log(error));
  }

  async saveLayoutData(data){
    // disable saving of node positions when moved by patient
    if(this.render_patient_pedigree) return

    let data_copy = cloneDeep(data)

    if(!isEmpty(data_copy)){
      //store datastore only once, and remove the datastore reference from each node
      let datastore = cloneDeep(data_copy[0].data.datastore)
      data_copy.forEach(node => {
        delete node.data;
      });
      data_copy.push(datastore)
    }

    let saved_data = this.props.getPedigreeData().getSavedNodePositions();

    let payload = {
      probandID: this.props.getPedigreeData().getProband().id,
      data: saved_data.data ? saved_data.data : [],
      data_show_affected_partners: saved_data.data_show_affected_partners ? saved_data.data_show_affected_partners : [],
      data_hide_partners: saved_data.data_hide_partners ? saved_data.data_hide_partners : [],
    };

    let partners_option = Cookie.get(CookieKeys.PARTNER_OPTION)

    if(!isEmpty(data_copy)){
      if(partners_option === PartnerOptions.SHOW_ALL_PARTNERS){
        payload.data = data_copy
      }
      else if(partners_option === PartnerOptions.SHOW_AFFECTED_PARTNERS){
        payload.data_show_affected_partners = data_copy
      }
      else if(partners_option === PartnerOptions.HIDE_PARTNERS){
        payload.data_hide_partners = data_copy
      }
    }
    else{
      payload.data = data_copy
      payload.data_show_affected_partners = data_copy
      payload.data_hide_partners = data_copy
    }

    await familyApi.react_flow_save_layout_data(payload)

    if(isEmpty(data_copy)){
      this.props.getPedigreeData().setSavedNodePositions('')
    }
    else{
      this.props.getPedigreeData().setSavedNodePositions(payload)
    }

  }

  async redrawToOriginalPositions(){
    await this.saveLayoutData([])
    await this.props.getPedigreeData().setSavedNodePositions('');

    //This is a possible solution to force rerender a pedigree to its original position without hard refreshing,
    //it may not be needed for now, but I'll comment this out for future reference -Rafa
    // let nodes = cloneDeep(this.state.nodes)
    // for(let node of nodes){
    //   if('position' in node){
    //     node.position.x += (Math.random() * 100);
    //     node.position.y += (Math.random() * 100);
    //   }
    // }
    // if (this.pedigreeCallbacks && this.pedigreeCallbacks.setElements) {
    //   this.pedigreeCallbacks.setElements(nodes);
    // }


    await this.reRenderPedigree();
  }

  showSideBar(){
    let { is_no_children_node, is_infertility_node } = this.state.selectedNode.data.profile
    let people = cloneDeep(Object.values(this.props.getPedigreeData().getAllProfiles()))
    let father = people.find(person => person.id == this.state.selectedNode.data.profile.father_id)
    let mother = people.find(person => person.id == this.state.selectedNode.data.profile.father_id)

    let either_parents_is_infertile_or_no_children = false;

    if(father && mother){
      let father_profile = this.props.getPedigreeData().getProfile(father.rkey)
      let mother_profile = this.props.getPedigreeData().getProfile(mother.rkey)

      if(father_profile.no_children || father_profile.infertile || mother_profile.no_children || mother_profile.infertile){
        either_parents_is_infertile_or_no_children = true;
      }
    }

    if(this.render_patient_pedigree) return false

    if(is_no_children_node || is_infertility_node || either_parents_is_infertile_or_no_children){
      return false;
    }
    return true;
  }

  sendAddMessageToApp(){
    if (window.ReactNativeWebView) {
      // send to patient app
      window.ReactNativeWebView.postMessage(JSON.stringify({selected_member_id: this.state.selectedNode.id, type: 'add-member'}))
    } else {
      // send to patient web portal
      window.parent.postMessage(JSON.stringify({selected_member_id: this.state.selectedNode.id, type: 'add-member'}), "*");
    }
  }

  sendEditMessageToApp(){
    if (window.ReactNativeWebView) {
      // send to patient app
      window.ReactNativeWebView.postMessage(JSON.stringify({selected_member_id: this.state.selectedNode.id, type: 'edit-member'}))
    } else {
      // send to patient web portal
      window.parent.postMessage(JSON.stringify({selected_member_id: this.state.selectedNode.id, type: 'edit-member'}), "*");
    }
  }

  showFamilyHistoryModal(){
    this.setState({showFamilyHistoryModal: !this.state.showFamilyHistoryModal})
  }

  getPartnersOption() {
    let partnersOption = Cookie.get(CookieKeys.PARTNER_OPTION);

    if (partnersOption === undefined || partnersOption === null) {
      if (this.props.user.default_partner_view)
        switch (this.props.user.default_partner_view) {
          case 'show_all_partners': { partnersOption = PartnerOptions.SHOW_ALL_PARTNERS; break; }
          case 'show_affected_partners': { partnersOption = PartnerOptions.SHOW_AFFECTED_PARTNERS; break; }
          case 'hide_partners': { partnersOption = PartnerOptions.HIDE_PARTNERS; break; }
          default: { break; }
        }
      else {
        this.setCookie(CookieKeys.PARTNER_OPTION, PartnerOptions.SHOW_ALL_PARTNERS);
        partnersOption = PartnerOptions.SHOW_ALL_PARTNERS;
      }
    }

    return partnersOption
  }

  render() {
    let deleteFailMessage = '';

    if(this.state.openCannotDeleteModal[0] === 'toplevel'){
      deleteFailMessage = 'Cannot delete because this person has 2 or more children.'
    }
    else if(this.state.openCannotDeleteModal[0] === 'children'){
      deleteFailMessage = "This family member cannot be deleted because they have children. Please delete the children first."
    }
    else if(this.state.openCannotDeleteModal[0] === 'non_blood_related_partner'){
      deleteFailMessage = "This family member cannot be deleted because they have partner(s). Please delete the partner(s) first."
    }

    const dimensions = {
      height: this.state.pedigreeHeight,
      width: this.state.pedigreeWidth
    };

    let pedigree = null;
    if (this.state.showPedigree) {
      pedigree = (
        <Pedigree
          dimensions={dimensions}
          nodes={this.state.nodes}
          nodeParentMap={this.state.nodeParentMap}
          nodeDiseaseColorMap={this.state.nodeDiseaseColorMap}
          saveLayoutData={this.saveLayoutData}
          onLoad={this.reactFlowLoaded}
          onMoveEnd={(event, flowTransform) => this.updateZoomValue(event, flowTransform)}
          nodeSelectedCallback={(node, nodesSelectedCount) => this.onNodeSelected(node, nodesSelectedCount)}
          clearNodeSelectionCallback={() => this.onClearNodeSelection()}
          updatedNodesCallback={(nodes) => console.log(nodes)}
          updatedNodeParentMapCallback={(nodes) => console.log(nodes)}
          updatedNodeDiseaseColorMapCallback={(disease_colors) => console.log(disease_colors)}
          readOnlyUser={this.props.read_only}
          saved_data={() => this.props.getPedigreeData().getSavedNodePositions()}
          member_id_toBeSelected={this.props.member_id_toBeSelected}
          datastore={this.datastore}
          getPedigreeData={this.props.getPedigreeData}
          reRenderPedigree={this.reRenderPedigree}
          consanguineousToolTipTrack={this.consanguineousToolTipTrack}
          reAssignParentsToolTipTrack={this.reAssignParentsToolTipTrack}
        />
      );
    }

    return (
      <>
        <div id="consanguineous-tooltip">Select the partner</div>
        <div id="re-assign-parents-tooltip">Select the relationship node</div>

        <PedigreeToolbar
          showProgenyArchivedDataOption={this.props.getPedigreeData().getProgenyArchiveData() !== null}
          cookies={this.getCookies()}
          setCookie={this.setCookie}
          onLoad={this.onLoadPedigreeToolbar}
          onZoomInClick={this.handleZoomInClick}
          onZoomOutClick={this.handleZoomOutClick}
          onActualSizeClick={this.handleActualSizeClick}
          onFitToScreenClick={this.handleFitToScreenClick}
          onShrinkCheck={this.handleShrinkCheck}
          onSaveImageClick={this.handleSaveImageClick}
          onToggleViewClick={this.handleToggleViewStatus}
          onToggleLegend={this.handleToggleLegend}
          onTogglePedigreeNotes={this.handleTogglePedigreeNotes}
          onPDFFromApi={(singlePage)=>this.prepareForPrint(singlePage)}
          onToggleAncestry={this.handleToggleAncestry}
          onToggleShowProgenyArchivedData={this.handleToggleShowProgenyArchivedData}
          onToggleGeneticTesting={this.handleToggleGeneticTesting}
          onToggleNotes={this.handleToggleNotes}
          reRenderPedigree={this.reRenderPedigree}
          redrawToOriginalPositions={this.redrawToOriginalPositions}
          probandMemberId={this.props.getPedigreeData().getProband().id}
          readOnlyUser={this.props.read_only}
          onToggleBlackAndWhite={this.handleToggleBlackAndWhite}
          renderPatientPedigree={this.render_patient_pedigree}
          openFamilyHistoryModal={this.showFamilyHistoryModal}
        />

        {pedigree}

        {/* memberid is the proband id */}
        {this.state.selectedNode && this.showSideBar() && (
          <PedigreeSidebar
            dimensions={dimensions}
            key={this.state.selectedNode ? this.state.selectedNode.id : ""}
            getPedigreeData={this.props.getPedigreeData}
            node={this.state.selectedNode}
            readOnlyUser={this.props.read_only}
            user={this.props.user}
            probandId={this.props.memberid}
            showSideBar={this.state.showSideBar}
            viewProgenyArchiveTable={this.viewArchivedDataTable}
            onCloseClick={this.handleSideBarClose}
            onInfoAndHistoryUpdate={(data) => this.handleInfoAndHistoryUpdate(data)}
            onDiseaseUpdate={(data) => this.handleDiseaseUpdate(data)}
            onGeneTestUpdate={(data) => this.handleGeneTestUpdate(data)}
            onDiseaseDelete={(data) => this.handleDiseaseDelete(data)}
            onGeneTestDelete={(data) => this.handleGeneTestDelete(data)}
            reRenderTopBarFunction={() => this.props.reRenderTopBarFunction()}
            reRenderPedigree={() => this.reRenderPedigree()}
            navigateToRecord={(member_id) => this.navigateToRecord(member_id)}
            history={this.props.history}
          />
        )}

        <ErrorSummary
          errorMessages={this.state.errorMessages}
          modal={true}
          onHide={() => this.setState({ errorMessages: [] })}
        />

        {this.state.openModalConfirmDelete && (
          <ModalConfirmDelete
            loading={false}
            errorMessages={[]}
            isOpen={this.state.openModalConfirmDelete}
            message={this.getDeleteMsg()}
            onCancel={() => {
              this.setState({
                openModalConfirmDelete: false,
                errorMessages: []
              });
            }}
            noChildrenInfertilityDelete={this.isNoChildrenOrInfertility()}
            onOk={() => this.handleDeleteSelectedPerson()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
            hasSpouses={false}
          />
        )}

        {this.state.openCannotDeleteModal[1] && (
          <ModalPopUpBlockerAlert
            loading={false}
            errorMessages={[]}
            title="Delete Details"
            message={deleteFailMessage}
            isOpen={this.state.openCannotDeleteModal[1]}
            onOk={() => this.closeCannotDeleteModal()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}

        {this.state.openPrintingErrorModal && (
          <ModalPopUpBlockerAlert
            loading={false}
            errorMessages={[]}
            title="Print"
            message={"The document failed to print. Please try again. If the problem persists, you may contact support with details."}
            isOpen={this.state.openPrintingErrorModal}
            onOk={() => this.closePrintingErrorModal()}
            person={(this.state.selectedNode) ? this.state.selectedNode.data.profile : null}
          />
        )}
        {this.state.showFamilyHistoryModal && (
          <ModalFamilyHistory
            closeModal={this.showFamilyHistoryModal}
            proband={this.props.getPedigreeData().getProband()}
            profiles={this.props.getPedigreeData().getAllProfiles()}
            pedigreeNotes={this.props.getPedigreeData().getPedigreeNotes()}
          />
        )}
        {this.render_patient_pedigree && this.state.selectedNode &&
          <>
            <a
              className='patient-pedigree-add-button'
              onClick={() => this.sendAddMessageToApp()}>
              <img height='45' width='45' src={add_btn}></img>
            </a>

            <a
              className='patient-pedigree-edit-button'
              onClick={() => this.sendEditMessageToApp()}>
              <img height='45' width='45' src={edit_btn}></img>
            </a>
          </>
        }

        <ActivityIndicator loading={this.state.loading_patient_pedigree} pedigree={true} />

      </>
    );
  }
}

export default PedigreeWrapper;
